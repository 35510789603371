@import './css/bootstrap.css';
@import './css/foundation-grid.css';
/* @import 'fonts/themify/themify-icons/themify-icons.css'; */
ul{
  padding-left:0!important;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    tranform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    tranform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#email-verified {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999;
  background: #fbfdff;
  top: 0;
  left: 0;
}
#email-verified > div {
  background: transparent no-repeat center 100px;
  background-size: 60px;
  max-width: 900px;
  margin: 0 auto;
  padding-top: 170px;
  text-align: center;
}
#email-verified .verified {
  display: none;
  /* background-image: url(../images/balloons.svg); */
}
#email-verified .invalid {
  display: none;
}
#email-verified .loading {
  /* background-image: url(../images/loading.svg); */
}
#email-verified.verified .verified {
  display: block;
}
#email-verified.verified .loading,
#email-verified.verified .invalid {
  display: none;
}
#email-verified.invalid .invalid {
  display: block;
}
#email-verified.invalid .verified,
#email-verified.invalid .loading {
  display: none;
}
#email-verified h1 {
  color: #154a65;
}
#email-verified button,
#email-verified a {
  margin-top: 30px;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Inter-Regular.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/Inter-Medium.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/Inter-SemiBold.woff?v=3.15") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("./fonts/Inter-ExtraBold.woff?v=3.15") format("woff");
}
.show-invoice-details-wrapper {
  text-align: center;
  padding: 0 0 30px 0;
  margin: 0 auto;
  max-width: 750px;
}
.show-invoice-details-wrapper button {
  display: inline-block;
}
/* apply a natural box layout model to all elements */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-size: 13px;
  /* font-family: Inter, Arial, sans-serif; */
  color: #3d3c40;
}
ul {
  font-size: 14px;
}
.right {
  float: right;
}
.left {
  float: left;
}
html {
  height: 100%;
  width: 100%;
}
/** Reused Classes **/
.link {
  color: #428bca;
}
.link:hover {
  color: #2a6496;
  text-decoration: underline;
}
.modal-window-base {
  border-radius: 4px;
  background: #f0f1f6;
  background: #fefefe;
  -webkit-background-clip: border-box;
}
.modal-basic {
  border-radius: 4px;
  background: #f0f1f6;
  background: #fefefe;
  -webkit-background-clip: border-box;
  box-shadow: 7.5px 13px 40px rgba(54, 71, 82, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
}
ul {
  padding-left: 0;
}
#app-wrapper {
  height: 100%;
  overflow: hidden;
  background: #F6F5F9;
}
.basic-button,
input[type=submit] {
  background: #285CFF;
  color: #fff;
  border: none;
  display: inline-block;
}
.basic-button:hover,
input[type=submit]:hover {
  background: #285CFF;
  color: #fff !important;
  border: none;
}
.basic-button.disabled,
input[type=submit].disabled,
.basic-button.disabled:hover,
input[type=submit].disabled:hover {
  opacity: 0.6;
  cursor: not-allowed;
}
.basic-button.large,
input[type=submit].large {
  padding: 10px 40px;
  font-size: 16px;
}
.basic-button:hover,
input[type=submit]:hover {
  box-shadow: 0 1px 2px rgba(43, 50, 57, 0.08);
  border: 1px solid #bdbdbd;
}
.basic-button[disabled=disabled],
input[type=submit][disabled=disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.button.blue:hover,
input[type=submit]:hover {
  border: 1px solid #458CCF;
  box-shadow: inset 0 0 0 1px #458CCF;
  background: rgba(69, 140, 207, 0.05);
  color: #458CCF !important;
}
.panel-basic {
  height: 60px;
  padding-top: 10px;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  background: #fff;
}
.panel-basic h3 {
  line-height: 60px;
  display: inline-block;
  font-size: 22px;
  font-weight: normal;
}
.panel-basic .panel-title {
  text-align: center;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  color: #505960;
}
.panel-basic .panel-left {
  width: 70%;
}
.panel-basic .panel-left .panel-title {
  max-width: 30%;
}
.panel-basic .panel-left .panel-title-widget {
  width: 59%;
}
[data-template="global-search"] .panel-title {
  width: 100%;
  max-width: 100% !important;
  text-align: left !important;
}
.icon,
.icon-white {
  display: inline-block;
  vertical-align: text-top;
  font-size: 17px;
  color: #444;
}
.clickable .icon {
  opacity: 0.5;
}
.clickable .icon:hover {
  opacity: 0.5;
}
.icon-white {
  /* background-image: url("../images/glyphicons-halflings-white.png") !important; */
}
.clickable {
  cursor: pointer;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid black;
  content: "";
}
.open .caret,
.caret-down {
  border-bottom: 4px solid transparent;
  border-top: 4px solid black;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.collapsible .collapse {
  display: none;
  padding-left: 20px;
}
.collapsible h5 {
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #eee;
}
.collapsible .caret {
  margin-right: 10px;
  margin-top: 10px;
  margin-left: 10px;
}
.collapsible.open h5 {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02);
  margin-bottom: 20px;
}
.collapsible.open .caret {
  margin-top: 14px;
}
.collapsible.open .collapse {
  display: block;
}
.user-reference {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  padding: 2px 6px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  background: #107dac;
  text-shadow: 0 -1px 0 #0c6084;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #0c5b7d;
}
.user-reference span {
  font-weight: normal;
}
.user-image {
  display: block;
  height: 32px;
  width: 32px;
  /* background: transparent url(../images/unknown-user.jpg) no-repeat center center scroll; */
  background-size: 100% 100%;
  border-radius: 50%;
  overflow: hidden;
}
.user-image img {
  width: 100%;
}
.user-image.user-image-large {
  height: 100px;
  width: 100px;
}
.flat-button {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #f1f1f1 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #f1f1f1 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #f1f1f1 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff1f1f1', GradientType=0);
  border: 1px solid #DCDCDC;
  border-radius: 2px;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  margin-right: 16px;
  height: 29px;
  line-height: 28px;
  min-width: 54px;
  outline: 0;
  padding: 0 12px;
  display: inline-block;
  margin: 4px 0 0 0;
  vertical-align: top;
  color: #444;
}
.flat-button:hover {
  background-image: -webkit-linear-gradient(top, #4D90FE 0%, #4787ED 100%);
  background-image: -o-linear-gradient(top, #4D90FE 0%, #4787ED 100%);
  background-image: linear-gradient(to bottom, #4D90FE 0%, #4787ED 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4d90fe', endColorstr='#ff4787ed', GradientType=0);
  border: 1px solid #3079ED;
  color: #fff;
}
.flat-button.danger:hover {
  color: #fff;
}
.flat-button.static:hover {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #f1f1f1 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #f1f1f1 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #f1f1f1 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff1f1f1', GradientType=0);
  border: 1px solid #DCDCDC;
  color: #444;
}
.disable-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.header-notification {
  position: relative;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  float: left;
  padding: 0 30px 0 10px;
  border: 1px solid #e8d38d;
  border-radius: 3px;
  margin: 3px 20px;
  background-image: -webkit-linear-gradient(top, #FFFBE0 0%, #f5eebe 100%);
  background-image: -o-linear-gradient(top, #FFFBE0 0%, #f5eebe 100%);
  background-image: linear-gradient(to bottom, #FFFBE0 0%, #f5eebe 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffffbe0', endColorstr='#fff5eebe', GradientType=0);
}
.header-notification a {
  color: #67a4cc;
}
.header-notification:hover {
  background-image: -webkit-linear-gradient(top, #f6f2d8 0%, #F3EBCD 100%);
  background-image: -o-linear-gradient(top, #f6f2d8 0%, #F3EBCD 100%);
  background-image: linear-gradient(to bottom, #f6f2d8 0%, #F3EBCD 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff6f2d8', endColorstr='#fff3ebcd', GradientType=0);
}
.header-notification .close {
  padding: 4px;
  cursor: pointer;
  font-size: 10px;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;
  position: absolute;
  top: 0px;
  right: 5px;
  height: 12px;
  width: 12px;
  line-height: 12px;
}
.full header {
  box-shadow: none;
}
footer {
  display: none;
}
#sidebar {
  background-color: #fff;
  float: left;
  position: relative;
  z-index: 2;
  width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 2px #D4D2DB;
}
#sidebar hr {
  margin: 0 32px 10px 20px;
  width: auto;
}
#sidebar .sidebar-logo {
  height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  background: rgba(0, 0, 0, 0.05);
}
#sidebar .sidebar-logo img {
  max-height: 100%;
  max-width: 100%;
}
#sidebar .sub-menu {
  display: none;
}
#sidebar .section {
  cursor: pointer;
}
#sidebar .section.expanded .sub-menu {
  display: block;
}
#help-button {
  /* background: url(../images/help.svg) no-repeat center center; */
  background-size: 30px;
  height: 50px;
  width: 50px;
  opacity: 0.2;
  bottom: 0px;
  left: 0;
  position: absolute;
  transition: opacity 0.4s linear;
  cursor: pointer;
}
#help-button:hover {
  opacity: 0.7;
}
#support-message {
  max-width: 700px;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  padding: 60px 40px;
}
#support-message p {
  color: #22282B;
  font-size: 16px;
}
#support-message label {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 50px;
  color: #85939d;
  display: block;
}
#support-message textarea {
  display: block;
  width: 100%;
  height: 200px;
  font-size: 16px;
  margin-bottom: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}
#get-help {
  max-width: 800px;
  margin: 0 auto;
}
#get-help #support-message,
#get-help #contact-sent,
#get-help #contact-error {
  display: none;
}
#get-help #contact-sent,
#get-help #contact-error {
  text-align: center;
  padding: 40px 100px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
}
#get-help .error {
  color: #f00;
}
#get-help.contact #choose-help-type {
  display: none;
}
#get-help.contact #support-message {
  display: block;
}
#get-help.contact-sent #support-message {
  display: none;
}
#get-help.contact-sent #choose-help-type {
  display: none;
}
#get-help.contact-sent #contact-sent {
  display: block;
}
#get-help.contact-error #support-message {
  display: none;
}
#get-help.contact-error #choose-help-type {
  display: none;
}
#get-help.contact-error #contact-error {
  display: block;
}
#get-help .user-message {
  margin: 20px 0;
  background: #fafafa;
  text-align: left;
  padding: 20px;
  white-space: pre-wrap;
}
#choose-help-type {
  max-width: 800px;
  margin: 60px auto;
}
#choose-help-type:before,
#choose-help-type:after {
  content: " ";
  display: table;
}
#choose-help-type:after {
  clear: both;
}
#choose-help-type img {
  margin-bottom: 10px;
  display: none;
}
#choose-help-type h5 {
  color: #292c35;
  font-family: aktiv-grotesk-std, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 30px;
}
#choose-help-type p {
  font-size: 16px;
  line-height: 1.5;
}
#choose-help-type > div {
  width: 47.5%;
  float: left;
  position: relative;
  text-align: center;
  padding: 80px 40px 40px 40px;
  background: #f7fbff;
  box-shadow: 0 0 20px rgba(102, 103, 128, 0.07);
  border: 1px solid #ebeef8;
}
#choose-help-type > div:first-child {
  margin-right: 5%;
}
#choose-help-type .button {
  background: #86DB6F;
  border: none;
  color: #fff;
  display: block;
  margin: 0 auto;
  text-align: Center;
  font-size: 18px;
}
#choose-help-type h5.title {
  padding-left: 80px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 10px 20px 10px 20px;
  color: #fff;
  background: #45A1ED;
  z-index: 2;
  margin: 0;
}
#finish-setup {
  max-width: 800px;
  margin: 0 auto;
  padding: 120px 60px 60px 80px;
  background: #f7fbff;
  box-shadow: 0 0 20px rgba(102, 103, 128, 0.07);
  margin-top: 20px;
  border: 1px solid #ebeef8;
  position: relative;
}
#finish-setup h4.title {
  padding-left: 80px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 20px 20px 20px 40px;
  color: #fff;
  background: #45A1ED;
  z-index: 2;
  margin: 0;
}
#finish-setup ul h4 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #505863;
}
#finish-setup ul li {
  position: relative;
  margin-bottom: 60px;
}
#finish-setup ul span {
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #fff;
  background: #86DB6F;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: -40px;
  top: -5px;
}
#finish-setup ul p {
  color: #747a8c;
  display: none;
}
#finish-setup ul .more-info {
  display: none;
}
#finish-setup ul .more-info.showing {
  display: block;
}
#finish-setup .button {
  background: #86DB6F;
  border: none;
  color: #fff;
  display: block;
  margin: 0 auto;
  text-align: Center;
  font-size: 18px;
}
.sign-out,
.contact-us {
  line-height: 40px;
  color: #8F939B;
  font-size: 14px;
  padding: 0px 0 0 0;
  margin: 0px 40px 0px 20px;
}
.sign-out a,
.contact-us a {
  color: #8F939B;
}
.sign-out .indicator,
.contact-us .indicator {
  margin-right: 5px;
}
.has-payoneer-button {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.payoneer-button {
  background: #01a6ea;
  background: #4a94d2;
  background: #a5b6c4;
  background: #90a7b8;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  width: 150px;
  text-align: center;
  text-decoration: none;
  line-height: 28px;
  font-size: 1.2rem;
  border-radius: 3px;
  padding: 0 !important;
  margin: 20px 20px 15px 16px !important;
  display: block;
}
.payoneer-button:hover {
  border: none;
}
#sidebar-menu-items {
  margin-top: 2rem;
}
#sidebar-menu-items > li {
  line-height: 4rem;
  color: #8F939B;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
#sidebar-menu-items > li span,
#sidebar-menu-items > li a {
  position: relative;
}
#sidebar-menu-items > li > span:before,
#sidebar-menu-items > li > a:before {
  content: ' ';
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  top: 50%;
  left: 16px;
  margin-top: -1.25rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
}
#sidebar-menu-items > li:nth-child(1) > span:before,
#sidebar-menu-items > li:nth-child(1) > a:before {
  /* background: #552CF6 url(../images/invoice.svg) no-repeat center center / 1.2rem; */
}
#sidebar-menu-items > li:nth-child(2) > span:before,
#sidebar-menu-items > li:nth-child(2) > a:before {
  /* background: #2CADF6 url(../images/clients.svg) no-repeat center center / 1.2rem; */
}
#sidebar-menu-items > li:nth-child(3) > span:before,
#sidebar-menu-items > li:nth-child(3) > a:before {
  /* background: #41D0B6 url(../images/mysettings.svg */
        /* ) no-repeat center center / 1.2rem; */
}
#sidebar-menu-items > li:nth-child(5) > span:before,
#sidebar-menu-items > li:nth-child(5) > a:before {
  display: none;
  height: 0;
  width: 0;
}
#sidebar-menu-items > li:nth-child(7) > span:before,
#sidebar-menu-items > li:nth-child(7) > a:before {
  /* background: #E5E4EA url(../images/log-out.svg) no-repeat center center / 1.2rem; */
}
#sidebar-menu-items > li:nth-child(8) > span:before,
#sidebar-menu-items > li:nth-child(8) > a:before {
  /* background: #E5E4EA url(../images/email.svg) no-repeat center center / 1.2rem; */
}
#sidebar-menu-items > li a {
  color: #000;
  text-decoration: none;
}
#sidebar-menu-items li a,
#sidebar-menu-items .section-name {
  padding: 0 20px 0 5rem;
  display: block;
}
#sidebar-menu-items .divider {
  line-height: 0;
}
#sidebar-menu-items .divider hr {
  border-color: rgba(0, 0, 0, 0.07);
  margin: 2rem;
}
#sidebar-menu-items .sub-menu li {
  list-style: none;
  position: relative;
}
#sidebar-menu-items .sub-menu li a {
  color: #5b686e;
}
#sidebar-menu-items .sub-menu li > a:before {
  background: #000;
  content: ' ';
  position: absolute;
  height: 1px;
  width: 6px;
  top: 50%;
  left: 18px;
  opacity: 0.2;
  margin-top: -1px;
}
#sidebar-menu-items .sub-menu li.active #sidebar-menu-items .sub-menu li:before {
  background: #289af6;
}
#sidebar-menu-items li:not(.section):hover,
#sidebar-menu-items .section-name:hover,
#sidebar-menu-items li:not(.section).active,
#sidebar-menu-items li.section.active .section-name {
  background-color: rgba(255, 255, 255, 0.8);
}
#sidebar-menu-items li:not(.section).active .section-name:before,
#sidebar-menu-items li.section.active .section-name:before,
#sidebar-menu-items li.section.child-active .section-name:before,
#sidebar-menu-items li:not(.section).active > a:before,
#sidebar-menu-items li.section.active > a:before,
#sidebar-menu-items li.section.child-active > a:before {
  background-color: #289af6;
  opacity: 1;
}
#sidebar-menu-items .bubble {
  height: 20px;
  width: 22px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  background: rgba(95, 215, 66, 0.6);
  color: #fff;
  border-radius: 9px;
  font-size: 11px;
  margin-left: 8px;
}
#sidebar-menu-items .bubble.empty {
  display: none;
}
.sidebar-icon {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  width: 24px;
  display: none;
  margin: 0;
  font-size: 17px;
  color: #383F45;
  top: 1px;
  margin-right: 10px;
}
#current-user {
  padding: 10px 1.2rem 10px 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 2rem;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
#current-user a {
  color: #383F45;
}
#current-user .user-image-wrapper {
  height: 2rem;
  text-align: center;
  position: relative;
  display: block;
  display: none;
}
#current-user .user-image {
  display: inline-block;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
}
#current-user .user-image img {
  vertical-align: top;
  width: 100%;
}
#current-user .user-meta > div {
  padding: 0 10px;
  word-break: break-all;
  display: inline-block;
  line-height: 1.3;
  font-size: 1.3rem;
}
#current-user .user-name {
  color: #383F45;
}
#current-user .user-role {
  color: #959ca0;
}
#current-user:hover #logout {
  display: block;
}
.has-logo #current-user {
  padding-top: 15px;
  padding-bottom: 15px;
}
.has-logo #current-user .user-image-wrapper {
  height: 30px;
  width: 30px;
  display: inline-block;
  position: absolute;
  top: 20px;
  left: 15px;
}
.has-logo #current-user .user-image {
  display: inline-block;
  height: 30px;
  width: 30px;
}
.has-logo #current-user .user-meta {
  padding-left: 60px;
  text-align: left;
  margin-top: 7px;
}
.has-logo #current-user .user-meta > div {
  display: block;
  font-size: 12px;
}
.has-logo #current-user .user-role span {
  display: none;
}
#app-wrapper.white #sidebar {
  background: #fff;
}
#app-wrapper.white #sidebar li a {
  color: #818181;
}
#app-wrapper.white #sidebar li:hover a {
  color: #202020;
}
#app-wrapper.white #sidebar #current-user {
  background: #fff;
}
#app-wrapper.white .entity-list {
  border-left: 1px solid #e1e6ef;
}
#app-wrapper.grey #sidebar {
  background: #2a2d33;
}
#app-wrapper.grey #sidebar #global-search {
  background: rgba(0, 0, 0, 0.15);
  color: #aaa;
  position: relative;
}
#app-wrapper.grey #sidebar .sidebar-logo {
  background: #464b57;
}
#app-wrapper.grey #sidebar #current-user {
  border-bottom: none;
}
#app-wrapper.grey #sidebar #current-user .user-name {
  color: #ddd;
}
#app-wrapper.grey #sidebar #current-user .user-role {
  color: #ccc;
}
#app-wrapper.grey #sidebar #footer-space {
  border-top-color: transparent;
}
#app-wrapper.grey #sidebar-menu-items li {
  margin: 0;
  width: 100%;
  border-radius: 0;
}
#app-wrapper.grey #sidebar-menu-items li a,
#app-wrapper.grey #sidebar-menu-items li .section-name {
  color: #aeaeb0;
}
#app-wrapper.grey li:not(.section):hover,
#app-wrapper.grey .section-name:hover,
#app-wrapper.grey li:not(.section).active,
#app-wrapper.grey li.section.active .section-name {
  background-color: rgba(255, 255, 255, 0.1);
}
#app-wrapper.grey li:not(.section).active .section-name:before,
#app-wrapper.grey li.section.active .section-name:before,
#app-wrapper.grey li.section.child-active .section-name:before,
#app-wrapper.grey li:not(.section).active > a:before,
#app-wrapper.grey li.section.active > a:before,
#app-wrapper.grey li.section.child-active > a:before {
  background-color: #ffffff;
  opacity: 0.8;
}
#app-wrapper[class^=blur] #sidebar {
  /* background: #2a2d33 url(../images/blur/3.jpg); */
}
#app-wrapper[class^=blur] #sidebar #global-search {
  background: rgba(255, 255, 255, 0.1);
  color: #aaa;
  position: relative;
}
#app-wrapper[class^=blur] #sidebar #global-search::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(0, 0, 0, 0.2);
}
#app-wrapper[class^=blur] #sidebar #global-search:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(0, 0, 0, 0.2);
}
#app-wrapper[class^=blur] #sidebar #global-search::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(0, 0, 0, 0.2);
}
#app-wrapper[class^=blur] #sidebar #global-search:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(0, 0, 0, 0.2);
}
#app-wrapper[class^=blur] #sidebar #current-user {
  background: transparent;
}
#app-wrapper[class^=blur] #sidebar #current-user .user-name {
  color: rgba(255, 255, 255, 0.9);
}
#app-wrapper[class^=blur] #sidebar #current-user .user-role {
  color: rgba(255, 255, 255, 0.7);
}
#app-wrapper[class^=blur] #sidebar #footer-space {
  border-top-color: transparent;
}
#app-wrapper[class^=blur] #sidebar-menu-items li {
  margin: 0;
  width: 100%;
  border-radius: 0;
}
#app-wrapper[class^=blur] #sidebar-menu-items li .sidebar-icon,
#app-wrapper[class^=blur] #sidebar-menu-items li .section-name {
  color: #fff;
}
#app-wrapper[class^=blur] #sidebar-menu-items li a {
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
}
#app-wrapper[class^=blur] #sidebar-menu-items li:not(.section):hover,
#app-wrapper[class^=blur] #sidebar-menu-items .section-name:hover,
#app-wrapper[class^=blur] #sidebar-menu-items li:not(.section).active,
#app-wrapper[class^=blur] #sidebar-menu-items li.section.active .section-name {
  background-color: rgba(255, 255, 255, 0.1);
}
#app-wrapper[class^=blur] #sidebar-menu-items > li > span:before,
#app-wrapper[class^=blur] #sidebar-menu-items > li > a:before {
  background: rgba(255, 255, 255, 0.3);
}
#app-wrapper[class^=blur] #sidebar-menu-items li:not(.section).active .section-name:before,
#app-wrapper[class^=blur] #sidebar-menu-items li.section.active .section-name:before,
#app-wrapper[class^=blur] #sidebar-menu-items li.section.child-active .section-name:before,
#app-wrapper[class^=blur] #sidebar-menu-items li:not(.section).active > a:before,
#app-wrapper[class^=blur] #sidebar-menu-items li.section.active > a:before,
#app-wrapper[class^=blur] #sidebar-menu-items li.section.child-active > a:before {
  background-color: #ffffff;
  opacity: 0.8;
}
#app-wrapper.blur2 #sidebar {
  /* background: #2a2d33 url(../images/blur/1.jpg); */
}
#app-wrapper.blur2 #sidebar-wrapper {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
}
#app-wrapper.blur2 #global-search {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.1);
}
#app-wrapper.blur2::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(255, 255, 255, 0.6);
}
#app-wrapper.blur2:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.7);
}
#app-wrapper.blur2::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.7);
}
#app-wrapper.blur2:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(255, 255, 255, 0.7);
}
#app-wrapper.dark-blue #sidebar {
  background: #444b55;
}
#app-wrapper.dark-blue #sidebar #global-search {
  background: rgba(0, 0, 0, 0.1);
}
#app-wrapper.dark-blue #sidebar #footer-space {
  border-top-color: rgba(255, 255, 255, 0.1);
}
#app-wrapper.dark-blue #sidebar #current-user .user-name {
  color: #eee;
}
#app-wrapper.dark-blue #sidebar li .sidebar-icon,
#app-wrapper.dark-blue #sidebar li .section-name {
  color: #b1b4c2;
}
#app-wrapper.dark-blue #sidebar li a {
  color: #b1b4c2;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
}
#app-wrapper.dark-blue #sidebar li:not(.section):hover,
#app-wrapper.dark-blue #sidebar .section-name:hover,
#app-wrapper.dark-blue #sidebar li:not(.section).active,
#app-wrapper.dark-blue #sidebar li.section.active .section-name {
  background-color: rgba(255, 255, 255, 0.1);
}
#app-wrapper.dark-blue #sidebar li:not(.section).active .section-name:before,
#app-wrapper.dark-blue #sidebar li.section.active .section-name:before,
#app-wrapper.dark-blue #sidebar li.section.child-active .section-name:before,
#app-wrapper.dark-blue #sidebar li:not(.section).active > a:before,
#app-wrapper.dark-blue #sidebar li.section.active > a:before,
#app-wrapper.dark-blue #sidebar li.section.child-active > a:before {
  background-color: #ffffff;
  opacity: 0.8;
}
#logo-space {
  height: 0px;
  background: #2381E9;
}
#running-timer-space {
  float: left;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
}
#notification-space {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
#notification-space .notification {
  min-height: 50px;
  padding: 15px 0;
  opacity: 0;
}
#notification-space .notification-inner {
  padding: 0 60px;
  text-align: center;
}
#notification-space .close {
  float: right;
  font-size: 17px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  opacity: 1;
  right: 20px;
  top: 8px;
}
#logout {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: none;
  transition: all linear 0.2s;
  opacity: 0.3;
}
#logout:hover {
  opacity: 0.5;
}
#logout .icon {
  background-position: -384px 0;
  margin: 12px 4px 0 0;
}
#global-search-space {
  position: relative;
  height: 40px;
  width: 90%;
  margin: 0 auto;
}
#global-search-space:after {
  font-family: 'themify';
  height: 20px;
  width: 20px;
  position: absolute;
  top: 11px;
  left: 8px;
  content: "\e610";
  color: rgba(0, 0, 0, 0.4);
}
#global-search-wrapper {
  height: 100%;
}
#global-search {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 100%;
  margin: 0;
  width: 100%;
  padding: 0 10px 0 27px;
  color: #333;
}
#global-search:focus {
  outline: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
#global-search:focus:after {
  color: rgba(0, 0, 0, 0.6);
}
#global-search::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #969fa6;
}
#global-search:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #969fa6;
}
#global-search::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #969fa6;
}
#global-search:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #969fa6;
}
#footer-space {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 92%;
  margin: 0 4%;
  height: 0px;
}
#footer-space a {
  color: #c0c0c0;
  width: 50%;
  display: block;
  float: left;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}
#footer-space a:hover {
  color: #333;
  background: rgba(255, 255, 255, 0.7);
}
.window-outer {
  width: 100%;
  padding-left: 120px;
  position: absolute;
  right: 0;
  top: 0;
}
.full .window-outer {
  padding-left: 200px;
}
.public.full .window-outer {
  padding-left: 0;
}
.mini-header .window-outer {
  top: 0;
}
.window {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 20px 20px 20px 0;
  overflow: hidden;
  float: right;
  z-index: 0;
  border-radius: 4px;
  background: #f0f1f6;
  background: #fefefe;
  -webkit-background-clip: border-box;
  opacity: 0;
}
.window.one-panel .panel {
  display: none;
}
.window.one-panel .secondary {
  display: block;
  width: 100%;
  border-left: none;
}
.full .window {
  margin: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}
.window-menu {
  height: 20px;
  background: #fafafa;
  box-shadow: inset 0 -1px 0 0px #ffffff;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 -1px 1px rgba(255, 255, 255, 0.25);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 -1px 1px rgba(255, 255, 255, 0.25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 -1px 1px rgba(255, 255, 255, 0.25);
  margin-bottom: 10px;
  border-bottom: 1px solid #D9D9D9;
  padding: 10px 24px;
}
.window-menu h3 {
  line-height: 20px;
  color: #666;
}
.viewport-shadow {
  content: ' ';
  width: 100%;
  height: 5px;
  display: block;
  -webkit-mask-box-image: -webkit-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 50%, transparent 100%);
  background-color: transparent;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
}
.viewport-no-shadow {
  background-image: none;
}
.panel {
  height: 100%;
  position: relative;
  float: left;
  width: 20%;
}
.panel .hidden {
  display: none;
}
.panel.secondary {
  width: 100%;
  background: #F6F5F9;
}
.panel.secondary .inner-content > div:first-child {
  margin-bottom: 20px;
}
.panel.secondary .inner-content > div:first-child:before,
.panel.secondary .inner-content > div:first-child:after {
  content: " ";
  display: table;
}
.panel.secondary .inner-content > div:first-child:after {
  clear: both;
}
.panel.secondary.full-width {
  width: 100%;
  border-left: none;
}
.panel.secondary .nano:after {
  content: ' ';
  width: 100%;
  height: 5px;
  display: block;
  -webkit-mask-box-image: -webkit-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 50%, transparent 100%);
  background-color: transparent;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
}
.panel.secondary .nano.top:after {
  background-image: none;
}
.panel.secondary .nano .content {
  outline: none !important;
}
.slide-out-panel-open .panel.secondary {
  border-left: 1px solid #e1e6ef;
}
.panel-overlay {
  display: none;
}
.panel.flat {
  background: #fff;
}
.panel.bordered {
  box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.2);
}
.panel.top-info {
  padding-top: 61px;
}
.panel.half {
  width: 50%;
  float: left;
}
.half:last-child {
  box-shadow: -4px 0 6px rgba(0, 0, 0, 0.07), inset 1px 0 0 0 rgba(0, 0, 0, 0.2);
}
.disable-interaction-overlay {
  display: none;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  transition: opacity linear 2s;
  background: rgba(255, 255, 255, 0.9);
}
.disable-interaction-overlay.showing {
  display: block;
  opacity: 1;
}
.panel-info {
  height: 60px;
  padding-top: 10px;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  background: #F6F5F9;
  max-width: 1080px;
  margin: 0 auto;
  padding-right: 40px;
  padding-left: 40px;
}
.panel-info h3 {
  line-height: 60px;
  display: inline-block;
  font-size: 22px;
  font-weight: normal;
}
.panel-info .panel-title {
  text-align: center;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  color: #505960;
}
.panel-info .panel-left {
  width: 70%;
}
.panel-info .panel-left .panel-title {
  max-width: 30%;
}
.panel-info .panel-left .panel-title-widget {
  width: 59%;
}
.panel-info .filter {
  float: right;
}
.panel-info .panel-actions-wrapper {
  display: table;
  position: absolute;
  right: 30px;
  top: 10px;
  margin: 0;
}
.panel-info .panel-actions-wrapper .panel-actions {
  display: table-cell;
  vertical-align: middle;
}
.panel-info .panel-actions-wrapper .panel-actions > div {
  margin-right: 10px;
}
.panel-info .panel-actions-wrapper .panel-actions > div:last-child {
  margin-right: 0;
}
.panel-info .panel-actions-wrapper .panel-actions .button-set {
  vertical-align: top;
}
.panel-info .panel-title-widget {
  display: inline-block;
  padding-left: 10px;
  vertical-align: top;
}
.panel-info #discuss-button-wrapper {
  display: inline-block;
  vertical-align: top;
  border-left: 1px solid #f5f5f5;
  margin-left: 10px;
  padding-left: 10px;
}
.panel-info .button {
  background: #f5f5f5;
  border-radius: 4px;
  line-height: 40px;
  height: 40px;
  padding: 0 30px;
  color: #3d3c40;
  border: 1px solid transparent !important;
  font-size: 16px;
}
.panel-info .button:hover {
  box-shadow: none;
}
.panel-info .new-entity,
.panel-info .button.main-action {
  background: #DEDCE6;
  color: #000;
  cursor: pointer;
}
.panel-info .new-entity .icon,
.panel-info .button.main-action .icon {
  color: #fff;
}
.panel-info .new-entity.green,
.panel-info .button.main-action.green,
.panel-info .new-entity .secondary-action,
.panel-info .button.main-action .secondary-action {
  background: #552CF6;
}
.panel-info .new-entity:hover,
.panel-info .button.main-action:hover {
  background: #2CADF6;
  color: #fff;
}
.panel-info .new-entity:hover .icon,
.panel-info .button.main-action:hover .icon {
  color: #fff !important;
}
.panel-info .secondary-action {
  background: #552CF6;
  color: #fff;
}
.panel-info .new-entity,
.panel-info .button {
  border-radius: 4px;
}
.entity-actions .button {
  background: #f5f5f5;
  border-radius: 4px;
  line-height: 30px;
  height: 30px;
  padding: 0 20px;
  border: none;
  color: #3d3c40;
}
.panel > .inner {
  list-style: none;
  margin: 0 0 30px 0;
  overflow: hidden;
  position: relative;
}
.panel-info-hidden .panel-info {
  display: none;
}
.panel-info-hidden.panel > .inner {
  margin-top: 0;
}
.secondary .filter {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 200px;
}
.inner-content-wrapper {
  position: relative;
  z-index: 0;
}
.inner-content.content > div {
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 30px;
}
#discuss-project-button {
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  background: #f5f5f5;
  color: #9c9c9c;
  cursor: pointer;
  position: relative;
}
#discuss-project-button .icon {
  color: #9c9c9c;
}
#discuss-project-button:hover {
  background: #4ea0e2;
}
#discuss-project-button:hover .icon {
  color: #fff;
}
.inner-left {
  display: block;
  margin-right: 0px;
  position: relative;
  left: 1px;
}
.inner-left .new-entity {
  float: right;
}
.inner-right {
  display: none;
  width: 300px;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  margin-left: 20px;
}
.info-showing #info-panel {
  visibility: visible;
  margin-right: 0;
}
.info-showing #info-panel-overlay,
.info-showing #info-panel-wrapper {
  visibility: visible;
  opacity: 1;
}
#info-panel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  z-index: 3;
}
#info-panel-overlay {
  background: linear-gradient(to left, rgba(188, 198, 206, 0.17) 50%, rgba(98, 112, 120, 0.1));
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;
}
#info-panel {
  width: 500px;
  visibility: hidden;
  background: #fefefe;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  height: 100%;
  box-shadow: inset 0px 0 20px rgba(0, 0, 0, 0.05);
  box-shadow: inset 3px 0 5px rgba(0, 0, 0, 0.05);
  margin-right: -500px;
  box-shadow: 3px 0 20px rgba(0, 0, 0, 0.2);
  transition: margin-right 0.2s ease;
  overflow: auto;
  border-left: 1px solid #ccc;
}
#info-panel .title {
  border-bottom: 1px solid #e8e9ea;
  margin: 0 20px;
}
#info-panel .title:before,
#info-panel .title:after {
  content: " ";
  display: table;
}
#info-panel .title:after {
  clear: both;
}
#info-panel .title > * {
  display: block;
  float: left;
}
#info-panel .title .close {
  height: 58px;
  line-height: 58px;
  padding-right: 20px;
  text-align: center;
  font-size: 19px;
  cursor: pointer;
}
#info-panel .title .close:hover {
  color: #259af4;
}
#info-panel .title .button-space {
  display: block;
  float: right;
  padding-right: 10px;
  height: 58px;
}
#info-panel .title h3 {
  display: inline-block;
  max-width: 100px;
  line-height: 58px;
  height: 58px;
  margin: 0;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#info-panel .title .new-entity,
#info-panel .title .button {
  position: relative;
  top: 11px;
}
#info-panel .title .button.main-action {
  color: #444;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
#info-panel .title .button.main-action .icon {
  margin-right: 10px;
}
#info-panel .title .button.main-action:hover {
  color: #2381e9;
}
#info-panel .title .button.main-action:hover .icon {
  color: #2381e9 !important;
}
#info-panel .title .button {
  background: none;
  border: none;
}
#info-panel .title .new-entity,
#info-panel .title .button.main-action {
  color: #2381e9;
}
#info-panel .title .new-entity .icon,
#info-panel .title .button.main-action .icon {
  color: #2381e9;
}
#info-panel .form {
  padding: 20px;
}
#info-panel .form input[type=text],
#info-panel .form input[type=number],
#info-panel .form input[type=password],
#info-panel .form textarea {
  border-top-color: #D9D9D9;
}
#info-panel .item-details-inner {
  margin: 0;
}
#discussion-panel {
  height: 100%;
  position: relative;
  margin: 0 auto;
}
#discussion-panel .nano:after {
  content: ' ';
  width: 100%;
  height: 5px;
  display: block;
  -webkit-mask-box-image: -webkit-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.8) 50%, transparent 100%);
  background-color: transparent;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.1), transparent);
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
}
#discussion-panel .nano.top:after {
  background-image: none;
}
#discussion-panel #top-details {
  margin-right: 20px;
  overflow: hidden;
  padding: 0 30px;
  text-align: center;
}
#discussion-panel #top-details > div {
  display: inline-block;
  padding: 0px 30px 0px 0;
}
#discussion-panel #top-details > div:last-child {
  padding-right: 0;
}
#discussion-panel #top-details h3 {
  font-size: 16px;
  color: #444;
  margin: 0;
}
#discussion-panel #top-details h5 {
  text-transform: uppercase;
  color: #888;
  font-size: 10px;
  margin-top: 1px;
  margin-bottom: 0;
}
#discussion-panel #attach-files {
  font-size: 12px;
  top: 1px;
  left: 21px;
  position: absolute;
  padding: 2px 8px;
  height: 48px;
  line-height: 42px;
  cursor: pointer;
  border-right: 1px solid #ccc;
}
#discussion-panel #attach-files span {
  font-size: 20px;
}
#discussion-panel #attach-files:hover {
  background: #fafafa;
}
#discussion-panel .message-files {
  font-size: 15px;
}
#discussion-panel .message-files .icon {
  color: #2381e9 !important;
  font-size: 12px !important;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
#discussion-header-wrapper {
  height: 30px;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  text-align: center;
}
#discussion-header-wrapper h4 {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #999;
  display: inline-block;
  font-size: 15px;
}
#discussion-header-wrapper #discussion-enity-title {
  color: #888;
  padding-right: 5px;
}
#discussion-header-wrapper > div {
  display: inline-block;
  width: 100%;
  line-height: 22px;
  color: #aaa;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#discussion-header-wrapper .icon {
  cursor: pointer;
  margin-right: 10px;
  float: right;
}
#discussion-header-wrapper #refresh-messages {
  float: right;
  background-position: -240px -24px;
}
#discussion-panel.minimal {
  margin: 0 20px;
}
#discussion-panel.minimal #discussion-header-wrapper {
  position: relative;
  border-bottom: none;
  margin-bottom: 20px;
  text-align: left;
}
#discussion-panel.minimal #discussion-header-wrapper h4 {
  line-height: 30px;
}
#discussion-panel.minimal #discussion-header-wrapper .button {
  display: none;
}
#messages-list-wrapper {
  z-index: 0;
  margin: 0;
  margin: 30px 20px 0 0;
  list-style: none;
}
#messages-list-wrapper ul {
  margin-bottom: 20px;
}
#messages-list {
  padding-top: 20px;
}
#new-message {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  background: #fff;
  z-index: 999999;
  height: 60px;
}
#new-message #send-message,
#new-message #sending-message {
  height: 50px;
  position: absolute;
  right: 20px;
  top: 0px;
  line-height: 48px;
  font-size: 14px;
  background: #4ea0e2;
  color: #fff;
  padding: 2px 10px;
  border-radius: 0 3px 3px 0;
}
#new-message #sending-message {
  display: none;
}
#new-message.sending-message #send-message {
  display: none;
}
#new-message.sending-message #sending-message {
  display: block;
}
#new-message #message-area-wrapper {
  position: relative;
}
#new-message textarea,
#new-message #message-area-wrapper,
#new-message #sending-message-overlay {
  height: 50px;
  border-radius: 3px;
  border-color: #ccc;
}
#new-message textarea {
  width: 100%;
  box-shadow: none;
  padding-left: 47px;
  padding-right: 130px;
}
#new-message #message-file-name-space {
  height: 30px;
  border-top: none;
  display: none;
  position: relative;
}
#new-message #message-file-name-space .remove-files {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #2381e9;
  font-size: 12px;
  width: 76px;
}
#new-message #file-names {
  position: relative;
  padding-right: 81px;
}
#new-message #file-names span {
  display: inline-block;
  padding: 0 10px;
  line-height: 22px;
  height: 22px;
  margin: 4px 5px;
  background: #DFEDFF;
  border-radius: 3px;
}
#new-message.has-files {
  height: 80px;
}
#new-message.has-files #message-file-name-space {
  display: block;
}
/** Comments **/
.user-messages-section .user-info > a {
  display: inline-block;
  vertical-align: top;
}
.user-messages-section .user-info .user-link {
  line-height: 32px;
  margin-left: 20px;
  font-weight: bold;
  color: #3d3c40;
}
.message {
  position: relative;
  padding: 10px;
}
.message:hover {
  background: rgba(248, 250, 250, 0.8);
}
.message .posted-on {
  font-size: 12px;
  max-width: 80%;
  display: block;
  float: right;
  position: relative;
  color: #555;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.message .posted-on a {
  color: #6f8ca3;
}
.message .posted-on span {
  position: relative;
  z-index: 1;
}
.message .posted-on hr {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 0;
  margin: 0;
  display: none;
}
.message .posted-on a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.message .message-contents {
  position: relative;
}
.message .user-image {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 50px;
  height: 50px;
}
.message .message-main {
  width: 75%;
  display: inline-block;
  padding-left: 57px;
  vertical-align: top;
}
.message .message-text-inner {
  padding-top: 3px;
  color: #000;
}
.message .message-text-inner pre {
  font-family: Inter, Arial, sans-serif;
  color: #3d3c40;
  line-height: 1.3;
  margin: 0;
  white-space: pre-wrap;
}
.message .message-meta {
  width: 24%;
  display: inline-block;
  text-align: right;
}
.message .user-image {
  display: inline-block;
}
.message:first-child {
  margin-top: 0;
}
.message .message-date {
  color: #fff;
  display: block;
  margin-top: 2px;
  font-size: 12px;
}
.message:hover .message-date {
  color: #333;
}
.message .user-link {
  font-weight: bold;
  color: #2988cc;
  font-size: 14px;
}
#slide-out-panel-wrapper {
  float: left;
  position: relative;
  width: 0;
  overflow: visible;
  height: 100%;
}
.slide-out-panel {
  height: 100%;
  width: 600px;
  border-left: 1px solid #e1e6ef;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -600px;
  z-index: 1;
}
.slide-out-panel > div {
  float: right;
}
.entity-list {
  height: 100%;
  width: 400px;
  position: relative;
}
.entity-list-header {
  position: absolute;
  top: 0;
  left: 0;
  height: 51px;
  width: 100%;
  padding: 11px 15px;
  z-index: 2;
  border-bottom: 1px solid #e1e6ef;
}
.entity-list-header > .entity-list-title,
.entity-list-header > div {
  display: inline-block;
}
.entity-list-header .entity-actions {
  float: right;
}
.entity-list-title {
  margin: 0;
  line-height: 28px;
}
.entity-list-button {
  background: #285CFF;
  color: #fff;
  border: none;
  display: inline-block;
}
.entity-list-button:hover {
  background: #285CFF;
  color: #fff !important;
  border: none;
}
.entity-list-button.disabled,
.entity-list-button.disabled:hover {
  opacity: 0.6;
  cursor: not-allowed;
}
.entity-list-button.large {
  padding: 10px 40px;
  font-size: 16px;
}
.entity-list-button:hover {
  box-shadow: 0 1px 2px rgba(43, 50, 57, 0.08);
  border: 1px solid #bdbdbd;
}
.entity-list-button[disabled=disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.entity-list-content {
  padding-top: 50px;
  height: 100%;
}
.item-details {
  float: left;
  height: 100%;
  width: 100%;
}
.item-details .item-details-main {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.item-details .item-details-main:before,
.item-details .item-details-main:after {
  content: " ";
  display: table;
}
.item-details .item-details-main:after {
  clear: both;
}
.item-details .item-details-inner {
  margin: 0 20px;
}
.item-details .item-notes {
  margin-top: 20px;
  float: left;
}
.item-details .item-notes p {
  margin-top: 10px;
}
.item-details-list {
  list-style: none;
  margin: 10px 0 40px 0;
}
.item-details-list:last-child {
  margin-bottom: 0;
}
.item-details-list li {
  clear: both;
  line-height: 14px;
  margin-bottom: 10px;
}
.item-details-list li:before,
.item-details-list li:after {
  content: " ";
  display: table;
}
.item-details-list li:after {
  clear: both;
}
.item-details-list .email-details .icon {
  background-position: -72px 0;
}
.item-details-list .address-details .icon {
  background-position: 0 -24px;
  vertical-align: top;
}
.item-details-list .address-details .address {
  display: inline-block;
  line-height: 18px;
}
.item-details-list .phone-details .icon {
  background-position: -72px -144px;
}
.item-details-list .website-details .icon {
  background-position: -336px -144px;
}
.task-details .item-details-inner {
  position: relative;
}
.task-details .task-main {
  width: 900px;
  margin: 0 auto;
  position: relative;
}
.task-details .task-main:before,
.task-details .task-main:after {
  content: " ";
  display: table;
}
.task-details .task-main:after {
  clear: both;
}
.task-details .is-task-completed,
.task-details .retry-save,
.task-details .saving-indicator {
  position: absolute;
}
.task-details .is-task-completed {
  left: 0;
  height: 16px;
  width: 16px;
  background: none;
  border: 1px solid #888;
  border-radius: 3px;
}
.task-details .saving-indicator {
  left: 0px;
  height: 16px !important;
  width: 16px;
  /* background: transparent url(../images/tasks/task-saving.gif) no-repeat 0 0; */
  display: none;
}
.task-details .retry-save {
  height: 14px !important;
  width: 14px;
  margin: 10px 0 0 10px;
  left: 14px;
  /* background-image: url(../images/glyphicons-halflings.png); */
  background-repeat: no-repeat;
  background-position: 0 -120px;
  cursor: pointer;
  opacity: 0.5;
  display: none;
}
.task-details .saving .is-task-completed {
  display: none;
}
.task-details .saving .saving-indicator {
  display: block;
}
.task-details .saving .retry-save {
  display: none;
}
.task-details .error-saving .is-task-completed {
  display: none;
}
.task-details .error-saving .saving-indicator {
  display: none;
}
.task-details .error-saving .retry-save {
  display: block;
}
.task-details .task-content-section {
  padding: 20px 0;
  width: 650px;
  float: left;
  border: 1px solid #cfcfcf;
}
.task-details .task-content {
  position: relative;
  padding: 0 20px 20px 50px;
}
.task-details .task-details-meta {
  padding: 20px;
  width: 250px;
  float: left;
  color: #888;
}
.task-details .task-details-meta span {
  display: block;
  float: left;
  font-weight: bold;
  margin-right: 10px;
  width: 95px;
}
.task-details .task-details-meta > div {
  margin-bottom: 10px;
}
.task-details .task-details-meta > div:before,
.task-details .task-details-meta > div:after {
  content: " ";
  display: table;
}
.task-details .task-details-meta > div:after {
  clear: both;
}
.task-details .is-task-completed {
  left: 20px !important;
  top: 4px;
}
.task-details .task-text {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 150%;
}
.task-details.complete .is-task-completed {
  /* background-image: url(../images/tasks/task-complete.gif) !important; */
  background-position: center center;
  background-size: 18px !important;
  border: none !important;
}
.task-details.complete .task-text {
  text-decoration: line-through;
}
.task-details.complete #task-edit {
  display: none;
}
.task-details .task-description {
  color: #888;
  margin-bottom: 20px;
}
.task-details .task-actions {
  display: block !important;
  top: 0 !important;
  width: auto !important;
  position: relative !important;
  float: right;
}
.task-details .task-actions .button {
  float: right;
  margin-right: 10px;
}
.task-details .task-weight-value .value {
  float: left;
  margin-right: 10px;
}
.task-details #change-task-weight {
  background-position: 0 -72px;
  height: 14px;
  width: 14px;
  cursor: pointer;
  transition: all 0.2s linear;
  opacity: 0;
}
.task-details .button {
  display: inline-block;
  cursor: pointer;
  opacity: 0.95;
  transition: all 0.2s linear;
}
.task-details .button:hover {
  opacity: 1;
}
.task-details .task-files {
  padding: 20px 20px 0 20px;
}
.task-details .task-files ol {
  list-style: none;
  padding: 0;
}
.task-details .task-files li {
  line-height: 30px;
  padding-left: 30px;
  /* background: transparent url(../images/activity/file.png) no-repeat 10px center; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.task-details h6 {
  font-weight: bold;
  color: #000;
  font-size: 17px;
  padding: 10px 20px;
  cursor: pointer;
}
.task-details h6:hover {
  color: #259af4;
}
.task-details-alternate {
  padding: 20px;
}
.task-details-alternate .item-details-inner {
  position: relative;
}
.task-details-alternate .is-task-completed,
.task-details-alternate .retry-save,
.task-details-alternate .saving-indicator {
  position: absolute;
}
.task-details-alternate .is-task-completed {
  left: 0;
  height: 16px;
  width: 16px;
  background: none;
  border: 1px solid #888;
  border-radius: 3px;
}
.task-details-alternate .saving-indicator {
  left: 0px;
  height: 16px !important;
  width: 16px;
  /* background: transparent url(../images/tasks/task-saving.gif) no-repeat 0 0; */
  display: none;
}
.task-details-alternate .retry-save {
  height: 14px !important;
  width: 14px;
  margin: 10px 0 0 10px;
  left: 14px;
  /* background-image: url(../images/glyphicons-halflings.png); */
  background-repeat: no-repeat;
  background-position: 0 -120px;
  cursor: pointer;
  opacity: 0.5;
  display: none;
}
.task-details-alternate .saving .is-task-completed {
  display: none;
}
.task-details-alternate .saving .saving-indicator {
  display: block;
}
.task-details-alternate .saving .retry-save {
  display: none;
}
.task-details-alternate .error-saving .is-task-completed {
  display: none;
}
.task-details-alternate .error-saving .saving-indicator {
  display: none;
}
.task-details-alternate .error-saving .retry-save {
  display: block;
}
.task-details-alternate #task-time-entries-wrapper {
  display: none;
}
.task-details-alternate.has-time-entries #task-time-entries-wrapper {
  display: block;
}
.task-details-alternate .task-content {
  position: relative;
  padding: 0 50px 0px 50px;
}
.task-details-alternate .user-image {
  position: absolute;
  top: 0;
  right: 0;
}
.task-details-alternate .task-description {
  padding-left: 20px;
}
.task-details-alternate .task-details-meta {
  margin-top: 40px;
  margin-bottom: 30px;
  padding-left: 20px;
  font-size: 12px;
}
.task-details-alternate .task-details-meta span.meta-tag {
  display: inline-block;
  color: #999;
  text-transform: uppercase;
  width: 100px;
  margin-right: 5px;
}
.task-details-alternate .task-details-meta > div {
  display: block;
  margin-right: 10px;
  margin-bottom: 15px;
}
.task-details-alternate .task-details-meta [class^="ti-"] {
  color: #bbb;
  margin-right: 5px;
  font-size: 17px;
}
.task-details-alternate .is-task-completed {
  left: 20px !important;
  top: 4px;
}
.task-details-alternate .task-text {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 150%;
}
.task-details-alternate.complete .is-task-completed {
  /* background-image: url(../images/tasks/task-complete.gif) !important; */
  background-position: center center;
  background-size: 18px !important;
  border: none !important;
}
.task-details-alternate.complete .task-text {
  text-decoration: line-through;
}
.task-details-alternate.complete #task-edit {
  display: none;
}
.task-details-alternate .task-description {
  color: #888;
  margin-bottom: 20px;
}
.task-details-alternate .task-actions {
  display: block !important;
  top: 0 !important;
  width: auto !important;
  position: relative !important;
  float: right;
}
.task-details-alternate .task-actions .button {
  float: right;
  margin-right: 10px;
}
.task-details-alternate .task-weight-value .value {
  float: left;
  margin-right: 10px;
}
.task-details-alternate #change-task-weight {
  background-position: 0 -72px;
  height: 14px;
  width: 14px;
  cursor: pointer;
  transition: all 0.2s linear;
  opacity: 0;
}
.task-details-alternate .button {
  display: inline-block;
  cursor: pointer;
  opacity: 0.95;
  transition: all 0.2s linear;
  margin-left: 0 !important;
}
.task-details-alternate .button:hover {
  opacity: 1;
}
.task-details-alternate .task-files {
  margin-top: 20px;
  margin-bottom: 20px;
}
.task-details-alternate .task-files ol {
  list-style: none;
  padding: 0 0 0 50px;
}
.task-details-alternate .task-files a {
  color: #777;
}
.task-details-alternate .task-files li {
  line-height: 30px;
  padding-left: 20px;
  /* background: transparent url(../images/activity/file.png) no-repeat 0px center; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.task-details-alternate .task-weight-value {
  display: inline-block;
}
.task-details-alternate h6 {
  color: #000;
  margin-bottom: 15px;
  font-size: 17px;
  padding: 10px 0px;
  cursor: pointer;
}
.task-details-alternate h6 span {
  float: right;
  display: inline-block;
  position: relative;
  display: none;
}
.task-details-alternate h6 .closed {
  display: inline-block;
}
.task-details-alternate h6.showing span {
  display: inline-block;
}
.task-details-alternate h6.showing span.closed {
  display: none;
}
.task-details-alternate h6.showing + div {
  margin-bottom: 40px;
}
.task-details-alternate h6:hover {
  color: #259af4;
}
.task-details-alternate #timer {
  background: transparent;
  border: none;
  padding: 0;
  width: 100%;
}
.task-details-alternate #enter-time {
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
  color: #777;
}
.task-details-alternate #enter-time:hover {
  color: #000;
}
#timer {
  background: #FBFBFD;
  border-top: 1px solid #E6E6EB;
  border-bottom: 1px solid #E6E6EB;
  padding: 10px 20px;
}
#timer:before,
#timer:after {
  content: " ";
  display: table;
}
#timer:after {
  clear: both;
}
.timer-buttons {
  float: left;
}
#elapsed-time {
  float: right;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: #888;
  display: none;
}
#inactive-timer {
  float: right;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  color: #888;
  line-height: 30px;
}
#stop-timer {
  display: none;
}
#task-attachments-tab .add-file-button {
  position: absolute;
  right: 10px;
}
#task-attachments-tab .attachments-list {
  float: left;
  clear: both;
}
#task-time-entries .header {
  font-weight: bold;
  border-bottom: 1px solid #eee;
  color: #555;
  display: none;
}
#task-time-entries .header:hover {
  background: #fff;
}
#task-time-entries .time-entry-details {
  margin-right: 21px;
}
#task-time-entries .time-entry-details > div {
  float: left;
  width: 33%;
  padding: 0 10px;
}
#task-time-entries .time-entry-delete {
  position: absolute;
  right: 5px;
  top: 0;
  margin-top: 6px;
  display: none;
}
#task-time-entries li {
  list-style: none;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  position: relative;
  margin-left: -10px;
}
#task-time-entries li:hover {
  background-color: #fafafa;
}
#task-time-entries li:hover .time-entry-delete {
  display: block;
}
#task-time-entries li.new {
  background-color: #feffeb;
}
.client-task-list .list li {
  padding: 0 0 0 10px;
}
.client-task-list .list li.list-header {
  padding: 0;
}
.client-task-list .list .is-task-completed {
  left: 0 !important;
}
.client-task-list .list .task-details {
  padding-left: 30px;
  padding-right: 280px;
}
.client-task-list .list .is-task-completed {
  display: none;
}
.clients-complete-tasks .mine .is-task-completed {
  display: block;
}
.client-task-details .task-contents {
  padding-left: 0;
}
#time-entry-form .fields {
  width: 206px;
  margin: 0 auto;
}
#time-entry-form .field {
  width: 60px;
  margin-right: 10px;
  float: left;
}
#time-entry-form .field:last-child {
  margin-right: 0;
}
#task-weight-form {
  width: 378px;
}
#task-weight-form p {
  margin-bottom: 30px;
}
.client-actions {
  list-style: none;
  margin: 10px 0 0 0;
}
.client-actions li {
  margin-top: 5px;
}
.client-details {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  padding-left: 100px !important;
}
.client-details:before,
.client-details:after {
  content: " ";
  display: table;
}
.client-details:after {
  clear: both;
}
.client-details .actions {
  text-align: right;
  position: absolute;
  right: 40px;
  bottom: 40px;
}
.client-details .client-avatar {
  position: absolute;
  top: 20px;
  left: 20px;
}
.client-details .top {
  border: 1px solid #cfcfcf;
  background: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 40px 40px 40px 140px;
  position: relative;
  margin-bottom: 40px;
}
.client-details .top h3 {
  margin-top: 0;
  line-height: 1;
}
.client-details .bottom h3 {
  margin-bottom: 0;
}
.client-details .title-area {
  font-size: 0;
}
.client-details .title-area .left-side,
.client-details .title-area .right-side {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.client-details .title-area .right-side {
  text-align: right;
}
.client-details #client-invoices {
  margin-top: 20px;
}
.client-details .list-main-header {
  background: none !important;
  text-align: left;
  padding: 0 !important;
  display: none !important;
}
.client-widgets-outer {
  /*text-align:center;*/
  padding: 0 20px;
}
.client-widgets-outer:before,
.client-widgets-outer:after {
  content: " ";
  display: table;
}
.client-widgets-outer:after {
  clear: both;
}
.client-widgets-inner {
  display: inline-block;
}
.client-widget.widget .widget-title {
  background: #f8f8f8;
  border: 1px solid #ccc;
  padding: 10px 20px;
  color: #999;
  height: auto;
  margin: 0;
  position: relative;
  top: 1px;
  text-align: left;
}
.client-widget.widget .widget-inner {
  border-radius: 0;
  box-shadow: none;
}
.user-details {
  width: 440px;
  margin: 0 auto;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px !important;
}
.user-details .icon {
  margin-right: 10px;
}
.user-details .user-image {
  width: 100%;
  height: 300px;
  background: #fff;
  border-radius: 3px;
}
.user-details .user-image-inner {
  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 12;
  /* background: #fff url(../images/unknown-user-big.jpg) center center no-repeat; */
}
.user-details .user-image-inner img {
  width: 100%;
}
.user-details .user-contact-info {
  float: left;
  width: 400px;
  padding-right: 120px;
  position: relative;
}
.user-details .user-actions,
.user-details .admin-user-actions {
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 0;
}
.user-details div.section {
  float: left;
  clear: both;
}
/** Lists **/
.list {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.list ul {
  margin: 0;
}
.list li {
  margin-bottom: 1px;
  list-style: none;
  cursor: pointer;
  position: relative;
  display: flex;
}
.list li:first-child {
  border-top: none;
}
.list .list-main-header {
  padding: 1.3rem;
  font-weight: 500 !important;
  color: #666;
  width: 100%;
}
.detailed-list.fancy li.selected,
.detailed-list.fancy li.selected:hover {
  color: #fff;
  text-shadow: -1px 0px #1f6db6;
  border-top: 1px solid #1F6DB6;
  border-bottom: 1px solid #1F6DB6;
  background: #5AAAF5;
  background: -moz-linear-gradient(top, #5AAAF5 0%, #328BDE 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5AAAF5), color-stop(100%, #328BDE));
  background: -webkit-linear-gradient(top, #5AAAF5 0%, #328BDE 100%);
  background: -o-linear-gradient(top, #5AAAF5 0%, #328BDE 100%);
  background: -ms-linear-gradient(top, #5AAAF5 0%, #328BDE 100%);
  background: linear-gradient(top, #5AAAF5 0%, #328BDE 100%);
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5aaaf5', endColorstr='#328bde',GradientType=0 );*/
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.2), 0 1px 0 rgba(178, 178, 178, 0.6);
  border: 1px solid #1F6DB6;
  height: 60px;
}
.detailed-list.fancy li.selected:first-child,
.detailed-list.fancy li.selected:hover:first-child {
  border-top: none;
}
.detailed-list li.selected,
.detailed-list li.selected:hover {
  color: #333;
  background: #f2f8fd;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
  border-top: 1px solid #e6f0f9;
  border-bottom: 1px solid #e6f0f9;
  height: 60px;
}
.detailed-list li.selected:first-child,
.detailed-list li.selected:hover:first-child {
  border-top: none;
}
.detailed-list li.selected + li {
  border-top-color: #fff;
}
.details-list li.selected:first-child {
  border-top-color: #fff;
}
.detailed-list.no-status .status {
  background-color: #e0e0e0;
}
.list.condensed li,
.list-main-header {
  display: flex;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 255, 0.05);
  padding: 1.5rem;
}
.list.condensed li .client-avatar-wrapper,
.list-main-header .client-avatar-wrapper {
  width: 4rem;
  flex: 0 0 4rem;
}
.list.condensed li > div,
.list-main-header > div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.list.condensed li > div:not(.client-avatar-wrapper),
.list-main-header > div:not(.client-avatar-wrapper) {
  padding: 0 0.5rem;
  width: 25%;
  flex: 0 1 25%;
}
.list-main-header .invoice-total {
  font-weight: 500 !important;
}
.list.condensed li:hover,
.list.condensed li.selected,
.list.condensed li.pseudo-hover {
  background-color: #fff;
  color: #000;
}
.list.condensed li.selected {
  background-color: #F2F8FD;
  border-top-color: #D0E9F7;
}
/** Detailed List **/
.detailed-list li {
  height: 60px;
  padding: 10px 20px;
  overflow: hidden;
}
.detailed-list .top-details {
  font-weight: bold;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /*font-size:20px;*/
}
.detailed-list .top-details:before,
.detailed-list .top-details:after {
  content: " ";
  display: table;
}
.detailed-list .top-details:after {
  clear: both;
}
.detailed-list .bottom-details {
  height: 10px;
  line-height: 10px;
  color: #aaa;
  text-transform: uppercase;
  font-size: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.fancy li.selected .bottom-details {
  color: #eee;
}
li.selected .bottom-details {
  color: #333;
}
.detailed-list .left {
  width: 60%;
  margin-right: 10%;
}
.detailed-list .right {
  width: 30%;
  text-align: right;
}
.detailed-list .detail {
  float: left;
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.detailed-list .status {
  width: 5px;
  height: 100%;
  background: #4da6ff;
  position: absolute;
  left: 0;
  top: 0;
}
.draft .status.status {
  background: #b4b4b4;
}
.overdue .status,
.behind-schedule .status {
  background: #E96267;
}
.at-risk .status {
  background: #fff3a3;
}
.detailed-list li:hover {
  background: rgba(255, 255, 0, 0.05);
}
.detailed-list li:hover .progress {
  opacity: 1;
}
.detailed-list li:hover .progress span {
  opacity: 1;
}
/** Task List **/
.completed-task {
  text-decoration: line-through;
  background-color: transparent;
  color: #aaa;
}
.task-list li {
  border-top-color: #eee;
  height: 34px;
  line-height: 34px;
  padding: 0 297px 0 46px;
}
.task-list input {
  height: 100%;
  width: 100%;
  border: none;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: none;
}
.task-list input:focus,
.task-list input:active {
  outline: none;
}
.task-list input::-ms-clear {
  display: none;
}
.task-list li.selected input,
.task-list li:hover input,
.task-list li.pseudo-hover input {
  background-color: transparent;
}
.task-list li div {
  float: left;
  margin: 0 4px;
  cursor: pointer;
}
.task-list .sort-handle {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  cursor: move;
  /* background: transparent url(../images/tasks/sort-handle.png) no-repeat 0 center; */
  opacity: 0;
  transition: all 0.2s linear;
}
.task-list li:hover .sort-handle {
  opacity: 1;
}
.task-list .is-task-completed,
.task-list .retry-save,
.task-list .saving-indicator {
  position: absolute;
}
.task-list .is-task-completed {
  left: 0;
  height: 16px;
  width: 16px;
  background: none;
  border: 1px solid #888;
  border-radius: 3px;
}
.task-list .saving-indicator {
  left: 0px;
  height: 16px !important;
  width: 16px;
  /* background: transparent url(../images/tasks/task-saving.gif) no-repeat 0 0; */
  display: none;
}
.task-list .retry-save {
  height: 14px !important;
  width: 14px;
  margin: 10px 0 0 10px;
  left: 14px;
  /* background-image: url(../images/glyphicons-halflings.png); */
  background-repeat: no-repeat;
  background-position: 0 -120px;
  cursor: pointer;
  opacity: 0.5;
  display: none;
}
.task-list .saving .is-task-completed {
  display: none;
}
.task-list .saving .saving-indicator {
  display: block;
}
.task-list .saving .retry-save {
  display: none;
}
.task-list .error-saving .is-task-completed {
  display: none;
}
.task-list .error-saving .saving-indicator {
  display: none;
}
.task-list .error-saving .retry-save {
  display: block;
}
.task-list .list-header {
  padding-left: 15px;
  border-top: none;
}
.task-list .list-header .sort-handle {
  display: none;
}
.task-list .list-header .is-task-completed,
.task-list .list-header .view-task {
  display: none;
}
.task-list .list-header .task-details,
.task-list .list-header input {
  font-weight: bold;
}
.task-list .list-header + li {
  border-top: none !important;
}
.task-list .list-header.selected,
.task-list .list-header:hover {
  background-color: transparent !important;
}
.task-list .list-header.selected input,
.task-list .list-header:hover input {
  background-color: transparent !important;
}
.task-list .task-details {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.task-list .task-due-date {
  position: absolute;
  right: 0;
  width: 98px;
  text-align: right;
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.task-list .task-list-icon {
  font-size: 17px;
}
.task-list .task-actions {
  position: absolute;
  right: 0;
  width: 128px;
  text-align: right;
  margin-right: 10px;
  display: none;
  padding: 0 !important;
}
.task-list .task-actions div {
  float: right;
  cursor: pointer;
  font-size: 17px;
  margin: 17px 15px 0 0;
}
.task-list .task-actions div:first-child {
  margin-right: 0;
}
.task-list .task-actions div:hover {
  color: #2381e9;
}
.task-list .task-contents .task-actions div {
  font-size: 14px;
  margin-top: 0;
}
.task-list .task-meta {
  position: absolute;
  right: 130px;
  top: 0;
  width: 25px;
}
.task-list .task-meta .has-notes {
  float: right;
  color: #aaa;
  top: 17px;
  position: relative;
}
.task-list .task-meta .has-notes:hover {
  color: #aaa !important;
}
.task-list .task-assigned {
  width: 150px;
  position: absolute;
  right: 150px;
  top: 0;
  cursor: default;
}
.task-list .task-assigned .user-image {
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 0;
  position: absolute;
  top: 4px;
  left: 5px;
}
.task-list .task-assigned .user-image img {
  vertical-align: top;
}
.task-list .task-assigned .tag {
  position: relative;
  color: #888;
  border-radius: 4px;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  padding-left: 35px;
}
.task-list .task-assigned .tag:hover {
  background-color: #ebf2fb;
}
.task-list li:hover .task-completed {
  opacity: 0.6;
}
.task-list li:hover .task-due-date {
  display: none;
}
.task-list li:hover .task-actions {
  display: block;
}
.task-list li:hover .task-meta {
  display: block;
}
.task-list .completed {
  text-decoration: line-through;
  background-color: transparent;
  color: #aaa;
}
.status-indicators {
  top: 50%;
  margin-top: -8px !important;
  position: absolute;
  left: 20px;
  height: 16px !important;
}
.list-header .status-indicators {
  left: 0;
  cursor: default;
}
.list-header .status-indicators .is-task-completed {
  display: none;
}
.task-status-indicators .is-task-completed,
.task-status-indicators .retry-save,
.task-status-indicators .saving-indicator {
  position: absolute;
}
.task-status-indicators .is-task-completed {
  left: 0;
  height: 16px;
  width: 16px;
  background: none;
  border: 1px solid #888;
  border-radius: 3px;
}
.task-status-indicators .saving-indicator {
  left: 0px;
  height: 16px !important;
  width: 16px;
  /* background: transparent url(../images/tasks/task-saving.gif) no-repeat 0 0; */
  display: none;
}
.task-status-indicators .retry-save {
  height: 14px !important;
  width: 14px;
  margin: 10px 0 0 10px;
  left: 14px;
  /* background-image: url(../images/glyphicons-halflings.png); */
  background-repeat: no-repeat;
  background-position: 0 -120px;
  cursor: pointer;
  opacity: 0.5;
  display: none;
}
.task-status-indicators .saving .is-task-completed {
  display: none;
}
.task-status-indicators .saving .saving-indicator {
  display: block;
}
.task-status-indicators .saving .retry-save {
  display: none;
}
.task-status-indicators .error-saving .is-task-completed {
  display: none;
}
.task-status-indicators .error-saving .saving-indicator {
  display: none;
}
.task-status-indicators .error-saving .retry-save {
  display: block;
}
#tasks-list {
  margin-bottom: 0 !important;
}
.completed-task-checkbox {
  /* background-image: url(../images/tasks/task-complete.gif) !important; */
  background-position: center center;
  background-size: 18px !important;
  border: none !important;
}
#completed-tasks-list li {
  background-color: #fff;
}
#completed-tasks-list [disabled=disabled] {
  background: transparent;
}
#completed-tasks-list .task-details {
  text-decoration: line-through;
  background-color: transparent;
  color: #aaa;
}
#completed-tasks-list .sort-handle {
  display: none;
}
#completed-tasks-list .is-task-completed {
  /* background-image: url(../images/tasks/task-complete.gif) !important; */
  background-position: center center;
  background-size: 18px !important;
  border: none !important;
}
#completed-tasks-list .task-due-date {
  display: none;
}
#completed-tasks-list .flag-task {
  display: none;
}
.filter-dropdown {
  display: inline-block;
  position: relative;
  z-index: 99;
  background: #f5f5f5;
  border-radius: 4px;
}
.filter-dropdown:focus {
  outline: none;
}
.filter-dropdown .filter-button {
  line-height: 30px;
  height: 30px;
  padding: 0 19px 0 10px;
  border-radius: 2px;
  z-index: 1;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filter-dropdown .filter-button:hover {
  color: #2381e9;
}
.filter-dropdown .filter-button span {
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.filter-dropdown .dropdown-menu {
  z-index: -1;
  margin-top: -1px;
  background: #fff;
}
.filter-dropdown .caret {
  position: absolute;
  top: 15px;
  right: 6px;
  opacity: 0.6;
}
.filter-dropdown.open .dropdown-menu {
  border-radius: 2px;
}
.filter-dropdown li {
  position: relative;
}
.filter-dropdown .selected:after {
  content: ' ';
  height: 6px;
  width: 6px;
  position: absolute;
  left: 5px;
  top: 10px;
  background: #4da6ff;
  border-radius: 50%;
}
.filter-dropdown input {
  height: 25px;
  margin: 0 10px 5px 10px;
  width: auto;
  border-radius: 0;
  border: 1px solid #ddd;
  width: 138px;
  padding: 0 10px;
}
.filter-dropdown .divider {
  background-color: #eee !important;
}
.search-results-message,
.task-list-message {
  z-index: 99;
  padding: 5px 20px;
  margin-bottom: 10px;
  color: #666;
  font-style: italic;
  background: #fafafa;
  border: 1px solid #eee;
}
.search-results-message .close,
.task-list-message .close {
  display: block;
  position: absolute;
  top: 6px;
  right: 15px;
  cursor: pointer;
}
.search-results-message {
  background: #FFF9CE;
  border: 1px solid #F9E652;
}
.at-risk .task-due-date {
  color: #F7941D;
  font-weight: bold;
}
.overdue .task-due-date {
  color: #E96267;
  font-weight: bold;
}
.invoice-list .invoice-number,
.invoice-list .invoice-total,
.invoice-list .invoice-balance,
.invoice-list .invoice-due-date,
.invoice-list .invoice-status {
  width: 25%;
  font-size: 16px;
}
.invoice-list .invoice-due-date {
  text-align: right;
}
.invoice-list .invoice-total {
  font-weight: bold;
}
.estimate-list li > div,
.estimate-list .list-main-header > div {
  width: 33%;
}
#file-view-type li,
#dashboard-project-view-type li {
  width: 36px;
}
#file-view-type li span,
#dashboard-project-view-type li span {
  display: inline-block;
  vertical-align: text-top;
  font-size: 17px;
  color: #444;
}
/** File List **/
.file-list .file-title,
.file-list .file-type,
.file-list .file-uploader-name,
.file-list .file-create-date,
.file-list .file-extension {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.file-list .file-extension {
  width: 10%;
}
.file-list .file-title {
  width: 55%;
}
.file-list .file-type {
  width: 10%;
}
.file-list .file-uploader-name {
  width: 20%;
}
.file-list .file-create-date {
  width: 5%;
}
.file-list li {
  background-position: 10px center;
  background-repeat: no-repeat;
}
.file-list .filename {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.file-list .upload-date {
  position: absolute;
  right: 160px;
  width: 160px;
}
.file-list .uploaded-by {
  position: absolute;
  right: 0;
  width: 160px;
}
.file-list span {
  font-size: 12px;
  color: #fff;
  margin-right: 4px;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 2px 10px;
  background: #b2b2b2;
}
.file-list .zip span {
  background-color: #fdbb70;
}
.file-list .pdf span {
  background-color: #d04f3a;
}
.file-list .text span {
  background-color: #45bf7b;
}
.file-list .code span {
  background-color: #3a88d0;
}
.file-list .video span {
  background-color: #ce87eb;
}
.file-list .audio span {
  background-color: #3ab2d0;
}
.file-list .image span {
  background-color: #fd7037;
}
.file-list-view-wrapper {
  position: relative;
}
.files-preview-list {
  margin: 0;
}
.files-preview-list .file-list-preview {
  position: absolute;
  width: 300px;
  list-style: none;
  margin: 0 20px 10px 0;
  float: left;
  border: 1px solid #cfcfcf;
  -webkit-background-clip: padding-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  transition: border linear 0.2s;
  background: #fff;
}
.files-preview-list .file-list-preview:after,
.files-preview-list .file-list-preview:before {
  background-color: #ffffff;
  left: 0;
  top: 0;
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(34, 34, 34, 0.16);
}
.files-preview-list .file-list-preview:before {
  transform: rotate(-1.3deg);
  top: 0;
  z-index: -2;
}
.files-preview-list .file-list-preview:after {
  transform: rotate(1.3deg);
  top: 0;
  z-index: -1;
}
.files-preview-list .file-list-preview:hover {
  border: 1px solid #aaa;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.files-preview-list .file-name {
  font-size: 85%;
  font-weight: bold;
  color: #626368;
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.files-preview-list .file-options {
  position: absolute;
  top: 15px;
  right: 15px;
  border: 1px solid #bbb;
  height: 14px;
  width: 14px;
  padding: 4px 2px 0 2px;
  cursor: pointer;
  overflow: visible;
  border-radius: 3px;
  transition: all linear 0.2s;
}
.files-preview-list .file-options .caret {
  opacity: 0.5;
  transition: all linear 0.2s;
}
.files-preview-list .file-options:hover {
  border: 1px solid #666;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.files-preview-list .file-options:hover .caret {
  opacity: 1;
}
.files-preview-list .file-options .dropdown-menu {
  left: -73px;
  top: 16px;
}
.files-preview-list .file-meta-wrapper {
  border-top: 1px solid #eee;
  background: #fefefe;
  padding: 10px 20px;
  position: relative;
}
.files-preview-list .file-meta-wrapper .file-meta {
  font-size: 85%;
  color: #999;
}
.files-preview-list .file-meta-wrapper .file-meta span {
  color: #ccc;
  font-weight: bold;
}
.files-preview-list .animated {
  transition: all 0.5s ease-in-out;
}
.files-preview-list .file-dv {
  width: 260px;
  margin: 20px auto 20px auto;
  position: relative;
  /* background-image: url(../images/files/unknown-bg.png); */
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 90px;
  cursor: pointer;
}
.files-preview-list .file-dv:before,
.files-preview-list .file-dv:after {
  content: " ";
  display: table;
}
.files-preview-list .file-dv:after {
  clear: both;
}
.files-preview-list .file-dv.audio {
  /* background-image: url(../images/files/audio-bg.png); */
}
.files-preview-list .file-dv.image {
  /* background-image: url(../images/files/image-bg.png); */
}
.files-preview-list .file-dv.video {
  /* background-image: url(../images/files/video-bg.png); */
}
.files-preview-list .file-dv.pdf,
.files-preview-list .file-dv.code,
.files-preview-list .file-dv.txt,
.files-preview-list .file-dv.document {
  /* background-image: url(../images/files/document-bg.png); */
}
.files-preview-list .file-dv.loaded {
  background-image: none !important;
  min-height: 0;
}
.files-preview-list .document-viewer-wrapper {
  margin: 0;
  float: none;
  width: inherit;
  overflow: hidden;
  -webkit-background-clip: padding-box;
  opacity: 0;
}
.files-preview-list .loaded .document-viewer-wrapper {
  opacity: 1;
}
.files-preview-list .error .document-viewer-wrapper {
  opacity: 1;
}
.files-preview-list .error .document-viewer-wrapper .dv-markup {
  background: transparent;
}
.files-preview-list .error-loading,
.files-preview-list .loading-message,
.files-preview-list .unsupported-type {
  width: 100%;
  position: absolute;
  bottom: -16px;
  margin-top: -4px;
  text-align: center;
  font-size: 10px;
}
.files-preview-list .error-loading,
.files-preview-list .unsupported-type {
  color: #aaa;
}
.files-preview-list .loading-message {
  color: #006cff;
}
.files-preview-list .document-viewer-outer {
  border: none;
  box-shadow: none;
}
.files-preview-list .document-viewer {
  padding: 0 !important;
}
.files-preview-list .scrollable {
  width: 100%;
}
.files-preview-list .viewport {
  overflow: hidden;
}
.files-preview-list .pdf-menu {
  display: none;
}
.files-preview-list .audio .ttw-video-player {
  padding-top: 2px;
}
.files-preview-list .audio .dv-error {
  margin: 0 auto;
  padding: 0;
  font-size: 90%;
}
.files-preview-list .audio .ttw-video-player {
  height: 100%;
}
.files-preview-list .ttw-video-player {
  padding: 0;
}
.files-preview-list .ttw-video-player .player {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 0 10px;
  width: 554px;
  height: 33px;
}
.files-preview-list .progress-wrapper {
  margin-right: 0 !important;
}
.files-preview-list .volume-wrapper {
  display: none;
}
.files-preview-list .file-list-filename {
  text-align: center;
}
.files-preview-list .prettyprint {
  padding: 0 !important;
  margin: 0 !important;
}
.files-preview-list .dv-image {
  padding: 0;
  display: block;
}
.files-preview-list ol {
  list-style: none;
  margin: 0;
  font-size: 95%;
}
.files-preview-list ol li {
  line-height: 1.2 !important;
}
#file-list-simple-tiles {
  margin: 0;
  position: relative;
  top: 1px;
  left: 1px;
}
#file-list-simple-tiles .file-simple-tile {
  width: 170px;
  position: relative;
  list-style: none;
  margin: 0 20px 10px 0;
  float: left;
  text-align: center;
  cursor: pointer;
}
#file-list-simple-tiles .file-simple-tile .file-icon {
  width: 170px;
  height: 120px;
  -webkit-background-clip: padding-box;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.9;
  /* background-image: url(../images/files/unknown-bg2.jpg); */
}
#file-list-simple-tiles .file-simple-tile.audio .file-icon {
  /* background-image: url(../images/files/audio-bg2.jpg); */
}
#file-list-simple-tiles .file-simple-tile.image .file-icon {
  /* background-image: url(../images/files/image-bg2.jpg); */
}
#file-list-simple-tiles .file-simple-tile.video .file-icon {
  /* background-image: url(../images/files/video-bg2.jpg); */
}
#file-list-simple-tiles .file-simple-tile.pdf .file-icon {
  /* background-image: url(../images/files/pdf-bg2.jpg); */
}
#file-list-simple-tiles .file-simple-tile.code .file-icon,
#file-list-simple-tiles .file-simple-tile.txt .file-icon,
#file-list-simple-tiles .file-simple-tile.document .file-icon {
  /* background-image: url(../images/files/document-bg2.jpg); */
}
#file-list-simple-tiles .file-simple-tile .file-name {
  width: 100%;
  font-size: 14px;
  color: #444;
  height: 50px;
  background: #fafafa;
  border-radius: 8px;
  text-align: center;
  padding: 5px 20px;
  overflow: hidden;
}
#file-list-simple-tiles .file-simple-tile:hover {
  outline: 1px solid #2381e9;
}
#file-list-simple-tiles .file-simple-tile:hover .file-icon {
  opacity: 1;
}
#file-list-simple-tiles .file-simple-tile:hover .file-name {
  background: #ebf2fb;
  color: #2381e9;
  border-radius: 0;
}
#file-list-simple-tiles .file-meta-wrapper {
  border-top: 1px solid #eee;
  background: #fefefe;
  padding: 10px 20px;
  position: relative;
}
#file-list-simple-tiles .file-meta-wrapper .file-meta {
  font-size: 85%;
  color: #999;
}
#file-list-simple-tiles .file-meta-wrapper .file-meta span {
  color: #ccc;
  font-weight: bold;
}
#file-list-simple-tiles ol {
  list-style: none;
  margin: 0;
  font-size: 95%;
}
#file-list-simple-tiles ol li {
  line-height: 1.2 !important;
}
.file-view:before,
.file-view:after {
  content: " ";
  display: table;
}
.file-view:after {
  clear: both;
}
.file-view .file-view-inner {
  max-width: 900px;
  margin: 0 auto;
}
.file-view .document-viewer-outer:after,
.file-view .document-viewer-outer:before {
  background-color: #ffffff;
  left: 0;
  top: 0;
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(34, 34, 34, 0.16);
}
.file-view .document-viewer-outer:before {
  transform: rotate(-1.3deg);
  top: 0;
  z-index: -2;
}
.file-view .document-viewer-outer:after {
  transform: rotate(1.3deg);
  top: 0;
  z-index: -1;
}
.file-view .dv-image {
  width: 100%;
}
.file-view .file-dv,
.file-view .file-meta-wrapper {
  float: left;
}
.file-view .file-dv {
  width: 640px;
}
.file-view .file-meta-wrapper {
  width: 250px;
  padding: 20px;
}
.file-view .document-viewer-wrapper {
  margin: 0 auto;
  float: none;
  display: block;
}
.file-view .document-viewer-wrapper .button {
  box-shadow: none;
  border: none;
}
.file-view .document-viewer-wrapper .progress {
  background: none;
}
.file-view .document-viewer-wrapper .progress-bg,
.file-view .document-viewer-wrapper .volume {
  height: 7px;
}
.file-view .file-name {
  font-weight: bold;
  color: #888;
  font-size: 18px;
  margin-bottom: 20px;
}
.file-view .file-notes {
  margin-bottom: 20px;
}
.file-view .file-meta {
  color: #888;
  margin: 4px 0;
  font-size: 90%;
}
.file-view .file-meta span {
  color: #333;
  font-weight: bold;
}
.file-view .error-loading,
.file-view .loading-message {
  width: 100%;
  text-align: center;
  padding: 40px 0;
}
.file-view .error-loading {
  color: #ff0000;
}
.file-view .loading-message {
  color: #006cff;
}
.file-actions {
  width: 100%;
}
.file-actions .smart-menu-items li {
  padding: 0 2px !important;
}
.file-actions .more .sidebar-icon {
  width: 14px !important;
  margin-right: 2px;
}
.file-actions .more {
  top: 4px;
  position: relative !important;
  padding: 0 5px !important;
}
hr {
  width: 100%;
}
.file-actions {
  width: 100%;
  float: left;
}
.file-actions .button {
  float: left;
}
.file-actions .right {
  float: left;
  clear: both;
}
.file-actions .button {
  margin: 5px 3px 5px 0 !important;
}
#invoice-details,
#estimate-details {
  position: relative;
}
#invoice-details #invoice-details-inner-wrapper,
#estimate-details #invoice-details-inner-wrapper {
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
}
#invoice-details #invoice-details-inner-wrapper:before,
#estimate-details #invoice-details-inner-wrapper:before,
#invoice-details #invoice-details-inner-wrapper:after,
#estimate-details #invoice-details-inner-wrapper:after {
  content: " ";
  display: table;
}
#invoice-details #invoice-details-inner-wrapper:after,
#estimate-details #invoice-details-inner-wrapper:after {
  clear: both;
}
#invoice-details .invoice-wrapper,
#estimate-details .invoice-wrapper {
  max-width: 1000px;
}
#invoice-details .invoice-meta-wrapper,
#estimate-details .invoice-meta-wrapper {
  width: 250px;
  float: left;
  padding: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
#invoice-details .invoice-meta-wrapper > div,
#estimate-details .invoice-meta-wrapper > div {
  margin-bottom: 5px;
}
#invoice-details .invoice-meta-wrapper span:first-child,
#estimate-details .invoice-meta-wrapper span:first-child {
  width: 100px;
  display: inline-block;
  color: #666;
}
/** Messages List **/
#dashboard-filter-wrapper,
#dashboard-sort-wrapper {
  display: inline-block;
  position: relative;
  top: 3px;
  z-index: 99;
}
.paper-background:after,
.paper-background:before {
  background-color: #ffffff;
  left: 0;
  top: 0;
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(34, 34, 34, 0.16);
}
.paper-background:before {
  transform: rotate(-1.3deg);
  top: 0;
  z-index: -2;
}
.paper-background:after {
  transform: rotate(1.3deg);
  top: 0;
  z-index: -1;
}
/** Document **/
.document,
.invoice {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #fff;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.07), 1px 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.document:before,
.invoice:before,
.document:after,
.invoice:after {
  content: " ";
  display: table;
}
.document:after,
.invoice:after {
  clear: both;
}
/** Invoice **/
.invoice {
  padding: 40px;
  /* max-width: 700px; */
  /* max-width: 556px; */
  position: relative;
  margin-right: auto;
  margin-left: auto;
}
.invoice .invoice-details {
  margin-top: 40px;
  margin-bottom: 40px;
}
.invoice .invoice-details:before,
.invoice .invoice-details:after {
  content: " ";
  display: table;
}
.invoice .invoice-details:after {
  clear: both;
}
.invoice .invoice-logo {
  max-width: 40%;
  position: relative;
}
.invoice .invoice-logo .logo-button-space {
  display: inline;
}
.invoice .invoice-logo .add-file-button {
  padding: 0;
  font-size: 14px;
  float: none;
  color: #888;
  text-decoration: underline;
}
.invoice .invoice-logo .add-file-button:hover {
  box-shadow: none;
  border: none;
}
.invoice .invoice-logo img {
  max-width: 100%;
}
.invoice .invoice-logo .logo-instructions {
  z-index: 2;
  position: relative;
  color: #888;
  line-height: 1;
}
.invoice .invoice-logo.no-image {
  width: 46% !important;
  max-width: 46%;
  margin-bottom: 20px;
  padding: 10px 20px 5px 45px;
  /* background: transparent url(../images/upload.svg) no-repeat 0px center; */
  background-size: 30px;
  line-height: 1;
}
.invoice .invoice-logo.has-image .logo-instructions {
  display: none;
}
.invoice .invoice-logo.has-image .editable-area-marker {
  display: none;
}
.invoice .invoice-logo.has-image:hover .remove-logo {
  visibility: visible;
  opacity: 1;
}
.invoice .remove-logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  outline: 2px solid #46baf9;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.invoice .remove-logo .remove {
  position: absolute;
  top: -15px;
  right: -15px;
  height: 30px;
  width: 30px;
  background: #46baf9;
  color: #fff;
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.invoice .details-description {
  margin: 0px 0;
  color: #aaa;
}
.invoice .details-description.has-data {
  margin: 40px 0;
}
.invoice .corner {
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  width: 0;
  height: 0;
  border-top: 90px solid rgba(21, 114, 238, 0.5);
  border-left: 90px solid transparent;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
.invoice .corner .text {
  transition: all 0.2s;
  text-align: center;
  width: 90px;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  color: #b3b3b3;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  font-size: 12px;
  font-size: 0.7em;
  color: white;
  -webkit-transform: translate(-76px, -64px) rotate(45deg);
  -ms-transform: translate(-76px, -64px) rotate(45deg);
  transform: translate(-76px, -64px) rotate(45deg);
}
.invoice .corner.overdue {
  border-top-color: rgba(253, 110, 95, 0.81);
}
.invoice .corner.draft {
  border-top-color: rgba(138, 138, 138, 0.64);
}
.invoice .corner.paid {
  border-top-color: rgba(110, 203, 221, 0.79);
}
.invoice ul {
  margin: 0;
  list-style: none;
}
.invoice .line-items {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.invoice .invoice-items-header,
.invoice .summary-header {
  width: 100%;
  font-weight: bold;
  font-size: 0;
}
.invoice .invoice-items-header > div,
.invoice .summary-header > div {
  font-size: 14px;
  padding: 8px 10px;
  display: inline-block;
  background: #f5f5f5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.invoice .line-item,
.invoice .invoice-row {
  width: 100%;
  position: relative;
  border-top: 1px solid #DDD;
  font-size: 0;
}
.invoice .line-item:nth-child(odd) {
  background-color: #fbfbfb;
}
.invoice .invoice-details {
  font-size: 0;
}
.invoice .invoice-due-date {
  width: 46%;
  float: right;
}
.invoice .invoice-meta {
  width: 46%;
}
.invoice .invoice-description {
  width: 40%;
  line-height: 1.2;
  padding: 0 4px 14px 4px;
  font-size: 14px;
  color: #bbb;
  position: relative;
  margin-top: -12px;
}
.invoice .invoice-description textarea {
  position: relative;
  top: 6px;
}
.invoice .invoice-client-details,
.invoice .invoice-meta,
.invoice .invoice-due-date {
  display: inline-block;
  font-size: 14px;
  vertical-align: top;
  margin-right: 2%;
  position: relative;
}
.invoice .invoice-client-details > div,
.invoice .invoice-meta > div,
.invoice .invoice-due-date > div {
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.invoice .sender-name,
.invoice .invoice-client-name {
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
}
.invoice .sender-address,
.invoice .client-address {
  margin-bottom: 10px;
}
.invoice .invoice-company-details {
  display:flex;
  justify-content:space-between;
  align-items: center;
  margin-bottom: 70px;
}
/* .invoice .invoice-company-details:before,
.invoice .invoice-company-details:after {
  content: " ";
  display: table;
}
.invoice .invoice-company-details:after {
  clear: both;
} */
.invoice .invoice-logo {
  margin-bottom: 10px;
}
.invoice .invoice-date {
  margin: 4px 0;
}
.invoice .date-outer {
  background-color: #fff;
  padding: 4px 10px;
  border-radius: 3px;
}
.invoice .date-outer input {
  padding: 0 0 0 10px !important;
}
.invoice .date-outer.disabled {
  background: transparent;
  border: none;
}
.invoice .date-outer.disabled:hover {
  box-shadow: none;
}
.invoice .invoice-meta {
  margin-bottom: 0px;
}
.invoice .invoice-meta > div > span:first-child {
  vertical-align: top;
  display: inline-block;
  margin-right: 3%;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.invoice .invoice-meta > div > span:first-child:after {
  content: ':';
  margin-left: 2px;
  display: inline-block;
}
.invoice .invoice-due-date {
  margin-right: 0;
}
.invoice .invoice-due-date div {
  padding: 10px;
  display: inline-block;
}
.invoice .invoice-due-date .due-date {
  background-color: #fff;
  border-radius: 3px;
}
.invoice .invoice-due-date .due-date.disabled {
  background: transparent;
  border: none;
}
.invoice .invoice-due-date .due-date.disabled:hover {
  box-shadow: none !important;
}
.invoice .due-text {
  padding: 10px 0 !important;
  font-weight: bold;
}
.invoice .disabled .transparent.date {
  display: none;
}
.invoice .line-items {
  width: 100%;
}
.invoice .invoice-item {
  width: 40%;
}
.invoice .invoice-quantity {
  width: 15%;
}
.invoice .invoice-rate {
  width: 13%;
}
.invoice .invoice-tax-rate {
  width: 13%;
}
.invoice .invoice-tax-rate .select2 input {
  display: none;
}
.invoice .invoice-tax-rate .select2-container--default .select2-selection--multiple .select2-selection__choice {
  max-width: 100%;
  border: 0;
  margin-right: 3px;
  background: none;
  border: none;
  padding: 0;
}
.invoice .invoice-subtotal {
  width: 19%;
}
.invoice .invoice-quantity,
.invoice .invoice-rate,
.invoice .invoice-subtotal {
  text-align: center;
}
.invoice .invoice-summary-inner {
  padding-left: 55%;
}
.invoice .invoice-summary-inner.tvsh{
  border-bottom: 0;
}
.invoice .invoice-summary-inner.tvsh > p{
  font-size: 10px;
  color: #969696;
  text-decoration: none;
  border-bottom:0;
}
.invoice .invoice-summary-inner > *:last-child {
  border-bottom: 1px solid #ddd;
}
.invoice .invoice-summary {
  margin-top: 40px;
  font-size: 0;
}
.invoice .invoice-summary:before,
.invoice .invoice-summary:after {
  content: " ";
  display: table;
}
.invoice .invoice-summary:after {
  clear: both;
}
.invoice .invoice-summary > div {
  display: inline-block;
  font-size: 14px;
  width: 100%;
}
.invoice .invoice-summary .invoice-row div {
  width: 50%;
}
.invoice .invoice-summary .invoice-row div:nth-child(2) {
  text-align: right;
}
.invoice .summary-header {
  background: #f5f5f5;
  text-align: center;
  padding: 8px 2px;
  font-size: 14px;
}
.invoice .total-taxes {
  display: none;
}
.invoice .invoice-terms {
  color: #999;
  margin: 40px 0 20px 0;
}
.invoice .invoice-terms:before,
.invoice .invoice-terms:after {
  content: " ";
  display: table;
}
.invoice .invoice-terms:after {
  clear: both;
}
.invoice .line-item-actions {
  display: none !important;
}
.invoice .invoice-type {
  width: 46%;
  float: right;
  position: relative;
}
.line-item__top > div,
.invoice-row > div {
  padding: 14px 4px;
  display: inline-block;
  font-size: 14px;
  min-height: 50px;
  vertical-align: top;
}
.line-item__top > div *,
.invoice-row > div * {
  line-height: 20px;
}
.user-defined-data {
  position: relative;
  overflow: visible !important;
}
.user-defined-data.active {
  padding: 10px 5px;
  border: 2px dotted #c5dbff;
  margin-bottom: 20px;
}
.user-defined-data.active .textbox:focus,
.user-defined-data.active .textbox:active {
  outline: none;
}
.user-defined-data .textbox {
  min-height: 20px;
}
.user-defined-data textarea {
  width: 100% !important;
  height: auto !important;
  border: none !important;
}
.user-defined-data .ghost-button {
  position: absolute;
  top: -10px;
  left: -100px;
  display: block;
  content: '';
  display: none;
}
.user-defined-data .ghost-button .bubble {
  height: 34px;
  width: 34px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: all 0.2s ease-in;
  display: inline-block;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  position: relative;
  font-size: 17px;
  color: #777;
  opacity: 0.7;
}
.user-defined-data .ghost-button .text {
  opacity: 0;
  transition: all 0.2s ease-in;
  display: inline-block;
  margin-right: 10px;
  font-size: 12px;
  color: #777;
  position: absolute;
  top: 10px;
  right: 36px;
  width: 100px;
  text-align: right;
  visibility: hidden;
}
.user-defined-data .ghost-button:hover .bubble {
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  background: #fffdf2;
  color: #000;
  opacity: 1;
}
.user-defined-data .ghost-button:hover .text {
  opacity: 1;
  visibility: visible;
}
.invoice-contact-info .user-defined-data .bubble {
  margin-left: -10px;
}
#invoice-tax-line-items-wrapper .invoice-row:first-child {
  border-top: 1px solid #DDD;
}
.invoice.preview .date-outer,
.invoice.preview .due-date {
  display: inline-block;
  border: none;
}
.invoice.preview .date-outer:hover,
.invoice.preview .due-date:hover {
  box-shadow: none;
}
.invoice.preview .date-outer {
  padding: 0;
  background: none;
}
.invoice.preview .due-date {
  background: none;
}
.estimate {
  padding-bottom: 80px;
}
.estimate .invoice-due-date,
.estimate .invoice-terms {
  display: none;
}
.estimate-wrapper .invoice .corner {
  width: 140px;
  border: 3px solid rgba(138, 138, 138, 0.64);
  height: 52px;
  top: 40px;
  right: 40px;
}
.estimate-wrapper .invoice .corner .estimate-text {
  margin-top: 3px;
  color: rgba(138, 138, 138, 0.64);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 20px;
}
.estimate-wrapper .invoice .corner .text {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  color: rgba(138, 138, 138, 0.64);
  letter-spacing: 0;
  width: 100%;
  font-size: 12px;
  font-size: inherit;
  font-weight: normal;
  text-transform: none;
}
.estimate-wrapper .invoice .corner.rejected {
  border-color: rgba(253, 110, 95, 0.81);
}
.estimate-wrapper .invoice .corner.rejected .text,
.estimate-wrapper .invoice .corner.rejected .estimate-text {
  color: rgba(253, 110, 95, 0.81);
}
.estimate-wrapper .invoice .corner.approved {
  border-color: rgba(110, 203, 221, 0.79);
}
.estimate-wrapper .invoice .corner.approved .text,
.estimate-wrapper .invoice .corner.approved .estimate-text {
  color: rgba(110, 203, 221, 0.79);
}
.preview-notification {
  background: #FFF9CE;
  border: 1px solid #F9E652;
  max-width: 750px;
  padding: 15px 200px 15px 20px;
  margin: 0 auto;
  position: relative;
}
.preview-notification .button {
  position: absolute;
  right: 20px;
  top: 12px;
}
.invoice-status .status-text {
  line-height: 18px;
  width: 70px;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
}
.invoice-status.overdue .status-text {
  color: #FD6E5F;
}
.invoice-status.paid .status-text {
  color: #6ECBDD;
}
.invoice-status.pending .status-text,
.invoice-status.inactive .status-text,
.invoice-status.draft .status-text {
  color: #8a8a8a;
}
.estimate-list .invoice-status .status-text {
  width: 80px;
}
.estimate-list .invoice-status.rejected .status-text {
  border: 2px solid #FD6E5F;
  color: #FD6E5F;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
}
.estimate-list .invoice-status.approved .status-text {
  border: 2px solid #6ECBDD;
  color: #6ECBDD;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
}
.estimate-list .invoice-status.draft .status-text,
.estimate-list .invoice-status.sent .status-text {
  border: 2px solid #8a8a8a;
  color: #8a8a8a;
  background: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
}
.invoice-editor-menu {
  float: right;
}
.invoice-editor-menu #close-import-tasks {
  display: none;
}
.invoice-editor-menu.importing-tasks #close-import-tasks {
  display: inline-block;
}
.invoice-editor-menu.importing-tasks #import-tasks,
.invoice-editor-menu.importing-tasks #save-invoice {
  display: none;
}
.invoice-editor-errors {
  max-width: 1000px;
  margin: 0 auto;
}
.invoice-editor-errors .error {
  color: #f00;
}
.invoice-editable-wrapper {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  display: table;
  transition: all 0.2s ease;
  position: relative;
}
.invoice-editable-wrapper.settings-showing {
  max-width: 1000px;
}
.invoice-editable-wrapper.settings-showing .invoice-settings {
  display: inline-block;
  visibility: visible;
  opacity: 1;
  z-index: 1;
}
.invoice-editable-wrapper .invoice.editable {
  display: inline-block;
  margin: 0;
  width: 100%;
}
.invoice-editable-wrapper .invoice-settings {
  width: 300px;
  display: inline-block;
  display: table-cell;
  vertical-align: top;
  height: 100%;
  position: relative;
  border-left: none;
  position: absolute;
  visibility: hidden;
  top: 0;
  right: 3px;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -1;
}
.invoice-editable-wrapper .invoice-settings .invoice-settings-inner {
  padding: 0 0 2rem 3rem;
}
.invoice-editable-wrapper .invoice-settings input {
  padding: 4px 10px;
}
.invoice-editable-wrapper .panel-inline-setting .panel-inline-setting-input {
  display: none;
}
.invoice-editable-wrapper .panel-inline-setting.show-input .panel-inline-setting-input {
  display: block;
  padding: 10px;
}
.invoice-editable-wrapper .panel-inline-setting.show-input .panel-inline-setting-button {
  display: none;
}
.invoice-editable-wrapper label {
  margin-bottom: 6px;
  display: block;
}
.invoice-editable-wrapper input {
  width: 100%;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 36px;
  width: 78%;
  display: inline-block;
}
.invoice-editable-wrapper button {
  width: 20%;
  display: inline-block;
  height: 40px;
}
.invoice-editable-wrapper h4 {
  padding: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 40px;
}
.invoice-editable-wrapper .text-link {
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  margin-bottom: 10px;
}
.invoice-editable-wrapper .text-link:hover {
  color: #000;
  background: rgba(255, 255, 255, 0.5);
}
.invoice-editable-wrapper .text-link.hover-danger:hover {
  background-color: #ea736a !important;
  color: #fff !important;
}
.invoice-editable-wrapper .text-link span {
  margin-right: 8px;
}
.invoice-editable-wrapper .ti-close {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
  display: none;
}
.invoice-editable-wrapper .settings-button {
  position: absolute;
  right: -50px;
  top: 30px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 52px;
  font-size: 22px;
  border: 1px solid #cfcfcf;
  background: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 4px 4px 0;
  border-left: 0;
}
.hover-colors {
  background: transparent;
  color: #257eff;
  border: 2px solid #257eff;
}
.editable-hover-styles {
  background: transparent;
  color: #257eff;
  border: 2px solid #257eff;
}
.editable-hover-styles:hover {
  box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.05);
}
.invoice.editable {
  margin: 20px auto;
}
.invoice.editable li {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.invoice.editable .invoice-items-header {
  cursor: default;
}
.invoice.editable .line-item-actions {
  position: absolute;
  right: -50px;
  top: 50%;
  margin-top: -34px;
  background-color: transparent !important;
  width: 68px;
  cursor: default;
  display: block !important;
}
.invoice.editable .line-item-actions > div {
  display: none;
}
.invoice.editable .line-item-actions:hover > div {
  display: block;
}
.invoice.editable li:hover .line-item-actions {
  border-top: none;
}
.invoice.editable li:hover .line-item-actions > div {
  display: block;
}
.invoice.editable .edit-item,
.invoice.editable .delete-item,
.invoice.editable .set-date {
  height: 2rem;
  width: 2rem;
  margin: 10px 0 0 10px;
  float: left;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: 0.5;
}
.invoice.editable .edit-item:hover,
.invoice.editable .delete-item:hover,
.invoice.editable .set-date:hover {
  opacity: 1;
}
.invoice.editable .edit-item {
  /* background: transparent url(../images/edit.svg) no-repeat center center / 2rem; */
}
.invoice.editable .transparent {
  border: none;
  box-shadow: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  height: 100%;
  padding: 0;
  color: transparent;
  z-index: 1;
}
.invoice.editable .due-date .transparent {
  padding-left: 10px;
}
.invoice.editable .invoice-meta > div,
.invoice.editable .invoice-due-date > div {
  overflow: visible;
  position: relative;
}
.invoice.editable .date-outer {
  position: relative;
  display: inline-block;
}
.invoice.editable .due-date {
  position: relative;
}
.invoice.editable .delete-item {
  /* background: transparent url(../images/delete.svg) no-repeat center center / 2rem; */
}
.invoice.editable .edit-client,
.invoice.editable .edit-date,
.invoice.editable .edit-due-date {
  position: absolute;
}
.invoice.editable .add-item {
  text-align: center;
}
.invoice.editable #add-invoice-item {
  clear: both;
  cursor: pointer;
  margin: 20px auto 20px auto;
  padding: 1rem;
  font-size: 14px;
  transition: all 0.05s ease-in;
  display: block;
  color: #888;
  background: rgba(0, 0, 0, 0.015);
  border: 2px dashed rgba(0, 0, 0, 0.05);
}
.invoice.editable #add-invoice-item:hover {
  color: #257eff !important;
  background: transparent;
  color: #257eff;
  border: 2px solid #257eff;
}
.invoice.editable .editing .line-item-actions {
  position: absolute;
}
.invoice.editable .editing .edit-item {
  display: none;
}
.invoice.editable .editing > div {
  position: relative;
}
.invoice.editable .editing .error {
  border: 1px solid #ff8281;
}
.invoice.editable .editing .invoice-subtotal {
  line-height: 31px;
}
.invoice.editable input,
.invoice.editable textarea {
  width: 100%;
  box-shadow: none;
  border-radius: 1px;
  padding: 5px;
  height: 40px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #000;
}
.invoice.editable select {
  width: 100%;
  height: 20px;
}
.invoice.editable .select2-container--default .select2-selection--multiple {
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  line-height: 38px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 75%;
  font-size: 12px;
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}
form .select2-container .select2-selection--multiple {
  border: 1px solid #d9d9d9;
  height: 40px;
}
form.select2-container--focus .select2-selection--multiple {
  border-color: rgba(82, 168, 236, 0.8) !important;
}
form .select2-container .country-selector.select2-selection--multiple .select2-selection__choice {
  width: 100%;
  max-width: 75%;
  line-height: 40px;
  border: none;
  background: transparent;
  font-size: 14px;
  margin: 0;
}
form .select2-container .country-selector.select2-selection--multiple .select2-search__field {
  line-height: 40px;
}
form .select2-container .select2-selection--single {
  height: 32px;
  border-radius: 3px;
  border-color: #D9D9D9;
}
form .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px;
}
#invoice-builder:before,
#invoice-builder:after {
  content: " ";
  display: table;
}
#invoice-builder:after {
  clear: both;
}
#invoice-builder #choose-invoice-tasks,
#invoice-builder #invoice-preview {
  width: 45%;
  float: left;
}
#invoice-builder #choose-invoice-tasks {
  margin-right: 4%;
  border-right: 1px solid #ccc;
  min-height: 300px;
  padding: 20px;
}
#invoice-builder .invoice-details,
#invoice-builder .invoice-contact-info {
  display: none;
}
#invoice-builder-task-list #completed-tasks-list {
  display: none;
}
#invoice-builder-task-list .task-list-menu:before,
#invoice-builder-task-list .task-list-menu:after {
  content: " ";
  display: table;
}
#invoice-builder-task-list .task-list-menu:after {
  clear: both;
}
#invoice-builder-task-list a {
  float: right;
  font-size: 85%;
}
#invoice-builder-task-list a:before,
#invoice-builder-task-list a:after {
  content: " ";
  display: table;
}
#invoice-builder-task-list a:after {
  clear: both;
}
#invoice-builder-task-list .task-total-time {
  width: 150px;
  position: absolute;
  right: 0px;
  top: 0;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.task-drag-helper {
  border: 1px solid #E6F0F9;
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
  background: #F2F8FD;
}
.invoice.preview {
  margin: 8px 16px;
}
.icon-add {
  background-position: -408px -96px;
}
/** Filter Form **/
.filter {
  width: 70%;
  height: 30px;
  padding-right: 30px;
  position: relative;
  background-image: -webkit-linear-gradient(top, #fff 0%, #f9f9f9 100%);
  background-image: -o-linear-gradient(top, #fff 0%, #f9f9f9 100%);
  background-image: linear-gradient(to bottom, #fff 0%, #f9f9f9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff9f9f9', GradientType=0);
}
.filter input {
  width: 95%;
  height: 20px;
  padding: 4px 2.5%;
  position: relative;
  right: -1px;
  z-index: 2;
  background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #fff 100%);
  background-image: -o-linear-gradient(top, #f9f9f9 0%, #fff 100%);
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#ffffffff', GradientType=0);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px 0 0 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.filter input:focus {
  box-shadow: none;
}
.filter .choose-filter-button {
  width: 28px;
  height: 28px;
  float: left;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0 3px 3px 0;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  /* background: transparent url(../images/dropdown-arrow.png) no-repeat center center; */
}
.filter ul {
  position: absolute;
  min-width: 100%;
  z-index: 999999;
  margin: 0;
  display: none;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, #fff 0%, #f9f9f9 100%);
  background-image: -o-linear-gradient(top, #fff 0%, #f9f9f9 100%);
  background-image: linear-gradient(to bottom, #fff 0%, #f9f9f9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff9f9f9', GradientType=0);
}
.filter ul.active {
  display: block;
  top: 32px;
}
.filter li {
  list-style: none;
  padding: 5px 10px;
}
.filter li:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
/** Modals **/
.modal-overlay {
  text-align: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999998;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,2989d8+50,207cca+51,7db9e8+100;Blue+Gloss+Default */
  background-image: radial-gradient(ellipse, rgba(188, 198, 206, 0.5) 10%, rgba(98, 112, 120, 0.6));
}
.error-message {
  display: block;
  clear: both;
  font-size: 11px;
  color: #ff0000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.form form:not(.form--sectioned) {
  padding: 2rem;
}
.form input,
.form select,
.form textarea {
  padding: 7px 12px;
  display: block;
  margin: 0 0 20px 0;
}
.form input:focus,
.form textarea:focus {
  box-shadow: none !important;
}
.form input[type=text],
.form input[type=number],
.form input[type=password],
.form input[type=email],
.form textarea,
.form select,
.form .select2-container {
  width: 100% !important;
}
.form input[type=text],
.form input[type=number],
.form input[type=password],
.form input[type=email],
.form textarea,
.form .select2-selection {
  border: 1px solid #DBDFF3 !important;
  margin-top: 1px;
  border-radius: 3px;
  color: #333;
  box-shadow: none;
  outline: none;
}
.form input[type=text]:focus,
.form input[type=number]:focus,
.form input[type=password]:focus,
.form input[type=email]:focus,
.form textarea:focus,
.form .select2-selection:focus {
  border-color: #3A67FF !important;
  border-width: 2px !important;
  margin-top: 0;
  background: #f9fbff;
}
.form .select2-container--focus .select2-selection {
  border-color: #3A67FF !important;
  border-width: 2px !important;
  margin-top: 0;
  background: #f9fbff !important;
}
.form input[type=text],
.form input[type=number],
.form input[type=password],
.form input[type=email],
.form textarea {
  padding: 10px;
}
.form .select2-container .select2-selection--single {
  height: 42px;
}
.form input[type=date],
.form input.date {
  width: 176px;
}
.form input[type=date]:last-child,
.form input.date:last-child {
  margin-bottom: 10px;
}
.form select {
  width: 100%;
}
.form .field {
  position: relative;
}
.form .field.inline {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.form .field.inline:last-child {
  margin-right: 0;
}
.form label {
  color: #7D8592;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 4px;
  display: block;
}
.form .has-error label {
  top: 26px;
}
.form .radio {
  display: block;
}
.form .radio input {
  display: inline-block;
}
.form .error {
  display: block;
  clear: both;
  font-size: 11px;
  color: #ff0000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.form .row {
  max-width: 100%;
}
.form .label-field {
  padding-left: 2rem;
}
.form .label-field input[type=text] {
  padding: 0 0 2px 0;
  border: none  !important;
  border-bottom: 1px dotted #aaa !important;
  color: #000;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 4px;
  display: inline-block !important;
  position: relative;
  border-radius: 0 !important;
  width: auto !important;
  min-width: auto !important;
}
.form .label-field input[type=text]:focus {
  background: #fff !important;
  color: #257eff !important;
}
.form .label-field:after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  content: '';
  display: block;
  height: 2rem;
  width: 1.5rem;
  /* background: transparent url(../images/pencil.svg) no-repeat center center / 2rem; */
}
.form--sectioned {
  padding: 0;
  display: flex;
}
.form--sectioned > div {
  padding: 2rem;
}
.form__main {
  width: 100%;
}
.form__secondary {
  padding: 0 2rem;
  background: #fafafa;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  min-width: 220px;
  display: none;
}
.form__secondary h5 {
  font-weight: 500;
  margin-bottom: 1rem;
}
.optional-field-selector__field {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  /* background: transparent url(../images/add.svg) no-repeat left center / 1rem; */
}
.optional-field-selector__field:hover {
  cursor: pointer;
  color: #257eff;
  text-decoration: underline;
}
.modal {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: inline-block;
  text-align: left;
  overflow: hidden;
  min-width: 250px;
  margin: 0 auto;
  top: 20%;
  border-radius: 4px;
  background: #f0f1f6;
  background: #fefefe;
  -webkit-background-clip: border-box;
  box-shadow: 7.5px 13px 40px rgba(54, 71, 82, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
}
.modal:before,
.modal:after {
  content: " ";
  display: table;
}
.modal:after {
  clear: both;
}
.modal > div {
  min-width: 350px;
}
.modal.alert {
  padding-top: 20px;
}
.modal.alert.success p {
  color: #40b842;
  font-weight: bold;
}
.modal.alert p {
  margin-bottom: 0;
}
.modal.alert .alert {
  max-width: 500px;
}
.modal.alert button {
  width: 80%;
  margin: 0 auto;
  display: block;
}
.modal .modal-info {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #e1e6ef;
  background: #fafafa;
  min-width: auto;
}
.modal .modal-info h3 {
  line-height: 50px;
  padding-left: 20px;
  display: inline-block;
  font-size: 17px;
  font-weight: normal;
  margin-top: 1px;
}
.modal .modal-info .close {
  font-size: 17px;
  font-weight: normal;
  position: absolute;
  right: 9px;
  top: 12px;
  cursor: pointer;
  opacity: 0.4;
}
.modal .modal-info .close:hover {
  opacity: 1;
}
.modal form {
  margin-bottom: 0;
  float: left;
  width: 100%;
}
.modal .button.blue {
  width: 100%;
}
.modal form:not(.form--sectioned) {
  padding: 2rem;
}
.modal input,
.modal select,
.modal textarea {
  padding: 7px 12px;
  display: block;
  margin: 0 0 20px 0;
}
.modal input:focus,
.modal textarea:focus {
  box-shadow: none !important;
}
.modal input[type=text],
.modal input[type=number],
.modal input[type=password],
.modal input[type=email],
.modal textarea,
.modal select,
.modal .select2-container {
  width: 100% !important;
}
.modal input[type=text],
.modal input[type=number],
.modal input[type=password],
.modal input[type=email],
.modal textarea,
.modal .select2-selection {
  border: 1px solid #DBDFF3 !important;
  margin-top: 1px;
  border-radius: 3px;
  color: #333;
  box-shadow: none;
  outline: none;
}
.modal input[type=text]:focus,
.modal input[type=number]:focus,
.modal input[type=password]:focus,
.modal input[type=email]:focus,
.modal textarea:focus,
.modal .select2-selection:focus {
  border-color: #3A67FF !important;
  border-width: 2px !important;
  margin-top: 0;
  background: #f9fbff;
}
.modal .select2-container--focus .select2-selection {
  border-color: #3A67FF !important;
  border-width: 2px !important;
  margin-top: 0;
  background: #f9fbff !important;
}
.modal input[type=text],
.modal input[type=number],
.modal input[type=password],
.modal input[type=email],
.modal textarea {
  padding: 10px;
}
.modal .select2-container .select2-selection--single {
  height: 42px;
}
.modal input[type=date],
.modal input.date {
  width: 176px;
}
.modal input[type=date]:last-child,
.modal input.date:last-child {
  margin-bottom: 10px;
}
.modal select {
  width: 100%;
}
.modal .field {
  position: relative;
}
.modal .field.inline {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.modal .field.inline:last-child {
  margin-right: 0;
}
.modal label {
  color: #7D8592;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 4px;
  display: block;
}
.modal .has-error label {
  top: 26px;
}
.modal .radio {
  display: block;
}
.modal .radio input {
  display: inline-block;
}
.modal .error {
  display: block;
  clear: both;
  font-size: 11px;
  color: #ff0000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.modal .row {
  max-width: 100%;
}
/* For the details, see:
http://flowplayer.org/tools/dateinput/index.html#skinning */
/* the input field */
/* calendar root element */
#calroot {
  /* place on top of other elements. set a higher value if nessessary */
  z-index: 9999999;
  margin-top: -1px;
  width: 202px;
  padding: 2px;
  background-color: #fff;
  font-size: 11px;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
/* head. contains title, prev/next month controls and possible month/year selectors */
#calhead {
  padding: 2px 0;
  height: 22px;
}
#caltitle {
  font-size: 15px;
  color: #0150D1;
  float: left;
  text-align: center;
  width: 155px;
  line-height: 20px;
  text-shadow: 0 1px 0 #ddd;
}
#calnext,
#calprev {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: text-top;
  /* background-image: url("../images/glyphicons-halflings.png"); */
  background-position: -432px -72px;
  background-repeat: no-repeat;
  float: left;
  cursor: pointer;
  margin-top: 3px;
  margin-left: 6px;
  opacity: 0.5;
}
#calnext:hover,
#calprev:hover {
  opacity: 1;
}
#calnext {
  background-position: -456px -72px;
  float: right;
  margin-left: 0;
  margin-right: 6px;
}
#calprev.caldisabled,
#calnext.caldisabled {
  visibility: hidden;
}
/* year/month selector */
#caltitle select {
  font-size: 10px;
}
/* names of the days */
#caldays {
  padding: 0 0 6px 0;
  height: 20px;
  border-bottom: 1px solid #DDD;
}
#caldays span {
  display: block;
  float: left;
  width: 28px;
  text-align: center;
}
/* container for weeks */
#calweeks {
  background-color: #fff;
  margin-top: 4px;
}
/* single week */
.calweek {
  clear: left;
  height: 22px;
}
/* single day */
.calweek a {
  display: block;
  float: left;
  width: 27px;
  height: 20px;
  text-decoration: none;
  font-size: 11px;
  margin-left: 1px;
  text-align: center;
  line-height: 20px;
  color: #666;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
/* different states */
.calweek a:hover,
.calfocus {
  background-color: #ddd;
}
/* sunday */
a.calsun {
  color: red;
}
/* offmonth day */
a.caloff {
  color: #ccc;
}
a.caloff:hover {
  background-color: #f5f5fa;
}
/* unselecteble day */
a.caldisabled {
  background-color: #efefef !important;
  color: #ccc !important;
  cursor: default;
}
/* current day */
#calcurrent {
  background-color: #498CE2;
  color: #fff;
}
/* today */
#caltoday {
  background-color: #333;
  color: #fff;
}
/** Forms **/
/* Harvest Chosen Plugin */
.select2-dropdown {
  z-index: 99999999;
}
.button.red,
.danger {
  color: white;
  background-color: #ea736a;
  border: none;
  cursor: pointer;
}
.button.red:hover,
.danger:hover {
  background-color: #db6e6a !important;
  color: #fff !important;
  border: none;
}
.button.blue {
  background: #285CFF;
  color: #fff;
  border: none;
}
.button.blue:hover {
  background: #285CFF;
  color: #fff !important;
  border: none;
}
.hover-red {
  background: #aaa !important;
  color: #fff !important;
  border: none !important;
}
.hover-red:hover {
  background-color: #db6e6a !important;
  color: #fff !important;
  border: none;
}
.hover-blue {
  background: #aaa !important;
  color: #fff !important;
  border: none !important;
}
.hover-blue:hover {
  background: #2CADF6 !important;
  color: #fff !important;
  border: none;
}
.button.small {
  padding: 2px 10px;
  line-height: 21px;
}
.button.tiny {
  padding: 2px 10px;
  line-height: 14px;
  font-size: 9px;
}
input[type=submit],
.submit {
  padding: 7px 24px !important;
  float: right;
  text-decoration: none !important;
}
input[type=submit].small {
  padding: 4px 10px !important;
}
.button {
  background: #285CFF;
  color: #fff;
  border: none;
  display: inline-block;
}
.button:hover {
  background: #285CFF;
  color: #fff !important;
  border: none;
}
.button.disabled,
.button.disabled:hover {
  opacity: 0.6;
  cursor: not-allowed;
}
.button.large {
  padding: 10px 40px;
  font-size: 16px;
}
.button:hover {
  box-shadow: 0 1px 2px rgba(43, 50, 57, 0.08);
  border: 1px solid #bdbdbd;
}
.button[disabled=disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.clear-button {
  color: #444;
}
.button.dark {
  color: white;
  margin-left: 10px;
  background: #444;
  cursor: pointer;
}
.button.dark .icon {
  color: #fff;
}
.button.green {
  background: #552CF6;
  color: #fff;
  border: none;
}
.clear-button {
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
.clear-button .icon {
  margin-right: 10px;
}
.clear-button:hover {
  color: #2381e9;
}
.clear-button:hover .icon {
  color: #2381e9 !important;
}
.button.flat {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #f1f1f1 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #f1f1f1 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #f1f1f1 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff1f1f1', GradientType=0);
  border: 1px solid #DCDCDC;
  box-shadow: none;
}
.button.flat .icon {
  margin-right: 10px;
  position: relative;
  top: 1px;
}
.button.flat:hover {
  background-image: -webkit-linear-gradient(top, #ececec 0%, #e8e8e8 100%);
  background-image: -o-linear-gradient(top, #ececec 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #ececec 0%, #e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffececec', endColorstr='#ffe8e8e8', GradientType=0);
  border: 1px solid #ccc;
}
a.button:hover {
  text-decoration: none;
}
input[type=submit] {
  float: right;
}
/** Buttons **/
.button-set {
  display: block;
}
.button-set {
  opacity: 1;
  height: 30px;
  margin: 0;
  display: inline-block;
  border-radius: 3px;
}
.button-set li {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  height: 30px;
  line-height: 29px;
  list-style: none;
  margin: 0 0 0 -1px;
  text-align: center;
  vertical-align: middle;
  width: 75px;
}
.button-set li:first-child {
  border-radius: 3px 0 0 3px;
}
.button-set li:last-child {
  border-radius: 0 3px 3px 0;
  border-right: none;
}
.button-set li:hover {
  background: #ebf2fb;
}
.button-set li.selected span {
  color: #2381e9 !important;
}
.solid-button-set {
  opacity: 1;
  height: 30px;
  margin: 0;
  border-radius: 3px;
}
.solid-button-set li {
  background: #285CFF;
  color: #fff;
  border: none;
  display: inline-block;
  padding: 0.75rem 2rem;
  border-radius: 3px;
  border-color: transparent;
  border-radius: 0;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  display: block;
  float: left;
  margin: 0;
  background: #f5f5f5;
}
.solid-button-set li:hover {
  background: #285CFF;
  color: #fff !important;
  border: none;
}
.solid-button-set li.disabled,
.solid-button-set li.disabled:hover {
  opacity: 0.6;
  cursor: not-allowed;
}
.solid-button-set li.large {
  padding: 10px 40px;
  font-size: 16px;
}
.solid-button-set li:hover {
  box-shadow: 0 1px 2px rgba(43, 50, 57, 0.08);
  border: 1px solid #bdbdbd;
}
.solid-button-set li[disabled=disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.solid-button-set li:first-child {
  border-radius: 4px 0 0 4px;
}
.solid-button-set li:last-child {
  border-radius: 0 4px 4px 0;
}
.solid-button-set li:hover {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 5px 0px;
  color: #259af4;
}
.solid-button-set li.selected,
.solid-button-set li.selected:hover,
.solid-button-set li:active {
  color: #000;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1);
}
.button-set.small,
.beveled-button-set.small {
  height: 25px;
}
.button-set.small li,
.beveled-button-set.small li {
  height: 25px;
  padding: 2px 10px;
  font-size: 11px;
}
.panel .button-set {
  display: inline-block;
}
.button .add-item {
  position: relative;
  margin-right: 5px;
  top: -2px;
}
.button .edit-notes {
  background-position: 0 -72px;
  position: relative;
  margin-right: 5px;
}
.tabs {
  list-style: none;
  border-bottom: solid 1px #E6E6E6;
  display: block;
  height: 40px;
  padding: 0;
  margin-bottom: 20px;
}
.tabs dd:first-child,
.tabs li:first-child {
  margin-left: 0;
}
.tabs dd.active {
  border-top: 1px solid #ccc;
  margin-top: -1px;
}
.tabs dd {
  display: block;
  float: left;
  padding: 0;
  margin: 0;
}
.tabs dd.active a {
  cursor: default;
  color: #3C3C3C;
  background: white;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-weight: bold;
}
.tabs dd a,
.tabs li > a {
  color: #6F6F6F;
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0px 23.8px;
}
ul.tabs-content {
  display: block;
  margin: 0 0 20px;
  padding: 0 10px;
}
.tabs-content {
  clear: both;
}
.tabs-content:before,
.tabs-content:after {
  content: " ";
  display: table;
}
.tabs-content:after {
  clear: both;
}
ul.tabs-content > li.active {
  display: block;
}
ul.tabs-content > li {
  display: none;
  position: relative;
}
ul.tabs-content > li:before,
ul.tabs-content > li:after {
  content: " ";
  display: table;
}
ul.tabs-content > li:after {
  clear: both;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  opacity: 0.3;
}
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown:hover .caret,
.open .caret {
  opacity: 1;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  display: none;
  float: left;
  min-width: 160px;
  padding: 4px 0;
  margin: 1px 0 0;
  list-style: none;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu a,
.dropdown-menu span {
  display: block;
  padding: 3px 15px;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
}
.dropdown-menu li > a:hover,
.dropdown-menu li > span:hover,
.dropdown-menu .active > a,
.dropdown-menu .active > span,
.dropdown-menu .active > a:hover,
.dropdown-menu .active > span:hover {
  color: #08c;
  text-decoration: none;
  background-color: #fff;
}
.open {
  *z-index: 1000;
}
.open > .dropdown-menu {
  display: block;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000;
  content: "\2191";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-menu .divider {
  height: 2px;
  margin: 8px 1px;
  overflow: hidden;
  background-color: #E5E5E5;
  border-bottom: 1px solid white;
}
.typeahead {
  margin-top: 2px;
  border-radius: 4px;
}
.add-list-item,
.edit-notes {
  opacity: 0.95;
}
.add-list-item:hover,
.edit-notes:hover {
  opacity: 1;
}
/** Scrollbars **/
.scrollable {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
}
.scrollable .viewport {
  height: 100%;
}
.scrollable .scroll-content {
  position: relative;
  width: 100%;
}
.scrollable .scrollbar {
  float: right;
  width: 6px;
  position: absolute;
  right: 0px;
  top: 0;
  opacity: 0;
  transition: all 0.2s linear;
}
.scrollable:hover .scrollbar {
  opacity: 1;
}
.scrollable .track {
  height: 100%;
  width: 6px;
  position: relative;
  padding: 0 1px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -o-border-radius: 3px;
}
.scrollable .thumb {
  height: 20px;
  width: 6px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -o-border-radius: 3px;
}
.scrollable .thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.scrollbar.disable {
  display: none;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#cancel-all {
  display: none;
}
.uploading #cancel-all {
  display: block;
}
.add-file-button {
  position: relative;
  overflow: hidden;
}
#fileupload {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.progress-bar-wrapper {
  margin: 10px auto 9px auto;
  border-bottom: 1px solid #ddd;
  padding: 10px 10px 20px 10px;
  width: 100%;
}
.progress-bar-wrapper .filename {
  width: 85%;
  float: left;
}
.progress-bar-wrapper .cancel,
.progress-bar-wrapper .status {
  width: 9%;
  float: right;
  font-size: 85%;
  text-align: right;
}
.progress-bar-wrapper .status {
  display: none;
}
.progress-bar-wrapper .progress {
  height: 20px;
  margin-top: 5px;
  background: #EBEBEB;
  border-radius: 10px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.progress-bar-wrapper .progress > span {
  position: relative;
  float: left;
  margin: 0 -1px;
  min-width: 30px;
  height: 20px;
  line-height: 8px;
  text-align: right;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #F0AD24 #EBA310 #C5880D;
  background: #ffe393;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ffe393 0%, #ffdf92 70%, #fdcc63 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffe393), color-stop(70%, #ffdf92), color-stop(100%, #fdcc63));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffe393 0%, #ffdf92 70%, #fdcc63 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffe393 0%, #ffdf92 70%, #fdcc63 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffe393 0%, #ffdf92 70%, #fdcc63 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #ffe393 0%, #ffdf92 70%, #fdcc63 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe393', endColorstr='#fdcc63',GradientType=0 );
  /* IE6-9 */
  -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
}
.progress-bar-wrapper .progress > span span {
  padding: 0 8px;
  font-size: 12px;
  line-height: 20px;
  color: #404040;
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 0 1px rgba(255, 255, 255, 0.4);
}
.progress-bar-wrapper .progress > span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 18px;
  border-radius: 10px;
}
.cancelled {
  padding: 5px 10px 10px 10px;
}
.cancelled .progress {
  height: 3px;
}
.cancelled .progress span {
  height: 1px;
  position: relative;
  top: 1px;
  background: #555;
  background-image: none;
  border: none;
  box-shadow: none;
}
.cancelled .progress span span {
  display: none;
}
.cancelled .cancel {
  display: none;
}
.cancelled .status {
  display: block;
}
.complete .progress > span {
  border-color: #95B961 #87A55B #738D4E;
  background-color: #CCC;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #CCEB9B), color-stop(70%, #A9DC65), color-stop(100%, #89C443));
  background-image: -webkit-linear-gradient(top, #CCEB9B 0%, #A9DC65 70%, #89C443 100%);
  background-image: -moz-linear-gradient(top, #CCEB9B 0%, #A9DC65 70%, #89C443 100%);
  background-image: -ms-linear-gradient(top, #CCEB9B 0%, #A9DC65 70%, #89C443 100%);
  background-image: -o-linear-gradient(top, #CCEB9B 0%, #A9DC65 70%, #89C443 100%);
  background-image: linear-gradient(top, #CCEB9B 0%, #A9DC65 70%, #89C443 100%);
  -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
}
.complete .cancel {
  display: none;
}
.complete .status {
  display: block;
}
#hidden,
.hidden {
  position: absolute;
  top: 0;
  left: -9999999px;
  visibility: hidden;
}
#calendar-outer {
  padding: 0 20px 20px 20px;
}
#calendar {
  height: 100%;
  width: 98%;
  margin: 0 auto;
}
.fc-header h2 {
  font-size: 18px;
  font-weight: normal;
}
.fc-widget-header {
  border-width: 0 0 0 0 !important;
  color: #888;
  font-weight: normal;
}
.fc-day-number {
  color: #888;
}
.fc-today {
  background: #e7eef6;
}
.fc-today .fc-day-number {
  color: #3790f4;
  font-weight: bold;
}
.fc-widget-content {
  border-color: #cbcbcb;
}
td.fc-first,
td.fc-last {
  background-color: #fafafa;
}
#calendar .has-tasks {
  background: #EDEFF5;
}
#calendar-header #task-filters {
  margin-top: 5px;
}
#calendar-header .date-name {
  line-height: 30px;
  margin-right: 10px;
  font-size: 16px;
}
#calendar-header .task-filter {
  margin-left: 10px;
}
#calendar-header #calendar-menu > * {
  vertical-align: middle;
  display: inline-block;
}
#calendar-header #calendar-view-selector {
  width: 75px;
  margin-right: 10px;
}
.calendar-event-item {
  position: relative;
}
.calendar-event-item .task-status {
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background: #4da6ff;
  border: 1px solid #0059b3;
  position: absolute;
  top: 4px;
  left: 5px;
  opacity: 0.7;
}
.calendar-event-item.at-risk .task-status {
  background: #fff3a3;
  border: 1px solid #d6ba00;
}
.calendar-event-item.overdue .task-status {
  background: #E96267;
  border: 1px solid #9c161b;
}
.calendar-event-item .task-name {
  margin-left: 20px;
  color: #666;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.calendar-item-popup {
  width: 300px;
  background: #fff;
  padding: 20px 30px;
  position: absolute;
  z-index: 99;
  top: 20%;
  left: 50%;
  margin-left: -150px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.calendar-item-popup .close {
  position: absolute;
  top: 7px;
  right: 7px;
  height: 10px;
  width: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #888;
}
#confirm-box .message {
  margin: 20px 0 40px 0;
}
#confirm-box h3 {
  font-weight: normal;
}
#confirm-box .confirmation-buttons {
  float: right;
}
#confirm-box.success .red.action {
  background: #58cf2c;
}
#confirm-box.success .red.action:hover {
  background: #58cf2c !important;
  border-color: #438d23;
}
#confirm-box p {
  padding: 10px;
  margin-bottom: 30px;
}
#confirm-box.has-details .message {
  margin-top: 10px;
  margin-bottom: 0px;
}
.nano .slider {
  background: #111;
}
#credit-card-payment-form,
#paypal-payment-form,
#manual-payment-form {
  width: 640px;
}
#credit-card-payment-form p,
#paypal-payment-form p,
#manual-payment-form p {
  margin: 20px 0;
}
#credit-card-payment-form .accepted-cards,
#paypal-payment-form .accepted-cards,
#manual-payment-form .accepted-cards {
  width: 230px;
  height: 32px;
  margin: 0 0 20px 0;
  /* background: transparent url(../images/icon-cards.gif) no-repeat 0 0; */
  border-right: 1px solid #DDD;
}
#credit-card-payment-form .form-fields,
#paypal-payment-form .form-fields,
#manual-payment-form .form-fields,
#credit-card-payment-form .invoice-summary,
#paypal-payment-form .invoice-summary,
#manual-payment-form .invoice-summary,
#credit-card-payment-form .paypal-instructions,
#paypal-payment-form .paypal-instructions,
#manual-payment-form .paypal-instructions {
  float: left;
}
#credit-card-payment-form .form-fields,
#paypal-payment-form .form-fields,
#manual-payment-form .form-fields {
  width: 350px;
  margin-right: 30px;
}
#credit-card-payment-form .form-fields:before,
#paypal-payment-form .form-fields:before,
#manual-payment-form .form-fields:before,
#credit-card-payment-form .form-fields:after,
#paypal-payment-form .form-fields:after,
#manual-payment-form .form-fields:after {
  content: " ";
  display: table;
}
#credit-card-payment-form .form-fields:after,
#paypal-payment-form .form-fields:after,
#manual-payment-form .form-fields:after {
  clear: both;
}
#credit-card-payment-form .card-number,
#paypal-payment-form .card-number,
#manual-payment-form .card-number {
  float: left;
  clear: both;
  width: 100%;
}
#credit-card-payment-form input[type=text],
#paypal-payment-form input[type=text],
#manual-payment-form input[type=text],
#credit-card-payment-form select,
#paypal-payment-form select,
#manual-payment-form select {
  width: 100%;
}
#credit-card-payment-form .first-name,
#paypal-payment-form .first-name,
#manual-payment-form .first-name,
#credit-card-payment-form .last-name,
#paypal-payment-form .last-name,
#manual-payment-form .last-name,
#credit-card-payment-form .cvc,
#paypal-payment-form .cvc,
#manual-payment-form .cvc,
#credit-card-payment-form .expiration-month,
#paypal-payment-form .expiration-month,
#manual-payment-form .expiration-month,
#credit-card-payment-form .expiration-year,
#paypal-payment-form .expiration-year,
#manual-payment-form .expiration-year {
  float: left;
}
#credit-card-payment-form .first-name,
#paypal-payment-form .first-name,
#manual-payment-form .first-name,
#credit-card-payment-form .last-name,
#paypal-payment-form .last-name,
#manual-payment-form .last-name {
  width: 170px;
}
#credit-card-payment-form .cvc,
#paypal-payment-form .cvc,
#manual-payment-form .cvc {
  width: 70px;
}
#credit-card-payment-form .top-label,
#paypal-payment-form .top-label,
#manual-payment-form .top-label {
  margin-bottom: 5px;
  color: #999;
}
#credit-card-payment-form .expiration-month,
#paypal-payment-form .expiration-month,
#manual-payment-form .expiration-month,
#credit-card-payment-form .expiration-year,
#paypal-payment-form .expiration-year,
#manual-payment-form .expiration-year {
  width: 130px;
}
#credit-card-payment-form .first-name,
#paypal-payment-form .first-name,
#manual-payment-form .first-name,
#credit-card-payment-form .expiration-month,
#paypal-payment-form .expiration-month,
#manual-payment-form .expiration-month,
#credit-card-payment-form .expiration-year,
#paypal-payment-form .expiration-year,
#manual-payment-form .expiration-year {
  margin-right: 10px;
}
#credit-card-payment-form .card-number input,
#paypal-payment-form .card-number input,
#manual-payment-form .card-number input {
  margin-bottom: 10px;
}
#credit-card-payment-form .invoice-summary,
#paypal-payment-form .invoice-summary,
#manual-payment-form .invoice-summary {
  border-left: 1px solid #EEE;
  width: 220px;
  height: 250px;
  padding-left: 30px;
}
#credit-card-payment-form .invoice-summary .invoice-number,
#paypal-payment-form .invoice-summary .invoice-number,
#manual-payment-form .invoice-summary .invoice-number {
  color: #888;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
#credit-card-payment-form .invoice-summary .invoice-meta-wrapper,
#paypal-payment-form .invoice-summary .invoice-meta-wrapper,
#manual-payment-form .invoice-summary .invoice-meta-wrapper {
  margin-top: 20px;
}
#credit-card-payment-form .invoice-summary .invoice-meta-wrapper span:first-child,
#paypal-payment-form .invoice-summary .invoice-meta-wrapper span:first-child,
#manual-payment-form .invoice-summary .invoice-meta-wrapper span:first-child {
  background: #F2F8FD;
  width: 100px;
  display: inline-block;
  color: #666;
  margin-bottom: 5px;
  padding: 4px 5px;
}
#credit-card-payment-form .invoice-summary .invoice-meta-wrapper .button,
#paypal-payment-form .invoice-summary .invoice-meta-wrapper .button,
#manual-payment-form .invoice-summary .invoice-meta-wrapper .button {
  margin-bottom: 10px;
}
#credit-card-payment-form .submit-button,
#paypal-payment-form .submit-button,
#manual-payment-form .submit-button {
  width: 100%;
  clear: both;
}
#credit-card-payment-form .submit-button:before,
#paypal-payment-form .submit-button:before,
#manual-payment-form .submit-button:before,
#credit-card-payment-form .submit-button:after,
#paypal-payment-form .submit-button:after,
#manual-payment-form .submit-button:after {
  content: " ";
  display: table;
}
#credit-card-payment-form .submit-button:after,
#paypal-payment-form .submit-button:after,
#manual-payment-form .submit-button:after {
  clear: both;
}
#credit-card-payment-form .form-header,
#paypal-payment-form .form-header,
#manual-payment-form .form-header {
  clear: both;
}
#credit-card-payment-form .billing-icon,
#paypal-payment-form .billing-icon,
#manual-payment-form .billing-icon {
  background-position: -120px 0;
}
#paypal-payment-form {
  width: 590px;
}
#paypal-payment-form .paypal-instructions {
  width: 300px;
  float: left;
  margin-right: 30px;
}
#paypal-payment-form .invoice-summary {
  height: 200px;
}
#payment-processing-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(250, 250, 250, 0.8);
  height: 100%;
  width: 100%;
}
#payment-processing-overlay > div {
  width: 250px;
  background-color: #5b99de;
  padding: 10px;
  color: white;
  top: 40%;
  position: absolute;
  left: 50%;
  margin-left: -125px;
  border-radius: 4px;
  text-align: center;
}
#payment-processing-overlay span {
  font-size: 85%;
}
.no-activity {
  font-style: italic;
}
#project-details h3 {
  -webkit-font-smoothing: antialiased;
}
#project-details h5.section-title {
  color: #000;
  font-size: 17px;
  margin-top: 40px;
  margin-bottom: 20px;
}
#project-details .status-indicator {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #4da6ff;
  display: inline-block;
  position: relative;
  top: 0px;
}
#project-details .status-indicator.overdue,
#project-details .status-indicator.behind-schedule {
  background: #E96267;
}
#project-details .status-indicator.at-risk {
  background: #fff3a3;
}
#project-details #top-details {
  height: 140px;
  overflow: hidden;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
  display: block;
  max-width: 1202px;
  margin: 20px auto;
  font-size: 0;
}
#project-details #top-details > div {
  display: inline-block;
  padding: 30px 10px 10px 10px;
  height: 100%;
  width: 20%;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
#project-details #top-details > div:last-child {
  margin-right: 0;
  border: none;
}
#project-details #top-details > div span {
  color: #82929F;
  font-size: 80%;
}
#project-details #top-details.has-due-date > div {
  width: 16.66%;
}
#project-details #top-details h3 {
  margin-top: 5px;
  margin-bottom: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#project-details #top-details h3.on-schedule {
  font-size: 20px;
}
#project-details #top-details h3.behind-schedule {
  font-size: 18px;
}
#project-details #top-details h5 {
  color: #bbb;
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#project-details #main-dashboard {
  max-width: 1202px;
  margin: 20px auto;
}
#project-details #activity-stream-space {
  width: 65%;
  margin-right: 5%;
}
#project-details .project-stats {
  width: 30%;
}
#project-details .project-stats .icon {
  margin-right: 10px;
  color: #888;
}
#project-details .project-action {
  color: #428bca;
  text-decoration: none;
  cursor: pointer;
}
#project-details .project-action:hover {
  text-decoration: underline;
}
#project-details #activity-stream-space,
#project-details .project-stats {
  float: left;
}
#project-details .activity-stream {
  margin: 0 5% 0 0;
}
#project-details .activity-stream h3 {
  margin-bottom: 20px;
  font-size: 14px;
}
#project-details .activity-stream .activity-description {
  color: #82929F;
  font-weight: normal;
}
#project-details .people-section {
  padding-top: 20px;
}
#project-details .project-info {
  padding: 0 20px;
}
#project-details .people-list {
  margin-left: -10px;
}
#project-details .people-list li {
  width: 100%;
  padding: 16px 10px;
  text-align: left;
  height: 50px !important;
}
#project-details .people-list a {
  position: relative;
}
#project-details .people-list .person-image {
  height: 32px;
  width: 32px;
  position: absolute;
  top: -6px;
  left: 0px;
  background-size: 100% 100%;
}
#project-details .people-list .person-image img {
  width: 100%;
}
#project-details .people-list a span {
  display: block;
}
#project-details .people-list .person-name {
  font-weight: normal;
  display: block !important;
  margin-right: 65px;
  color: #82929F;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#project-details .people-list .person-role {
  position: absolute;
  top: 0;
  right: 0;
}
#project-details .people-list .person-meta {
  padding-left: 42px;
  text-align: left;
}
.project-dashboard-widget {
  border: 1px solid #d0d5d8;
  border-top-width: 3px;
  display: block;
  padding: 30px;
  height: 250px;
  position: relative;
}
.project-dashboard-widget .title {
  font-size: 11px;
  color: #8a959e;
  font-weight: 600;
  text-transform: uppercase;
}
.project-dashboard-widget p {
  font-size: 16px;
}
.project-dashboard-widget h3 {
  font: 300 35px/52px "Open Sans", "open-sans", sans-serif;
  font-size: 24px;
  color: #323a45;
  line-height: 35px;
  margin: 0;
  text-transform: none;
}
/** Activity feed **/
.activity-stream {
  padding: 0;
  margin: 0;
}
.activity-stream ul {
  overflow: hidden;
}
.activity-stream ul:before,
.activity-stream ul:after {
  content: " ";
  display: table;
}
.activity-stream ul:after {
  clear: both;
}
.activity-stream li {
  list-style: none;
  margin: 20px 0;
  line-height: 18px;
  overflow: visible;
  position: relative;
  float: left;
  width: 100%;
}
.activity-stream li:before,
.activity-stream li:after {
  content: " ";
  display: table;
}
.activity-stream li:after {
  clear: both;
}
.activity-stream li:first-child {
  padding-top: 0;
  background-position: 0 3px;
}
.activity-stream .activity-content {
  padding-left: 50px;
}
.activity-stream .activity-user {
  color: #666;
  display: inline-block;
}
.activity-stream .activity-timestamp {
  color: #999;
  font-size: 11px;
  margin-left: 5px;
  width: 100px;
}
.activity-stream .object-link {
  margin-top: 5px;
  min-height: 18px;
  line-height: 14px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0 0px;
}
.activity-stream .deleted-object {
  display: none;
  color: #aaa;
}
.activity-stream .activity-show-all {
  background-color: #f8f8f8;
  border: 1px solid #dfdfdf;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.activity-stream .activity-meta {
  float: left;
}
.activity-stream .activity-meta > div {
  display: inline-block;
}
.activity-stream .activity-time {
  font-size: 12px;
  line-height: 32px;
  position: relative;
  top: -10px;
  display: none;
}
.activity-stream .icons {
  height: 32px;
  width: 32px;
  position: relative;
}
.activity-stream .type-icon {
  display: none;
}
.activity-stream .user-image {
  height: 25px;
  width: 25px;
  position: relative;
  top: 0;
  right: -4px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}
.activity-stream .user-image img {
  width: 100%;
}
.activity-stream .marker {
  left: 15px;
  display: block;
  position: absolute;
  height: 300px;
  background: #eee;
  width: 2px;
}
.activity-stream li:last-child .marker {
  display: none !important;
}
.activity-task .type-icon {
  background: #A0B0BD;
}
.activity-invoice .type-icon {
  background: #DFF4F5;
}
.activity-invoice .type-icon span {
  color: #22CEE6 !important;
}
.activity-estimate .type-icon {
  background: #6FBC5F;
}
.activity-payment .type-icon {
  background: #F0E243;
}
.activity-message .type-icon {
  background: #3B7FCC;
}
.activity-file .type-icon {
  background: #A9CC9A;
}
.activity-project .type-icon {
  background: #ccc;
}
.activity-description {
  color: #334455;
}
.activity-title {
  color: #a2adb8;
}
.activity-title a {
  color: #a2adb8;
}
.activity-file .object-link {
  padding-left: 20px;
  /* background-image: url(../images/activity/file.png); */
}
.activity-file.activity-deleted {
  padding-left: 0;
  background: none;
}
.activity-message .object-link {
  padding: 10px 20px;
  background: #f7f7f7;
  border: 1px dashed #e0e0e0;
  border-radius: 5px;
  color: #666;
}
.activity-task .activity-title {
  padding-left: 20px;
  /* background: url(../images/tasks/task-incomplete.png) no-repeat 0 5px; */
}
.activity-task.activity-completed .activity-title {
  /* background: url(../images/tasks/task-complete.png) no-repeat 0 5px; */
}
.activity-task.activity-completed .activity-title a {
  text-decoration: line-through;
  color: #91a2b1;
}
.activity-task.activity-deleted .activity-title {
  background: none;
  padding-left: 0;
}
.activity-deleted a.object-link {
  display: none;
}
.activity-deleted .deleted-object {
  display: inline-block;
}
#dashboard {
  height: 100%;
  margin: 0;
}
#dashboard h3 {
  font-weight: normal;
}
#dashboard h4 {
  color: #9A9A9A;
  margin-top: 30px;
  margin-left: 18px;
}
#dashboard #dashboard-right-column {
  width: 480px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fdfdfd;
  box-shadow: inset 3px 0 3px rgba(0, 0, 0, 0.02);
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  padding: 20px 0 0 40px;
  position: absolute;
  top: 0;
  right: 0;
}
#dashboard #dashboard-left-column {
  width: 100%;
  height: 100%;
  padding: 0 480px 20px 0;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}
#dashboard-widgets {
  margin-top: 40px;
  margin-bottom: 50px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
  font-size: 0;
}
.dashboard-widget {
  background: #fff;
  display: inline-block;
  width: 33.33%;
  margin: 0;
  text-align: center;
  padding: 40px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.dashboard-widget h5 {
  color: #888;
  text-transform: uppercase;
  padding-bottom: 6px;
  border-bottom: 1px solid #d0d0d0;
  display: inline-block;
}
.dashboard-widget h2 {
  letter-spacing: 1px;
  font-size: 40px;
  font-weight: 600;
  color: #111;
}
.dashboard-widget .currency-symbol {
  font-size: 16px;
  vertical-align: top;
  position: relative;
  top: 5px;
}
.project-tile-list {
  margin: 0;
  list-style: none;
}
.project-tile-list .project-widget {
  float: left;
  overflow: hidden;
  cursor: pointer;
  width: 30%;
  height: 220px;
  padding: 4.5% 4.5% 39px 4.5%;
  border: 1px solid #ccc;
  margin: 20px 4.5% 20px 0;
  position: relative;
}
.project-tile-list .project-widget:nth-child(3n) {
  margin-right: 0;
}
.project-tile-list .project-widget .project-title {
  margin-bottom: 20px;
  float: left;
  clear: both;
  width: 100%;
}
.project-tile-list .project-widget:hover {
  background-color: #fffff6;
}
.project-tile-list .project-widget h3 {
  font-weight: normal;
  font-size: 18px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.project-tile-list .project-widget h6 {
  font-size: 11px;
  color: #999999;
  text-transform: uppercase;
}
.project-tile-list .project-widget .status {
  color: #fff;
  display: inline-block;
  font-weight: bold;
  margin-right: 5px;
  background-color: #4da6ff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  top: 4px;
}
.project-tile-list .project-widget.at-risk .status {
  background-color: #fff3a3;
}
.project-tile-list .project-widget.behind-schedule .status,
.project-tile-list .project-widget.overdue .status {
  background-color: #E96267;
}
.project-tile-list .project-widget p {
  margin-bottom: 5px;
  font-size: 12px;
  color: #888;
}
.project-tile-list .project-widget p span {
  float: right;
}
.project-tile-list .project-widget .progress-difference {
  float: right;
  font-weight: bold;
  letter-spacing: 1px;
}
.project-tile-list .project-widget .bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #666;
  font-size: 12px;
  padding: 10px 20px;
  background-color: #fafafa;
  border-top: 1px solid #eee;
  width: 100%;
}
.project-line-item-list {
  margin: 20px 0 0 0;
  width: 100%;
  list-style: none;
}
.project-line-item-list:before,
.project-line-item-list:after {
  content: " ";
  display: table;
}
.project-line-item-list:after {
  clear: both;
}
.project-line-item-list .project-widget {
  width: 100%;
  padding: 10px 10px 24px 10px;
  border-bottom: 1px solid #ddd;
}
.project-line-item-list .project-widget:first-child {
  border-top: 1px solid #ddd;
}
.project-line-item-list .project-widget:nth-child(even) {
  background-color: #fcfcfc;
}
.project-line-item-list h4 {
  display: inline-block;
  margin: 0 0 10px 0;
}
.project-line-item-list h4.client-name {
  color: #888;
  margin-left: 20px;
  font-size: 90%;
}
.project-line-item-list .progress-status {
  width: 70%;
  float: left;
  padding-right: 20px;
}
.project-line-item-list .due-date {
  width: 30%;
  float: left;
  text-align: right;
}
.project-line-item-list .project-title {
  margin-bottom: 0px;
}
.project-line-item-list .bottom {
  padding: 0;
  font-size: 12px;
}
.project-line-item-list .status {
  width: 8px;
  height: 8px;
  position: relative;
  top: 4px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #4da6ff;
}
.project-line-item-list.at-risk .status {
  background-color: #fff3a3;
}
.project-line-item-list.behind-schedule .status,
.project-line-item-list.overdue .status {
  background-color: #E96267;
}
.project-line-item-list li {
  cursor: pointer;
}
.project-line-item-list li:hover {
  background: #ebf2fb;
}
input.task-weight,
.modal input.task-weight {
  width: 100px;
}
.loading-maximum-weight {
  display: inline-block;
  height: 16px;
  width: 16px;
  /* background: transparent url(../images/tasks/task-saving.gif) no-repeat center center; */
}
.notification {
  text-align: left;
  width: 320px;
  position: fixed;
  right: 20px;
  top: 20px;
  display: inline-block;
  padding: 20px 20px 20px 100px !important;
  min-height: 100px;
  z-index: 9999999;
  overflow: hidden;
  border-radius: 3px;
  display: none;
  background: #fff;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 80px;
  color: #333;
}
.notification.showing {
  display: block;
}
.notification .notification-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  /* background: #FDD92F url('../images/Info.svg') no-repeat center center; */
  background-size: 40px;
}
.notification .button {
  colog: fff;
}
.notification .close {
  height: 12px;
  width: 12px;
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.notification .close:after {
  content: 'x';
  height: 12px;
  width: 12px;
  display: block;
  position: absolute;
  top: -4px;
  left: 0;
  color: #999;
}
.notification .close:hover:after {
  color: #444;
}
.notification .button {
  border: none;
  background: transparent;
  font-size: 13px;
  float: right;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
}
.notification.error {
  border: 1px solid #eed3d7;
  background: #f2dede;
  color: #b94a48;
  padding: 5px 10px;
  border-radius: 3px;
}
.full-width-notification,
.banner-notification {
  padding: 20px 40px;
  background-color: #fff;
  color: #2b6a94;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}
.full-width-notification .notification-content,
.banner-notification .notification-content {
  max-width: 800px;
  margin: 0 auto;
  color: #000;
}
.full-width-notification .notification-buttons,
.banner-notification .notification-buttons,
.full-width-notification .link-buttons,
.banner-notification .link-buttons {
  text-align: right;
  display: inline-block;
  float: right;
  margin-top: -3px;
}
.full-width-notification .notification-buttons .main-action,
.banner-notification .notification-buttons .main-action,
.full-width-notification .link-buttons .main-action,
.banner-notification .link-buttons .main-action {
  background: #2d9ff3;
  color: #fff;
  box-shadow: none;
  border: none;
}
.full-width-notification .notification-buttons .button,
.banner-notification .notification-buttons .button {
  background: none;
  border: none;
  color: #2b6a94;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
}
.full-width-notification .notification-buttons .button.main-action:hover,
.banner-notification .notification-buttons .button.main-action:hover {
  background: #552CF6;
  color: #fff;
}
.banner-notification {
  width: 800px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
#form-more {
  display: none;
  margin-top: 20px;
}
#add-client-user #hide-form-details,
#add-client #hide-form-details,
#edit-user #hide-form-details {
  display: none;
}
#add-client-user.showing-details #hide-form-details,
#add-client.showing-details #hide-form-details,
#edit-user.showing-details #hide-form-details {
  display: inline-block;
}
#add-client-user.showing-details #show-form-details,
#add-client.showing-details #show-form-details,
#edit-user.showing-details #show-form-details {
  display: none;
}
#add-client-user.showing-details #form-more,
#add-client.showing-details #form-more,
#edit-user.showing-details #form-more {
  display: block;
}
#global-search-results .search-entities {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
#global-search-results .search-entity {
  margin: 40px 0;
  padding: 40px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
#global-search-results h6 {
  margin-bottom: 10px;
  font-size: 18px;
}
#global-search-results #search-message-results .list.condensed li > div {
  height: auto;
}
#global-search-results #search-message-results .list.condensed li {
  height: auto;
  line-height: 1.5;
  padding: 10px 0;
}
#global-search-results #search-message-results .list.condensed li:before,
#global-search-results #search-message-results .list.condensed li:after {
  content: " ";
  display: table;
}
#global-search-results #search-message-results .list.condensed li:after {
  clear: both;
}
#global-search-results .list li > div,
#global-search-results .list-main-header > div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#global-search-results .search-task-list .task-name {
  width: 50%;
}
#global-search-results .search-task-list .task-project-name {
  width: 30%;
}
#global-search-results .search-task-list .task-status {
  width: 10%;
}
#global-search-results .search-task-list .task-due-date {
  width: 10%;
}
#global-search-results .project-list .project-name {
  width: 80%;
}
#global-search-results .project-list .project-due-date {
  width: 10%;
}
#global-search-results .project-list .project-status {
  width: 10%;
}
#global-search-results .file-list .file-name {
  width: 50%;
}
#global-search-results .file-list .file-type {
  width: 10%;
}
#global-search-results .file-list .file-project-name {
  width: 40%;
}
#global-search-results .message-list p:first-child {
  margin-top: 5px;
}
#global-search-results #search-filters {
  float: right;
  margin-right: 10px;
}
#global-search-results #search-filters:before,
#global-search-results #search-filters:after {
  content: " ";
  display: table;
}
#global-search-results #search-filters:after {
  clear: both;
}
.no-search-results {
  float: left;
  background: #fafafa;
  font-style: italic;
  font-size: 16px;
  text-align: center;
  padding: 20px;
  width: 100%;
  margin-top: 10px;
}
#forgot-password {
  height: 100%;
  background: #f7f8fa;
}
#forgot-password:before,
#forgot-password:after {
  content: " ";
  display: table;
}
#forgot-password:after {
  clear: both;
}
#forgot-password #temp-password-message {
  display: none;
}
#forgot-password form,
#forgot-password #temp-password-message {
  width: 400px;
  padding: 40px;
  margin: 100px auto 0 auto;
  background: #fff;
  border: 1px solid #ccc;
  -webkit-box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.07);
}
#forgot-password form:before,
#forgot-password #temp-password-message:before,
#forgot-password form:after,
#forgot-password #temp-password-message:after {
  content: " ";
  display: table;
}
#forgot-password form:after,
#forgot-password #temp-password-message:after {
  clear: both;
}
#forgot-password.submitted #temp-password-message {
  display: block;
}
#forgot-password.submitted form {
  display: none;
}
#forgot-password p {
  margin-bottom: 0;
}
#forgot-password input[type=text] {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: inset 0px 0px 0px 1px rgba(43, 50, 57, 0.21), inset 0 5px 3px -2px rgba(43, 50, 57, 0.04), 0 0 4px 1px rgba(69, 140, 207, 0);
  -webkit-appearance: none;
  border: 0;
  display: inline-block;
  color: #4e5b68;
  padding: 10px 10px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  -webkit-transition: box-shadow 100ms ease;
  transition: box-shadow 100ms ease;
  padding: 15px 13px;
  font-size: 17px;
  line-height: 22px;
  padding-left: 20px;
  margin: 20px auto;
  float: left;
}
#forgot-password input[type=text]:focus {
  outline: none !important;
}
#forgot-password h4 {
  margin-bottom: 20px;
}
#forgot-password .error {
  display: block;
  clear: both;
  font-size: 11px;
  color: #ff0000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#project-form .inline {
  width: 176px;
}
.panel-loading,
.panel-no-selection {
  text-align: center;
}
#panel-one {
  position: relative;
}
#panel-one .notification {
  position: absolute;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  text-align: center;
  background-color: #fcfcfc;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #ddd;
  border-radius: 0 0 4px 4px;
}
#reporting h1 {
  font-size: 50px;
  line-height: 40px;
  letter-spacing: -1px;
}
#reporting h1 span {
  font-size: 16px;
  letter-spacing: 0px;
}
#reporting h6 {
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 14px;
}
#reporting-text-widgets {
  width: 300px;
  float: left;
  margin-right: 40px;
  height: 380px;
  background-color: #fdfdfd;
}
#reporting-text-widgets:hover {
  background-color: #fafafa;
}
.reporting-change {
  padding: 0 40px 20px 40px;
  line-height: 12px;
}
#reporting-this-year {
  width: 100%;
  float: left;
  clear: both;
  padding: 20px;
}
#reporting-this-year:before,
#reporting-this-year:after {
  content: " ";
  display: table;
}
#reporting-this-year:after {
  clear: both;
}
#reporting-this-year h6 {
  margin: 0 0 0 20px;
}
#reporting-client-revenue {
  padding: 20px;
  float: left;
  height: 380px;
}
#reporting-client-revenue:before,
#reporting-client-revenue:after {
  content: " ";
  display: table;
}
#reporting-client-revenue:after {
  clear: both;
}
#reporting-client-revenue h6 {
  margin-bottom: 0;
}
#client-revenue-chart {
  width: 300px;
  height: 300px;
  float: left;
  margin-right: 40px;
}
#revenue-chart {
  height: 300px;
}
#reporting-payments-list {
  width: 600px;
  height: 300px;
  float: left;
  padding: 20px;
  margin-bottom: 60px;
}
#reporting-payments-list h6 {
  margin-left: 20px;
}
#reporting-payments-list .nano:after {
  background-image: none;
}
#reporting-payments-list th {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#reporting-payments-list ul div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#reporting-payments-list ul {
  width: 100%;
}
#reporting-payments-list ul:before,
#reporting-payments-list ul:after {
  content: " ";
  display: table;
}
#reporting-payments-list ul:after {
  clear: both;
}
#reporting-payments-list li {
  padding: 0;
}
#reporting-payments-list li.end {
  height: 25px;
  line-height: 25px;
  background-color: #fafafa;
  text-align: center;
}
#reporting-payments-list .payment-date {
  width: 20%;
}
#reporting-payments-list .payment-amount {
  width: 20%;
}
#reporting-payments-list .payment-client {
  width: 43%;
}
#reporting-payments-list .payment-invoice {
  width: 17%;
}
#admin-customize .settings-section > div {
  border-bottom: 1px solid #ccc;
  padding: 40px;
  width: 100%;
}
#admin-customize #customize-theme .select2 {
  width: 200px;
  margin-bottom: 20px;
}
#admin-customize #customize-theme .button {
  float: none;
}
#admin-settings h4 {
  margin-bottom: 10px;
}
#admin-settings .edit-setting {
  font-size: 12px;
  margin-left: 20px;
  color: #22f;
  cursor: pointer;
  display: none;
}
#admin-settings li:hover .edit-setting {
  display: inline;
}
#admin-settings .sign-up-type button {
  margin-top: 20px;
}
#admin-settings .sign-up-type label {
  padding-right: 20px;
}
.running-timer {
  width: 100%;
  float: left;
  padding: 0 10px;
  background: rgba(54, 135, 221, 0.9);
  cursor: pointer;
  color: #fff;
  padding: 10px;
}
.running-timer .timer-task {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 10px 0;
  width: 100%;
  margin-right: 40px;
}
.running-timer .timer-elapsed {
  font-size: 20px;
  width: 50%;
  float: left;
}
.running-timer .timer-stop {
  height: 15px;
  width: 15px;
  background: rgba(255, 255, 255, 0.8);
  margin: 4px 5px;
  float: right;
}
.running-timer .timer-stop:hover {
  background: #ffffff;
}
.running-timer-inner {
  float: left;
  position: relative;
  width: 100%;
}
.running-timer-inner > div {
  line-height: 1;
  display: inline-block;
}
.panel-filter-wrapper {
  display: inline-block;
}
.panel-filter {
  display: inline-block;
  cursor: pointer;
  position: relative;
  color: #999;
}
.panel-filter .panel-selected-filter {
  padding: 2px 19px 2px 10px;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;
}
.panel-filter:hover,
.panel-filter.active {
  color: #444;
}
.panel-filter:hover .panel-selected-filter,
.panel-filter.active .panel-selected-filter {
  border: 1px solid #ccc;
  background: #fff;
}
.panel-filter:hover .caret,
.panel-filter.active .caret {
  display: inline-block;
}
.panel-filter.active .panel-selected-filter {
  border-bottom-width: 0;
}
.panel-filter.active ul {
  display: block;
}
.panel-filter ul {
  list-style: none;
  display: none;
  position: absolute;
  border: 1px solid #ccc;
  top: 24px;
  left: 0;
  margin: 0;
  min-width: 100%;
  z-index: 0;
  background: #fff;
  font-size: 15px;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.panel-filter li {
  padding: 5px 15px;
  white-space: nowrap;
  color: #333;
}
.panel-filter li:first-child {
  border-top: none;
}
.panel-filter li:hover {
  background-color: #f2f4f8 !important;
  color: #181a1c;
}
.panel-filter li.selected {
  font-weight: bold;
}
.panel-filter .caret {
  display: none;
  position: absolute;
  right: 5px;
  top: 9px;
}
.panel-sort-wrapper {
  display: inline-block;
}
.panel-sort-wrapper .ascending .arrow-up {
  border-bottom-color: #666;
}
.panel-sort-wrapper .descending .arrow-down {
  border-top-color: #666;
}
.panel-sort {
  cursor: pointer;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 4px solid #aaa;
  margin-bottom: 2px;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #aaa;
}
@media all and (max-width: 1500px) {
  .panel-filter .panel-selected-filter {
    padding: 2px 12px 2px 5px;
  }
  .panel-filter .panel-selected-filter .caret {
    border-top: 3px solid #000000;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
    right: 3px;
    top: 11px;
  }
  .selected-filter-name {
    font-size: 80%;
  }
  .panel-info .panel-actions-wrapper .panel-action {
    min-width: 34px;
  }
  .panel-info h3 {
    font-size: 18px;
  }
}
#app-loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  color: #2381ef;
  font-size: 30px;
  text-align: center;
  z-index: 999999;
}
#app-loading-screen section {
  vertical-align: middle;
  text-align: center;
  margin: 100px auto;
}
/*	$Loader Quadrant
	========================================================================== */
.loader-quart {
  border-radius: 65px;
  border: 6px solid rgba(35, 129, 239, 0.25);
  display: inline-block;
  position: relative;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.loader-quart:after {
  content: '';
  position: absolute;
  top: -6px;
  left: -6px;
  bottom: -6px;
  right: -6px;
  border-radius: 65px;
  border: 6px solid rgba(35, 129, 239, 0.25);
  border-top-color: #2381ef;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.loader-text {
  font-size: 18px;
  position: relative;
  top: 34px;
}
.smart-menu {
  display: table;
}
.smart-menu ul {
  display: table-cell;
  margin: 0;
  vertical-align: middle;
}
.smart-menu li {
  list-style: none;
  cursor: pointer;
}
.smart-menu .smart-menu-hover:hover {
  color: #2381e9;
}
.smart-menu .smart-menu-items li {
  display: inline-block;
  padding: 10px 0;
  margin-right: 15px;
  line-height: 24px;
}
.smart-menu .smart-menu-items li:hover {
  color: #2381e9;
}
.smart-menu .smart-menu-items li.selected,
.smart-menu .smart-menu-items li:hover {
  color: #595959;
}
.smart-menu .smart-menu-items li.selected {
  font-weight: bold;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #f5f5f5;
  padding: 10px 12px;
}
.smart-menu .more {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  line-height: 24px;
  cursor: pointer;
  padding: 0 20px;
}
.smart-menu .sidebar-icon {
  font-size: 12px;
}
.smart-menu .smart-menu-overflow {
  list-style: none;
  display: none;
  position: absolute;
  border: 1px solid #ccc;
  top: 30px;
  left: 0;
  margin: 0;
  z-index: 0;
  background: #fff;
  font-size: 15px;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 100px;
}
.smart-menu .smart-menu-overflow.active {
  display: block;
}
.smart-menu .smart-menu-overflow li {
  padding: 5px 15px;
  white-space: nowrap;
  color: #333;
}
.smart-menu .smart-menu-overflow li:first-child {
  border-top: none;
}
.smart-menu .smart-menu-overflow li:hover {
  background-color: #f2f4f8 !important;
  color: #181a1c;
}
.smart-menu .smart-menu-overflow li.selected {
  font-weight: bold;
}
.smart-menu .smart-menu-overflow li.has-button {
  padding: 0;
}
.smart-menu .smart-menu-overflow li.has-button .button {
  display: block;
  padding: 10px 15px;
  margin: 0 !important;
}
.smart-menu .smart-menu-overflow li.has-button .button.danger:hover {
  background: #ff4f59;
  color: #fff;
}
.smart-menu .more .button {
  border: none !important;
  box-shadow: none !important;
  float: none;
  padding: 0;
  background: none;
}
.smart-menu .smart-menu-menu-text,
.smart-menu .menu-icon {
  display: none;
}
.smart-menu.showing-none .smart-menu-menu-text,
.smart-menu.showing-none .menu-icon {
  display: inline;
}
.smart-menu.showing-none .smart-menu-more-text,
.smart-menu.showing-none .more-icon {
  display: none;
}
.dropdown-button {
  display: inline-block;
  position: relative;
}
.dropdown-button.loading .main-button {
  padding-right: 30px;
  /* background-image: url(../images/button-loader.gif); */
  background-position: 92% center;
  background-repeat: no-repeat;
}
.dropdown-button.show-indicator .main-button {
  padding-left: 29px;
}
.dropdown-button.show-indicator .main-button:before {
  font-family: 'themify';
  content: "\e64b";
  position: absolute;
  top: 1px;
  left: 9px;
  z-index: 9999;
}
.dropdown-button .has-icon {
  padding-left: 35px;
}
.dropdown-button .has-icon:before {
  top: 10px;
}
#kanban-view:before {
  font-family: 'themify';
  content: "\e712";
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 9999;
}
#list-view:before {
  font-family: 'themify';
  content: "\e667";
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 9999;
}
.dropdown-button-dropdown {
  list-style: none;
  display: none;
  position: absolute;
  border: 1px solid #ccc;
  top: 100%;
  right: 0;
  margin: 0;
  z-index: 0;
  background: #fff;
  font-size: 15px;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 100px;
}
.dropdown-button-dropdown.active {
  display: block;
  z-index: 999999;
}
.dropdown-button-dropdown .button {
  margin-right: 0;
}
.dropdown-button-dropdown li {
  padding: 10px 15px;
  white-space: nowrap;
  color: #333;
  line-height: 24px;
  cursor: pointer;
  position: relative;
}
.dropdown-button-dropdown li:first-child {
  border-top: none;
}
.dropdown-button-dropdown li:hover {
  background-color: #f2f4f8;
  color: #181a1c;
}
.dropdown-button-dropdown li.selected {
  font-weight: bold;
}
.dropdown-button-dropdown li.danger {
  background: transparent;
  color: #333;
  box-shadow: none;
  border: none;
}
.dropdown-button-dropdown li.danger:hover {
  background: #ff4f59;
  color: #fff;
}
#modules-to-hide:before,
#modules-to-hide:after {
  content: " ";
  display: table;
}
#modules-to-hide:after {
  clear: both;
}
#modules-to-hide label {
  width: 33%;
  float: left;
  margin-bottom: 20px;
}
#custom-css-textarea {
  width: 100%;
  border-radius: 0;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 10px;
  background: #fcfdff;
  color: #111;
}
.settings-section p {
  max-width: 65rem;
  margin-bottom: 20px;
}
.setting-section-header {
  text-transform: uppercase;
  font-size: 90%;
  color: #777;
}
.setting-section-values {
  margin-bottom: 30px;
  list-style: none;
}
#admin-settings {
  max-width: 1000px;
  margin: 10px auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.admin-section {
  display: none;
  padding: 20px 0;
}
.admin-section.active {
  display: block;
}
.admin-section form {
  margin-right: 5%;
  margin-top: 20px;
}
.admin-section .field {
  margin-bottom: 20px;
}
.admin-section .error {
  display: block;
  clear: both;
  font-size: 11px;
  color: #ff0000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.admin-section h5 {
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 5px;
}
.social-shares-wrapper h4,
.referral-share-wrapper h4 {
  color: #000;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}
.social-shares-wrapper label,
.referral-share-wrapper label {
  display: block;
  font-weight: bold;
  color: #000;
  margin-bottom: 5px;
}
.social-shares-wrapper input,
.referral-share-wrapper input,
.social-shares-wrapper textarea,
.referral-share-wrapper textarea,
.social-shares-wrapper label,
.referral-share-wrapper label,
.social-shares-wrapper form,
.referral-share-wrapper form {
  max-width: 600px;
  width: 100%;
}
.social-shares-wrapper input[type=submit],
.referral-share-wrapper input[type=submit] {
  padding: 0 !important;
}
.social-shares-wrapper input,
.referral-share-wrapper input {
  line-height: 40px;
  height: 40px;
}
#admin-feedback > div {
  float: left;
  width: 100%;
}
#social-shares a {
  padding: 8px 20px;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  margin: 10px 0;
  text-decoration: none;
}
#social-shares .share-option {
  display: inline-block;
}
#social-shares .share-icon {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
#social-shares #facebook-button {
  background-color: #4e69a2;
}
#social-shares #facebook-button:hover {
  background-color: #6391dd;
}
#social-shares #twitter-button {
  background: #55acee;
}
#social-shares #twitter-button:hover {
  background: #4b97cf;
}
#social-shares #linkedin-button {
  color: #4875b4;
  background-color: #eaeaea;
}
#social-shares #linkedin-button:hover {
  background-color: #c6c6c6;
}
#social-shares #google-button {
  background-color: #dd4b39;
  color: #fff;
}
#social-shares #google-button:hover {
  background-color: #cd4937;
}
.customize-section {
  border-bottom: 1px solid #eee;
  padding-bottom: 60px;
  padding-top: 60px;
}
.customize-section .button {
  border: 1px solid #cecece !important;
  float: left;
}
.customize-section .remove-logo {
  display: none;
}
.customize-section .image-size {
  display: none;
}
.customize-section.has-logo .remove-logo {
  display: inline-block;
}
.customize-section.has-logo .image-size {
  display: inline-block;
}
.customize-section.has-logo .button-wrapper {
  display: none;
}
.customize-section .title {
  width: 400px;
  display: inline-block;
  vertical-align: top;
}
.customize-section .current-logo {
  display: inline-block;
  margin-left: 100px;
}
.customize-section .image-wrapper {
  max-width: 300px;
  height: 100px;
}
.customize-section .image-wrapper img {
  max-width: 100%;
  max-height: 100%;
}
#admin-feedback p {
  width: 70%;
  margin-right: 30%;
}
.person-info {
  width: 140px;
  height: 167px;
  float: left;
  margin-right: 20px;
  text-align: center;
  list-style: none;
  color: #fff;
  padding: 20px;
  cursor: pointer;
  border-radius: 4px;
}
.person-info a {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  position: relative;
}
.person-info a span {
  display: block;
}
.person-info:hover {
  background: #ebf2fb;
}
.person-info:hover .person-name,
.person-info:hover .person-role {
  color: #328ff1;
}
.person-info .person-image {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  background-position: center center;
  overflow: hidden;
}
.person-info .person-image img {
  width: 100%;
}
.person-info .person-name {
  color: #444;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.person-info .person-role {
  color: #aaa;
  text-transform: uppercase;
  font-size: 12px;
}
.person-info .remove-user {
  position: absolute;
  text-align: center;
  width: 80px;
  left: 50%;
  margin-left: -40px;
  background: #f2dede;
  color: #a94442;
  border-radius: 4px;
  border: 1px solid #ebccd1;
  bottom: -30px;
  display: none;
}
.person-info .remove-user:hover {
  background-color: #eacbce;
  border-color: #eba4a4;
  color: #943c3a;
}
.person-info:hover .remove-user {
  display: block;
}
#add-person-to-project {
  width: 378px;
}
#choose-new-user-type {
  width: 478px;
}
#choose-new-user-type .new-user-type {
  position: relative;
  border: 1px solid transparent;
  padding: 20px;
  cursor: pointer;
}
#choose-new-user-type .new-user-type:hover {
  background: #ebf2fb;
}
#choose-new-user-type .new-user-type:hover .icon {
  color: #328ff1;
  border-color: #328ff1;
}
#choose-new-user-type h5 {
  font-weight: bold;
}
#choose-new-user-type .icon {
  font-size: 17px;
  padding: 10px;
  border: 1px solid #aaa;
  color: #aaa;
  border-radius: 25px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 20px;
}
#choose-new-user-type .user-type-description {
  padding-left: 50px;
}
form#add-client,
#add-user-to-project-step-one {
  width: 378px;
}
#add-user-to-project-step-one {
  width: 378px;
}
#add-user-to-project-step-one h5 {
  font-weight: bold;
}
#add-user-to-project-step-one > p {
  margin-top: 20px;
  float: left;
  width: 100%;
  clear: both;
}
#add-user-to-project-step-one .new-user-type {
  position: relative;
  border: 1px solid transparent;
  padding: 20px;
  cursor: pointer;
}
#add-user-to-project-step-one .new-user-type:hover {
  background: #ebf2fb;
}
#add-user-to-project-step-one .new-user-type:hover .icon {
  color: #328ff1;
  border-color: #328ff1;
}
#add-user-to-project-step-one h5 {
  font-weight: bold;
}
#add-user-to-project-step-one .icon {
  font-size: 17px;
  padding: 10px;
  border: 1px solid #aaa;
  color: #aaa;
  border-radius: 25px;
  position: absolute;
  top: 30px;
  left: 20px;
}
#add-user-to-project-step-one .user-type-description {
  padding-left: 50px;
}
.user-quick-access-panel {
  width: 300px;
  height: 100%;
  border-left: 1px solid #e1e6ef;
}
.user-quick-access-panel ul {
  list-style: none;
  margin-top: 20px;
}
.user-quick-access-panel li {
  margin-bottom: 10px;
}
.user-quick-access-panel .icon {
  margin-right: 10px;
}
.user-quick-access-panel .quick-access-top {
  background: #fff;
  text-align: center;
  border-bottom: 1px solid #e1e6ef;
}
.user-quick-access-panel .quick-access-top,
.user-quick-access-panel .quick-access-content {
  padding: 20px 40px;
}
.user-quick-access-panel .user-image {
  display: inline-block;
}
.user-quick-access-panel #quick-access-change-photo {
  padding: 0;
  display: inline;
  position: relative;
  left: -3px;
  color: #428bca;
}
.user-quick-access-panel #quick-access-change-photo:hover {
  color: #2a6496;
  text-decoration: underline;
}
.panel-categories {
  padding-left: 18px;
  border-left: 1px solid #f5f5f5;
  height: 60px;
}
.panel-categories li,
.panel-categories .more {
  color: #A0A1A2;
}
.panel-categories li:hover,
.panel-categories .more:hover {
  color: #6d6e6f;
}
.panel-categories li:last-child {
  border-right: none;
}
.inline-popup {
  font-size: 15px;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 100px;
  min-height: 50px;
  z-index: 999;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.inline-popup:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  top: 100%;
  left: 50%;
  margin-left: -10px;
}
.inline-popup:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 11px;
  border-style: solid;
  border-color: #ccc transparent transparent transparent;
  top: 100%;
  left: 50%;
  margin-left: -11px;
}
.inline-popup.bottom:before {
  top: 0%;
  margin-top: -22px;
  border-color: transparent transparent #ccc transparent;
}
.inline-popup.bottom:after {
  top: 0%;
  margin-top: -20px;
  border-color: transparent transparent #fff transparent;
}
.inline-popup {
  padding: 10px;
  opacity: 0;
}
.inline-popup.showing {
  opacity: 1;
}
.inline-popup input,
.inline-popup select,
.inline-popup textarea {
  padding: 0px 6px;
  display: block;
  margin: 0 0 7px 0;
}
.inline-popup .select2-container {
  margin-bottom: 10px;
}
.inline-popup input[type=text],
.inline-popup input[type=number],
.inline-popup input[type="date"],
.inline-popup input[type=password],
.inline-popup select {
  height: 12px;
}
.inline-popup select {
  width: 100%;
}
.inline-popup input[type=submit],
.inline-popup .button {
  padding: 2px 5px !important;
  font-size: 12px;
  background: #444;
  border: none;
  text-shadow: none;
  border-radius: 0;
  color: #fff;
  float: right;
}
.inline-popup .cancel {
  float: left;
  background: #ccc;
  color: #333;
}
#assign-tasks-form {
  width: 200px;
}
#assign-tasks-form select,
#assign-tasks-form .select2 {
  width: 100%;
}
#start-timer-popup {
  width: 160px;
}
#new-task-form .notes-wrapper {
  display: none;
}
#new-task-form .clickable {
  display: block;
  color: #2381e9;
  margin-bottom: 15px;
}
#new-task-form .clickable:hover {
  text-decoration: underline;
}
#panel-two .entity-list {
  width: 100%;
}
@media (max-width: 800px) {
  .unfloat-on-mobile {
    float: left !important;
    width: 100% !important;
  }
  .invoice-wrapper {
    padding-right: 0 !important;
  }
  .invoice-meta-wrapper {
    position: relative !important;
  }
  .task-list li {
    padding-right: 150px !important;
  }
  .task-list li .task-assigned {
    right: 5px !important;
  }
  .task-list li .task-actions,
  .task-list li .task-meta {
    display: none !important;
  }
  .client-task-list .task-details {
    padding-right: 150px !important;
  }
  .client-task-list .task-details .task-assigned {
    right: 5px !important;
  }
  .file-list .file-extension {
    width: 20%;
  }
  .file-list .file-title {
    width: 60%;
  }
  .file-list .file-type {
    width: 20%;
  }
  .file-list .file-uploader-name {
    width: 0%;
    display: none;
  }
  .file-list .file-create-date {
    width: 0%;
    display: none;
  }
  .invoice {
    padding: 20px;
  }
  .invoice li {
    font-size: 89%;
  }
  #tasks-list .task-due-date {
    display: none !important;
  }
  #dashboard-project-list {
    border-right: none !important;
  }
  #project-details #top-details {
    height: auto;
  }
  #project-details #top-details div {
    padding-top: 10px;
    width: 50% !important;
    text-align: left;
  }
  #project-details #top-details h5 {
    font-size: 14px;
    margin-bottom: 0;
  }
  #project-details #top-details h3 {
    font-size: 16px !important;
  }
}
@media (max-width: 500px) {
  .full .window-outer {
    padding-left: 0px;
    min-width: 200px;
  }
  .user-details {
    width: auto;
  }
  .panel .inner-content > div {
    margin-left: 20px;
  }
  .panel-info .panel-actions-wrapper {
    right: 0;
  }
  .panel-info h3 {
    padding-right: 0px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .panel-info .panel-left {
    width: 100%;
  }
  .smart-menu .more {
    padding: 0;
  }
  .panel-categories {
    padding-left: 0;
  }
  .user-quick-access-panel {
    width: 200px;
  }
  .quick-access-content {
    padding: 20px !important;
  }
  .entity-list {
    width: 200px;
  }
  .entity-list-header {
    padding-left: 40px;
  }
  .entity-list-title {
    font-size: 16px;
  }
  .entity-actions {
    display: none !important;
  }
  #discuss-project-button .discuss-button-text {
    display: none;
  }
  #project-details #top-details {
    margin-right: 0;
  }
  #project-details #top-details > div {
    padding-right: 0px;
  }
  #project-calendar {
    display: none;
  }
  .new-entity {
    display: none;
  }
  .item-details,
  .item-details-inner {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .invoice-list .invoice-number,
  .invoice-list .invoice-balance,
  .invoice-list .invoice-status {
    width: 33% !important;
  }
  .invoice-list .invoice-due-date,
  .invoice-list .invoice-total {
    width: 0%;
    display: none;
  }
  .invoice-list .status-text {
    border: none !important;
    padding: 0 !important;
    background-color: #fff !important;
  }
  .invoice-wrapper {
    padding: 0 !important;
  }
  .invoice {
    padding: 10px !important;
  }
  .invoice li div {
    padding: 4px 1px;
  }
  .invoice .invoice-summary {
    width: 100% !important;
  }
  .invoice .invoice-terms {
    display: none;
  }
  .invoice .invoice-to {
    display: none;
  }
  .invoice .status-text {
    border: none !important;
    padding: 0 !important;
    background-color: #fff !important;
  }
  .invoice.preview .invoice-client {
    display: none;
  }
  .invoice.preview .invoice-meta,
  .invoice.preview .invoice-due-date {
    width: 48%;
  }
  #attach-files-button-wrapper,
  .add-file-button {
    display: none !important;
  }
  .file-list span {
    font-size: 10px;
    padding: 2px 5px;
  }
  #file-view-type {
    display: none;
  }
  .button {
    font-size: 12px;
  }
  .client-task-list .task-due-date {
    display: none !important;
  }
  .dashboard-invoices {
    display: none;
  }
  .invoices-tabs {
    display: none !important;
  }
  .invoices-tab {
    padding: 10px !important;
  }
  .invoices-tab h2 {
    font-size: 20px;
  }
  .invoices-tab h5 {
    font-size: 11px;
  }
  #dashboard-project-list {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .dashboard-projects {
    padding-right: 20px !important;
  }
  .project-tile-list .project-widget {
    width: 100%;
    height: 120px;
  }
  .modal {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
  }
  form {
    max-width: inherit !important;
  }
  #reporting-tab {
    display: none;
  }
  .client-details .user-email {
    display: none;
    width: 0;
  }
  .client-details .user-name {
    width: 70% !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .client-details .user-image-container {
    width: 30% !important;
  }
}
#make-recurring-form {
  max-width: 800px;
  margin: 0 auto;
  paddding: 40px;
}
#make-recurring-form .inline {
  width: 45%;
  margin-right: 9%;
}
#make-recurring-form .inline:last-child {
  margin-right: 0;
}
#make-recurring-form #custom-frequency {
  display: none;
}
#make-recurring-form #recurrence-frequency[data-selection=custom] #custom-frequency {
  display: block;
}
#link-body {
  margin: 0 auto;
  background: #F4F6F9;
  height: 100%;
  overflow: auto;
}
#link-view {
  height: 100%;
}
#link-view.naked {
  overflow: hidden;
  height: auto;
}
#link-view.naked .full-width-notification {
  display: none;
}
#link-view.naked #link-body {
  background: #fff;
  height: auto;
}
#link-view.naked .invoice.preview {
  border: none;
  box-shadow: none;
  margin: 0;
  max-width: 100%;
}
#link-view.naked .corner {
  display: none;
}
/* Make clicks pass-through */
#top-progress-bar {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}
#top-progress-bar.showing {
  opacity: 1;
  visibility: visible;
}
#top-progress-bar .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transition: all 200ms ease;
}
/* Fancy blur effect */
#top-progress-bar .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
/* Remove these to get rid of the spinner */
#top-progress-bar .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#top-progress-bar .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -webkit-animation: top-progress-bar-spinner 400ms linear infinite;
  animation: top-progress-bar-spinner 400ms linear infinite;
}
.top-progress-bar-custom-parent {
  overflow: hidden;
  position: relative;
}
.top-progress-bar-custom-parent #top-progress-bar .spinner,
.top-progress-bar-custom-parent #top-progress-bar .bar {
  position: absolute;
}
@-webkit-keyframes top-progress-bar-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes top-progress-bar-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#login-screen,
#sign-up-screen {
  height: 100%;
  width: 100%;
  float: left;
}
#login-info,
#login-form {
  float: left;
  height: 100%;
}
#login-info {
  width: 60%;
  background: #26262E;
}
#login-info #login-logo {
  /* background: url(../images/duet-128.jpg) center center; */
  background-size: 40px;
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 0;
  top: 30px;
}
#login-info .welcome-text {
  width: 50%;
  max-width: 500px;
  margin-left: 20%;
  display: table;
  height: 100%;
  position: relative;
}
#login-info .welcome-text-inner {
  display: table-cell;
  vertical-align: middle;
}
#login-info h3 {
  color: #fff;
  margin-bottom: 2.5rem;
}
#login-info p {
  color: #aaa;
  line-height: 1.5;
}
#login-info .powered-by {
  position: absolute;
  left: 0;
  bottom: 20px;
  background: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.8);
  padding: 4px 10px;
  border-radius: 4px;
}
.login-header,
.login-footer {
  color: #fff;
}
.login-header .content,
.login-footer .content {
  max-width: 800px;
  margin: 0 auto;
}
.login-header h3,
.login-footer h3 {
  font-weight: 700;
}
.login-header p,
.login-footer p {
  color: #ccc;
}
.login-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  text-align: right;
  width: 100%;
  padding: 10px 60px;
  border-bottom: 1px solid rgba(43, 50, 57, 0.15);
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
}
.login-header p {
  color: #333;
  display: inline-block;
  margin-right: 20px;
  font-size: 17px;
  color: #888;
}
#login-form {
  width: 100%;
  background: #F6F5F9;
  background-size: cover;
  position: relative;
  display: table;
  padding: 150px 0 100px 0;
}
#login-form.has-logo {
  padding-top: 100px;
}
#login-form #form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(38, 38, 46, 0.8);
  height: 100%;
  width: 100%;
  display: none;
}
#login-form .form-content {
  position: relative;
  z-index: 1;
  padding: 40px 40px 75px 40px;
  margin: 0 auto;
  width: 400px;
}
.login-title {
  margin: 0 0 10px 0;
  color: #3D3D3D;
  font-weight: bold;
  text-align: center;
}
.login-subtitle {
  padding: 0;
  margin: 0px 5px 15px 0;
  font-size: 23px;
  line-height: 29px;
  text-align: center;
}
.login-window {
  max-width: 400px;
  margin: 0 auto 0 auto;
  position: relative;
}
.login-window h3,
.login-window p {
  font-family: Inter, Arial, sans-serif;
  font-weight: normal;
  text-align: center;
  z-index: 0;
}
.login-window p.message {
  display: none;
  margin: 10px 0 20px 0;
  color: #81898f !important;
  font-size: 15px;
  margin: 0;
}
.login-window form {
  position: relative;
  margin: 0 auto;
}
.login-window form > div {
  position: relative;
}
.login-window .login-fields {
  margin-bottom: 12px;
}
.login-window .login-fields > div {
  padding: 10px 0;
  position: relative;
}
.login-window [for=username] {
  background-position: -168px 0;
}
.login-window [for=password] {
  background-position: -287px -24px;
}
.login-window input[type=text],
.login-window input[type=password],
.login-window input[type=email],
.login-window .input {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: inset 0px 0px 0px 1px rgba(43, 50, 57, 0.21), inset 0 5px 3px -2px rgba(43, 50, 57, 0.04), 0 0 4px 1px rgba(69, 140, 207, 0);
  -webkit-appearance: none;
  border: 0;
  display: inline-block;
  color: #4e5b68;
  padding: 10px 10px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  -webkit-transition: box-shadow 100ms ease;
  transition: box-shadow 100ms ease;
  padding: 15px 13px;
  font-size: 17px;
  line-height: 22px;
}
.login-window input[type=text]:focus,
.login-window input[type=password]:focus,
.login-window input[type=email]:focus,
.login-window .input:focus {
  outline: none !important;
}
.login-window input:focus + label {
  opacity: 0.6;
}
.login-window button[type=submit] {
  border: 0;
  cursor: pointer;
  display: inline-block;
  background-color: #1e79f2;
  border-radius: 3px;
  color: #fff !important;
  text-decoration: none;
  text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.05);
  -webkit-font-smoothing: antialiased;
  -webkit-transition: background-color 100ms ease;
  transition: background-color 100ms ease;
  padding: 15px 18px !important;
  font-size: 18px;
  line-height: 21px;
}
.login-window button[type=submit] span {
  position: relative;
  top: 2px;
}
.login-window button[type=submit]:focus {
  outline: none;
}
.login-window .forgot-password {
  display: inline-block;
  font-size: 15px;
  color: rgba(43, 50, 57, 0.45);
  margin: 5px 0;
  text-decoration: none;
  border-bottom: 0px solid #4e5b68;
  -webkit-transition: color 100ms ease, border-bottom 100ms ease;
  transition: color 100ms ease, border-bottom 100ms ease;
  vertical-align: bottom;
  float: right;
}
.login-window .error-message {
  text-align: center;
  font-size: inherit;
}
.login-logo {
  height: 100px;
  max-width: 320px;
  text-align: center;
  margin-bottom: 40px;
}
.login-logo img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}
[data-type=cards] {
  height: 100%;
  margin-bottom: 0 !important;
  overflow-x: auto;
}
#card-view-incomplete {
  float: left;
  height: 100%;
}
#card-view[data-filter=all] #card-view-complete {
  box-shadow: inset 0 -3px 3px rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid #eee;
}
#card-view-complete {
  padding-bottom: 5px;
  margin-bottom: 30px;
  display: block;
}
#card-view-complete h5 {
  font-weight: bold;
  margin: 20px 0 10px 0;
}
#card-view-complete li {
  background: transparent;
}
#card-view {
  height: 100%;
  float: left;
}
#card-view .card-column {
  width: 300px;
  height: 100%;
  float: left;
  background: #fff;
}
#card-view .card-column-header {
  padding: 8px 8px 8px 12px;
  font-size: 18px;
  font-weight: normal;
  color: rgba(30, 43, 101, 0.93);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#card-view .ui-state-hover.no-cards .cards:after,
#card-view .card-placeholder,
#card-view .column-placeholder {
  content: ' ';
  height: 60px;
  background: #EEF0F5;
  margin: 15px;
  display: block;
  border: dashed 2px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}
#card-view .column-placeholder {
  height: 90%;
  width: 270px;
  float: left;
}
#card-view .card {
  padding: 15px 15px 15px 30px;
  margin: 15px;
  display: block;
  cursor: pointer;
  background: #fcfcfc;
  border: solid 1px rgba(0, 0, 0, 0.13);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  position: relative;
  overflow: hidden;
}
#card-view .card:after {
  content: ' ';
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0;
  top: 0;
}
#card-view .card.at-risk:after {
  background-color: #fff3a3;
}
#card-view .card.overdue:after {
  background-color: #E96267;
}
#card-view .card.complete .is-task-completed {
  /* background-image: url(../images/tasks/task-complete.gif) !important; */
  background-position: center center;
  background-size: 18px !important;
  border: none !important;
}
#card-view .card.complete .task-details {
  text-decoration: line-through;
  color: #888;
}
#card-view .card .task-status-indicator {
  height: 14px;
  width: 14px;
  top: 18px;
  left: 15px;
}
#card-view .card .user-image {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 12px;
  right: 12px;
}
#card-view .card .task-details {
  overflow: hidden;
  margin-right: 25px;
  margin-left: 12px;
}
#card-view .ui-sortable-helper {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
#card-view .card-column.ui-sortable-helper {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
#card-view .is-task-completed,
#card-view .retry-save,
#card-view .saving-indicator {
  position: absolute;
}
#card-view .is-task-completed {
  left: 0;
  height: 16px;
  width: 16px;
  background: none;
  border: 1px solid #888;
  border-radius: 3px;
}
#card-view .saving-indicator {
  left: 0px;
  height: 16px !important;
  width: 16px;
  /* background: transparent url(../images/tasks/task-saving.gif) no-repeat 0 0; */
  display: none;
}
#card-view .retry-save {
  height: 14px !important;
  width: 14px;
  margin: 10px 0 0 10px;
  left: 14px;
  /* background-image: url(../images/glyphicons-halflings.png); */
  background-repeat: no-repeat;
  background-position: 0 -120px;
  cursor: pointer;
  opacity: 0.5;
  display: none;
}
#card-view .saving .is-task-completed {
  display: none;
}
#card-view .saving .saving-indicator {
  display: block;
}
#card-view .saving .retry-save {
  display: none;
}
#card-view .error-saving .is-task-completed {
  display: none;
}
#card-view .error-saving .saving-indicator {
  display: none;
}
#card-view .error-saving .retry-save {
  display: block;
}
#card-view .new-card,
#card-view .new-section {
  margin: 15px;
}
#card-view .new-card input,
#card-view .new-section input {
  background-color: #fff;
  padding: 9px 13px;
  color: #a6a6a6;
  border-radius: 3px;
  border: 1px dashed #ccc;
  width: 100%;
}
#card-view .new-card input:focus,
#card-view .new-section input:focus {
  border: 1px solid #aaa;
  outline: none;
}
#card-view .new-section {
  width: 259px;
  float: left;
  margin-top: 0;
}
#card-view .new-section input {
  margin-top: 15px;
}
#card-view .cards {
  overflow-y: auto;
}
#card-view #complete-tasks-list .is-task-completed {
  /* background-image: url(../images/tasks/task-complete.gif) !important; */
  background-position: center center;
  background-size: 18px !important;
  border: none !important;
}
#reporting-main {
  position: relative;
}
#reporting-content {
  width: 100%;
  padding-right: 450px;
}
#reporting-filters {
  width: 450px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0 0 40px;
}
#reporting-filters h3,
#reporting-filters #timeframe-selector {
  width: 49%;
  display: inline-block;
}
#reporting-filters #timeframe-selector {
  text-align: right;
}
#reporting-menu {
  margin-bottom: 30px;
  margin-top: 30px;
}
#reporting .separator {
  height: 1px;
  border-top: 1px dashed #ddd;
  margin: 40px 0;
}
#reporting .section {
  padding: 60px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
  margin: 0 0 40px 0;
  position: relative;
}
#reporting .section h3 {
  margin: 0 0 20px 0;
  font-weight: normal;
}
#reporting .empty-list {
  text-align: center;
  color: #888;
  margin-top: 40px;
  font-style: italic;
}
#reporting .has-items .empty-list {
  display: none;
}
#reporting .reporting-widget {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 49%;
  border-right: 1px solid #ebebeb;
  padding: 0 40px;
}
#reporting .reporting-widget .title {
  color: #989da0;
}
#reporting .reporting-widget:first-child {
  padding-left: 0;
}
#reporting .reporting-widget:last-child {
  border-right: none;
}
#reporting .tooltip {
  position: absolute;
  display: inline-block;
  min-width: 5em;
  padding: 0.5em;
  background: #F4C63D;
  color: #453D3F;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
}
#reporting .ct-series-a .ct-slice-pie {
  stroke: #1d91ce;
  fill: #1d91ce;
}
#reporting .ct-series-b .ct-slice-pie {
  stroke: #ceeaf8;
  fill: #ceeaf8;
}
#reporting .ct-bar {
  stroke: #65beea;
}
#reporting .legend {
  font-size: 0;
}
#reporting .legend .color {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
#reporting .legend .item {
  margin-bottom: 20px;
  display: inline-block;
  font-size: 14px;
  width: 50%;
}
#reporting .legend .item-a .color {
  background: #1d91ce;
}
#reporting .legend .item-b .color {
  background: #ceeaf8;
}
#reporting .legend .description {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
}
#reporting .legend .value {
  padding-left: 21px;
  color: #989ba0;
}
#reporting .graph,
#reporting .legend,
#reporting .text {
  display: block;
}
#reporting #total-invoiced-graph {
  height: 350px;
  max-width: 300px;
}
#reporting #time-worked-graph {
  height: 350px;
  width: 100%;
}
#reporting .list .list-main-header {
  background: #fff;
  border-bottom: 2px solid #eee;
  color: #aaa;
}
#reporting .invoice-total {
  color: #1471ac;
}
#reporting .invoice-client {
  width: 20%;
}
#reporting .list {
  max-height: 400px;
  overflow-y: auto;
}
#reporting label {
  display: block;
  margin-bottom: 7px;
}
#reporting .field {
  margin-bottom: 10px;
}
#reporting .select2-container--default .select2-selection--single {
  height: 40px;
  border-radius: 0;
  color: #cfcfcf;
}
#reporting .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
}
#reporting .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
}
#reporting #custom-date-range {
  display: none;
}
#reporting #custom-date-range.showing {
  display: block;
}
#reporting #custom-date-range .field {
  width: 49%;
  display: inline-block;
}
#reporting #reporting-filters > div {
  padding: 40px;
}
#reporting .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 1%, rgba(0, 0, 0, 0) 5%);
}
#total-invoiced-graph {
  width: 100%;
  margin: 0 40px;
  position: relative;
  top: -20px;
}
#time-worked-graph {
  height: 160px;
  position: relative;
  top: 20px;
}
#reporting-filter-wrapper {
  text-align: right;
  position: relative;
  margin-bottom: 40px;
}
#reporting-filter-wrapper #filter-container,
#reporting-filter-wrapper #download-reports-button {
  display: inline-block;
}
#reporting-filter-wrapper .filter-dropdown {
  margin-left: 10px;
}
.reporting-list {
  display: none;
}
.reporting-list.showing {
  display: block;
}
#report-download-choose-type {
  text-align: center;
  padding: 20px;
}
#report-download-choose-type .button:first-child {
  margin-right: 20px;
}
.dashboard-calendar,
#dashboard-upcoming-events-list {
  width: 450px;
  display: inline-block;
  vertical-align: top;
}
#dashboard-upcoming-events-list {
  margin-left: 60px;
  height: 350px;
  overflow: hidden;
}
#dashboard-upcoming-events-list #upcoming-events-filter-title {
  display: none;
  background: #FBFBFA;
  border-top: 1px solid #F2F2F1;
  border-bottom: 1px solid #F2F2F1;
  padding: 10px;
}
#dashboard-upcoming-events-list.filtered li {
  display: none;
}
#dashboard-upcoming-events-list.filtered li.filter-match {
  display: block;
}
#dashboard-upcoming-events-list.filtered #upcoming-events-filter-title {
  display: block;
  position: relative;
}
#dashboard-upcoming-events-list.filtered #upcoming-events-filter-title .close {
  curspor: pointer;
  position: absolute;
  top: 14px;
  right: 10px;
}
#dashboard-upcoming-events-list li {
  list-style: none;
  padding: 20px;
  cursor: pointer;
}
#dashboard-upcoming-events-list li:hover {
  background: rgba(244, 186, 46, 0.1);
}
#dashboard-upcoming-events-list h5 {
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 0;
  padding-left: 17px;
  position: relative;
}
#dashboard-upcoming-events-list h5:before {
  content: ' ';
  height: 12px;
  width: 12px;
  border: 2px solid #0B76DC;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -7px;
}
#dashboard-upcoming-events-list .overdue h5:before {
  border-color: #CC2446;
}
#dashboard-upcoming-events-list p {
  color: #888;
}
.dashboard-calendar .fc th,
.dashboard-calendar .fc td {
  border: none;
}
.dashboard-calendar .fc-basic-view .fc-body .fc-row {
  min-height: 2em;
}
.dashboard-calendar .fc td.fc-today {
  border: none;
}
.dashboard-calendar .fc-day-number {
  line-height: 40px;
  font-size: 16px;
  text-align: center !important;
  position: relative;
}
.dashboard-calendar .fc-day-number.fc-today:before {
  content: ' ';
  height: 30px;
  width: 30px;
  border: 1px solid #0B76DC;
  border-radius: 50%;
  position: Absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  z-index: -1;
}
.dashboard-calendar .fc-day-number.has-event {
  font-weight: bold;
  color: #EC7061;
}
.dashboard-calendar .fc-day-number.has-event.fc-today {
  color: #fff;
}
.dashboard-calendar .fc-day-number.has-event.fc-today:before {
  background: #EC7061;
  border: none;
}
.dashboard-calendar .fc-day.fc-today {
  background: none !important;
}
.dashboard-calendar .fc-day-header {
  text-transform: uppercase;
  font-weight: normal;
  color: #111;
}
#calendar-months {
  font-size: 11px;
  white-space: nowrap;
  padding: 0 30px;
  text-transform: uppercase;
}
#calendar-months > div {
  display: inline-block;
  width: 8%;
  color: #888;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#calendar-months > div.current {
  font-weight: bold;
}
.details-info {
  display: block;
  margin-bottom: 10px;
  color: #777;
}
#recurring-invoice-list {
  clear: both;
}
.stopped {
  font-size: 12px;
  background: #888;
  color: #fff;
  border-radius: 3px;
  margin-left: 20px;
  padding: 5px 10px;
}
.nav-tabs li {
  display: inline-block;
  list-style: none;
}
.nav-tabs a {
  display: inline-block;
  background-color: transparent;
  color: #5b666d;
  font-weight: 400;
  padding: 5px 0px;
  margin-right: 30px;
  text-decoration: none;
}
.nav-tabs .active a {
  background: none;
  background-color: rgba(255, 255, 255, 0.2) !important;
  box-shadow: #3b67b3 0px 2px 0px 0px;
  color: #3b67b3;
}
button.link {
  text-decoration: underline;
  background: none;
  border: none;
}
button.entity-link {
  border: none;
  background: transparent;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  /* background: url(../images/link.svg) no-repeat center center; */
  background-size: 15px;
  opacity: 0.3;
  transition: opacity 0.4s ease;
}
button.entity-link:hover {
  opacity: 0.6;
}
.entity-link-url {
  word-wrap: break-word;
  color: #6179a9;
}
#more-button-wrapper {
  margin: 30px 0 0 0;
}
#more-tasks {
  display: none;
}
#more-tasks.showing {
  display: block;
}
#agenda-tasks-filter-title {
  display: none;
}
#agenda-tasks {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
}
#agenda-tasks.filtered h4 {
  display: none;
}
#agenda-tasks.filtered li {
  display: none;
}
#agenda-tasks.filtered li.filter-match {
  display: block;
}
#agenda-tasks.filtered #agenda-tasks-filter-title {
  display: block;
  position: relative;
}
#agenda-tasks.filtered #agenda-tasks-filter-title .close {
  curspor: pointer;
  position: absolute;
  top: 14px;
  right: 10px;
}
#agenda-tasks-filter-title {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.035);
  margin-bottom: 40px;
  position: relative;
}
#agenda-tasks-filter-title .close {
  position: absolute;
  top: 9px;
  right: 10px;
}
#agenda {
  margin-top: 20px;
}
#agenda:before,
#agenda:after {
  content: " ";
  display: table;
}
#agenda:after {
  clear: both;
}
#agenda .hide-overflow {
  height: 20%;
  height: 400px;
  width: 100%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
  position: Absolute;
  bottom: 0;
  left: 0;
  display: none;
}
#agenda h4 {
  color: #aaa;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  padding-bottom: 4px;
  display: inline-block;
}
#agenda ul {
  margin-bottom: 20px;
}
#agenda .task-list .project-name {
  font-size: 90%;
  padding: 0 10px;
}
#agenda .no-tasks {
  text-align: center;
  font-style: italic;
  padding: 0;
}
#agenda .no-tasks:hover {
  background: none;
  cursor: default;
}
#agenda .has-tasks .no-tasks {
  display: none;
}
#agenda .drop-hover {
  background: rgba(238, 240, 245, 0.2);
  background: rgba(193, 237, 245, 0.2);
  outline: dashed 1px #eef0f5;
}
#agenda .sort-handle,
#agenda .is-task-completed {
  display: none;
}
#agenda .task-details {
  color: #222;
  padding-left: 10px;
}
#agenda .task-due-date {
  margin-right: 0;
  font-weight: normal;
  color: #AFBDC6;
}
#agenda .task-assigned {
  width: 105px;
  right: 94px;
}
#agenda .task-status {
  width: 20px;
  left: 0;
  position: absolute;
  top: 0;
  display: none;
  padding: 0;
  margin: 0;
}
#agenda .task-status span {
  border-radius: 3px;
  display: block;
  height: 22px;
  line-height: 22px;
  position: relative;
  top: 13px;
  padding: 0 10px;
  text-align: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0);
}
#agenda .overdue .task-status span {
  background: #FC6E63;
  color: rgba(0, 0, 0, 0);
}
#agenda .at-risk .task-status span {
  background: #FEC485;
  color: rgba(0, 0, 0, 0);
}
#agenda .on-schedule .task-status {
  display: none;
}
#agenda .task-list li {
  padding-left: 0;
  opacity: 1;
  transition: opacity 0.2s ease;
  transition-delay: 2s;
}
#agenda .task-list li:hover .task-due-date {
  display: block;
}
#agenda .task-list li:before {
  content: '-';
  position: absolute;
  left: 0;
  top: 0;
  color: #aaa;
}
#agenda .task-list li.not-showing {
  opacity: 0;
}
#agenda #agenda-today .task-list li,
#agenda #agenda-upcoming .task-list li {
  padding-left: 15px;
}
#agenda #agenda-today .task-list li .task-status,
#agenda #agenda-upcoming .task-list li .task-status {
  display: block;
}
#agenda #agenda-today .task-list li:before,
#agenda #agenda-upcoming .task-list li:before {
  display: none;
}
#agenda .dashboard-calendar {
  width: 350px;
}
#agenda .dashboard-calendar .fc-day-number {
  font-size: 14px;
}
#agenda .dashboard-calendar .fc-scroller {
  overflow: hidden;
}
#agenda .marker {
  display: none !important;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0.1s;
}
.open-projects {
  max-width: 350px;
}
.open-projects h4 {
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}
.open-projects li {
  list-style: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: relative;
}
.open-projects li:hover {
  background: #fffbea;
}
.open-projects .project-name {
  color: #111;
  padding-left: 18px;
  position: relative;
  padding-right: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.open-projects .project-name:before {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  height: 12px;
  width: 12px;
  border: 2px solid #6DCADB;
  border-radius: 50%;
}
.open-projects .overdue .project-name:before,
.open-projects .behind-schedule .project-name:before {
  border: 2px solid #FC6E63;
}
.open-projects .at-risk .project-name:before {
  border: 2px solid #FEC485;
}
.open-projects .project-due-date {
  position: absolute;
  top: 10px;
  right: 10px;
}
.open-projects .project-client {
  color: #888;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#agenda-activity {
  max-width: 800px;
  margin: 0 auto;
}
#agenda-activity .activity-content {
  padding-right: 100px;
}
#agenda-activity .activity-timestamp {
  position: absolute;
  right: 0;
  top: 0;
}
.agenda-tab {
  display: none;
}
.agenda-tab.showing {
  display: block;
}
#summary-widgets {
  font-size: 0;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 40px;
  text-align: center;
  display: none;
}
.summary-widget {
  background: #fff;
  display: inline-block;
  margin: 0;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #EDEDED;
}
.summary-widget h5 {
  color: #989898;
  text-transform: uppercase;
  display: inline-block;
  margin: 0;
}
.summary-widget:last-child h5:after {
  display: none;
}
.summary-widget.active {
  border-bottom: 2px solid #27AAFD;
}
.summary-widget.active h5 {
  color: #27AAFD;
}
#agenda-sidebar {
  width: 480px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fdfdfd;
  box-shadow: inset 3px 0 3px rgba(0, 0, 0, 0.02);
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  padding: 20px 0 0 40px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
#agenda-main-content {
  width: 100%;
  height: 100%;
  padding: 0 0 20px 0;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
}
.agenda-drag-helper {
  padding: 0 297px 0 46px;
  height: 50px;
  line-height: 50px;
  background: rgba(193, 237, 245, 0.2);
  background: rgba(238, 240, 245, 0.35);
  outline: dashed 1px #eef0f5;
}
.agenda-drag-helper .task-due-date,
.agenda-drag-helper .task-assigned,
.agenda-drag-helper .task-meta,
.agenda-drag-helper .task-status {
  display: none;
}
#agenda-billing {
  max-width: 800px;
  margin: 0 auto;
}
#agenda-billing .activity-stream {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding-top: 15px;
}
#agenda-billing .activity-stream .type-icon {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  display: inline-block;
}
#agenda-billing .activity-stream .type-icon span {
  top: 2px;
  color: #fff;
  font-size: 17px;
  position: relative;
}
#agenda-billing .activity-stream .user-image {
  display: none;
}
#agenda-billing h5 {
  padding: 12px 0;
  margin: 0;
}
#agenda-billing img {
  color: #F87C79;
  margin-right: 10px;
}
#agenda-billing .activity-show-all {
  display: none;
}
.quick-start {
  max-width: 1024px;
  margin: 0 auto 60px auto;
  font-size: 0;
}
.quick-start > div {
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
}
.quick-start .quick-task {
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 75%;
  width: 100%;
  position: relative;
}
.quick-start .quick-task.saving:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.03);
}
.quick-start .quick-task.saving button.create-task {
  /* background: transparent url(../images/tasks/task-saving.gif) no-repeat center center; */
  color: rgba(0, 0, 0, 0);
}
.quick-start .quick-menu {
  margin-bottom: 20px;
  position: relative;
  width: 22%;
  width: 100%;
  margin-left: 3%;
  margin-left: 0;
  text-align: right;
}
.quick-start .quick-menu .quick-menu-right {
  display: inline-block;
}
.quick-start .quick-menu:after {
  display: block;
  width: 100%;
  margin: 0 auto;
  height: 1px;
  background: rgba(0, 0, 0, 0.06);
  content: '';
  margin-top: 10px;
  margin-bottom: 10px;
  display: none;
}
.quick-start .quick-menu .dropdown-button {
  display: block;
  height: 40px;
  line-height: 40px;
}
.quick-start .quick-menu .button {
  border: none;
  background: none;
  display: block;
  height: 100%;
  line-height: 50px;
  font-size: 14px;
  text-align: right;
}
.quick-start .quick-menu .button:hover {
  box-shadow: none;
}
.quick-start .quick-menu .quick-menu-button {
  display: inline-block;
  padding: 0 15px;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 40px;
  cursor: pointer;
}
.quick-start .quick-menu .quick-menu-button img {
  opacity: 0.5;
  transition: opacity 0.1s linear;
}
.quick-start .quick-menu .quick-menu-button span {
  color: rgba(43, 50, 57, 0.65);
  padding-left: 6px;
  font-size: 14px;
  line-height: 40px;
  transition: color 0.1s linear;
}
.quick-start .quick-menu .quick-menu-button:hover {
  text-decoration: none;
}
.quick-start .quick-menu .quick-menu-button:hover img {
  opacity: 1;
}
.quick-start .quick-menu .quick-menu-button:hover span {
  color: #000000;
}
.quick-start .quick-task-fields {
  margin-right: 60px;
  font-size: 0;
}
.quick-start .quick-task-fields > * {
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  height: 50px;
  line-height: 50px;
  border: none;
  margin: 0;
  padding: 0 20px;
}
.quick-start .quick-task-fields > .select2-container {
  padding-right: 0;
  padding-left: 0;
}
.quick-start .select2-container .select2-selection--single,
.quick-start .select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 50px;
  line-height: 50px;
  border: none;
}
.quick-start .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
}
.quick-start .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
  display: none;
}
.quick-start .task-name {
  width: 70%;
}
.quick-start .task-time {
  width: 15%;
  position: relative;
  padding: 0;
}
.quick-start .task-time span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: rgba(0, 0, 0, 0.2);
  padding: 0 20px;
}
.quick-start .task-time input {
  width: 33%;
  height: 100%;
  border: none;
  float: left;
  display: none;
  padding: 0 5px;
}
.quick-start .task-time input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.2);
}
.quick-start .task-time input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.2);
}
.quick-start .task-time input:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.2);
}
.quick-start .task-time input:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.2);
}
.quick-start .task-time:focus,
.quick-start .task-time.focused,
.quick-start .task-time.has-val {
  outline: none;
}
.quick-start .task-time:focus input,
.quick-start .task-time.focused input,
.quick-start .task-time.has-val input {
  display: block;
}
.quick-start .task-time:focus span,
.quick-start .task-time.focused span,
.quick-start .task-time.has-val span {
  display: none;
}
.quick-start .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.quick-start .select2-selection.select2-selection--single:focus,
.quick-start input:focus {
  outline: none;
  background: rgba(0, 0, 0, 0.02);
}
.quick-start .task-project,
.quick-start .select2-container {
  width: 15%;
}
.quick-start button {
  width: 60px;
  position: absolute;
  top: 0;
  right: 0;
  color: #757575;
  font-size: 20px;
  height: 50px;
  border: none;
  outline: none;
  background: #f2f2f2;
}
@media (max-width: 1649px) {
  #dashboard-right-column,
  #agenda-sidebar {
    width: 380px;
    padding-left: 20px;
  }
  #dashboard-left-column,
  #agenda-main-content {
    padding-right: 380px;
  }
  #reporting-content {
    padding-right: 250px;
  }
  #reporting-filters {
    width: 250px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0 0 10px;
  }
  #reporting #reporting-filters > div {
    padding: 10px;
  }
}
@media (max-width: 1400px) {
  .dashboard-widget {
    width: 50%;
    padding: 40px 20px;
  }
  .dashboard-widget:last-child {
    display: none;
  }
  .dashboard-widget h5 {
    font-size: 12px;
  }
  .dashboard-calendar {
    display: none;
  }
  #agenda .task-status {
    height: 22px;
  }
  #agenda .task-status span {
    display: none;
  }
  #agenda .task-list li {
    padding-right: 230px;
  }
  #dashboard-upcoming-events-list {
    margin-left: 0;
  }
  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  #total-invoiced-graph {
    position: absolute;
    top: 0;
    right: 0;
  }
  #total-invoiced .legend .item {
    display: block;
  }
  #reporting .section {
    padding: 40px;
  }
  #reporting .reporting-widget {
    display: block;
    width: 99%;
    border: none;
    height: 300px;
    padding: 0;
  }
  #reporting .reporting-widget:first-child {
    border-bottom: 1px solid #ebebeb;
  }
  #reporting #total-invoiced-graph {
    height: 250px;
    margin: 0;
  }
  #reporting #time-worked-graph {
    height: 180px;
  }
  #reporting .invoice-list .invoice-number,
  #reporting .invoice-list .invoice-total,
  #reporting .invoice-list .invoice-balance,
  #reporting .invoice-list .invoice-due-date,
  #reporting .invoice-list .invoice-status,
  #reporting .invoice-client {
    width: 33%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  #reporting .invoice-list .invoice-status,
  #reporting .invoice-list .invoice-due-date {
    display: none;
  }
}
@media (max-width: 1024px) {
  #dashboard-widgets {
    margin-left: 20px;
  }
  #reporting-menu {
    margin-top: 0;
    margin-left: 50px;
  }
  #reporting-menu h3 {
    margin: 12px 0;
    line-height: 1;
  }
  #agenda-sidebar {
    display: none;
  }
  #dashboard-left-column,
  #agenda-main-content {
    padding-right: 0;
  }
  #reporting .section {
    margin-left: 20px;
  }
}
@media (max-width: 800px) {
  #reporting-content {
    padding-right: 0;
  }
  #reporting-filters {
    position: relative;
    padding: 20px;
  }
  #agenda .task-assigned {
    display: none !important;
  }
}
@media (max-width: 500px) {
  #reporting #total-invoiced-graph {
    height: 150px;
  }
  #reporting-filters .background {
    position: relative;
  }
}
.inline-page-form {
  max-width: 700px;
  margin: auto;
}
.inline-page-form .form-title {
  padding-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  font-weight: bold;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.inline-page-form label {
  font-size: 16px;
}
.inline-page-form .field {
  margin-bottom: 35px;
}
.inline-page-form .select2-container {
  margin-bottom: 0;
}
.inline-page-form .select2-container .select2-selection--single {
  height: 38px;
}
.inline-page-form .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
}
.inline-page-form input[type=submit] {
  padding: 14px 30px !important;
  font-size: 16px;
}
#my-time {
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
}
#my-time .no-task {
  color: #474f59;
  font-style: italic;
  cursor: pointer;
  transition: all 0.1s ease;
}
#my-time .no-task:hover {
  background: rgba(0, 0, 0, 0.1);
}
#my-time li {
  font-size: 0;
  color: #555;
}
#my-time li a {
  color: #000;
}
#my-time li > div {
  padding: 0 20px;
  line-height: 60px;
  display: inline-block;
  font-size: 16px;
}
#my-time li .time-entry-date {
  width: 20%;
}
#my-time li .time-entry-task {
  width: 60%;
}
#my-time li .time-entry-time {
  width: 20%;
}
#my-time.latest li:first-child {
  background: rgba(250, 255, 0, 0.03);
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0.1s;
  transition-delay: 1s;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
#link-time-to-task {
  padding: 20px;
}
#link-time-to-task .intro {
  padding: 0 20px 20px 20px;
}
#link-time-to-task li {
  position: relative;
  font-size: 0;
  padding: 10px;
  cursor: pointer;
}
#link-time-to-task li:hover {
  background: rgba(250, 255, 0, 0.1);
  color: #000;
}
#link-time-to-task li > div {
  display: inline-block;
  font-size: 16px;
  padding: 0 10px;
}
#link-time-to-task .icon {
  position: absolute;
}
#link-time-to-task .task {
  width: 70%;
}
#link-time-to-task .project-name {
  width: 30%;
}
.editable-area-content {
  padding: 0 20px;
  position: relative;
  opacity: 0;
  color: rgba(0, 0, 0, 0.8);
  display: table;
  width: 100%;
}
.editable-area-content > div {
  display: table-cell;
  vertical-align: middle;
}
.editable-area-content span {
  font-size: 12px;
  color: #bbb;
}
#client-selector-list {
  padding-bottom: 50px;
}
#client-selector-list .client-list {
  max-height: 270px;
  overflow: auto;
}
#client-selector-list .client-list-item {
  position: relative;
  border: 1px solid transparent;
  padding: 25px 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 255, 0.06);
}
#client-selector-list .client-list-item:last-child {
  border-bottom: none;
}
#client-selector-list .client-list-item:hover {
  background: #ebf2fb;
}
#client-selector-list .client-list-item:hover .icon {
  color: #328ff1;
  border-color: #328ff1;
}
#client-selector-list h5 {
  font-weight: bold;
  margin-bottom: 0;
}
#client-selector-list p {
  color: #888;
}
#client-selector-list .badge {
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  text-transform: uppercase;
  width: 50px;
  text-align: center;
  color: #aaa;
  border-radius: 25px;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 10px;
}
#client-selector-list .client-list-item:nth-child(4n + 1) .badge {
  background: #DBF4F0;
  color: #3fc4af;
}
#client-selector-list .client-list-item:nth-child(4n + 2) .badge {
  background: #FFCCCB;
  color: #fff;
}
#client-selector-list .client-list-item:nth-child(4n + 3) .badge {
  background: #EEEBE2;
  color: #a39261;
}
#client-selector-list .client-list-item:nth-child(4n + 4) .badge {
  background: #C7EDDC;
  color: #38b07a;
}
#client-selector-list .user-type-description {
  padding-left: 50px;
}
#client-selector-list .create-new-client {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #E3F2F9;
  color: #4e9ce7;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}
.invoice-title {
  color: #888;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 80%;
  margin-bottom: 1rem;
}
#login-to-continue {
  text-align: center;
}
#login-to-continue .button {
  width: 53%;
  margin: 8px auto;
  padding: 10px 40px;
  font-size: 14px;
  border: none;
}
#login-to-continue .orange {
  background: #01A6EA;
  color: #fff;
}
#login-to-continue .green {
  background: #50D275;
  color: #fff;
}
#login-to-continue .or {
  width: 53%;
  position: relative;
  text-transform: uppercase;
  margin: 15px auto;
  font-size: 13px;
}
#login-to-continue .or span {
  background: #fff;
  padding: 0 10px;
  z-index: 20;
  position: relative;
  color: #888;
}
#login-to-continue .or:after {
  content: '';
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #ccc;
  display: block;
  position: absolute;
}
#login-to-continue p {
  max-width: 73%;
  font-size: 16px;
  margin: 0 auto 10px auto;
}
#login-to-continue .download-now {
  text-decoration: underline;
  cursor: pointer;
  color: #015a7d;
  margin-bottom: 20px;
}
#login-to-continue .download-now .downloading {
  display: none;
}
#login-to-continue .download-now.downloading {
  margin-bottom: 0;
  text-decoration: none;
  color: #888;
}
#login-to-continue .download-now.downloading .downloading {
  text-decoration: none;
  display: block;
  /* background: transparent url(../images/button-loading-dark.svg) center 21px no-repeat; */
  background-size: 22px;
  padding-bottom: 22px;
}
#login-to-continue .download-now.downloading .not-downloading {
  display: none;
}
.modal-overlay.thinking {
  text-align: center;
}
#thinking {
  background: #fff;
  display: inline-block;
  padding: 20px 40px;
  border-radius: 4px;
  margin: 10% auto;
  width: 174px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}
.user-avatar {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: #e9f5ff;
  font-size: 1rem;
  text-align: center;
  line-height: 2rem;
  display: inline-block;
  text-transform: uppercase;
}
.client-avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid transparent;
  color: #000;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  display: inline-block;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: transparent url(../images/invoice-dark.svg) no-repeat center center / 1.5rem; */
  border: 1px solid #aaa;
}
.client-avatar.data-has-initials {
  background: transparent !important;
  border: 2px solid rgba(20, 20, 20, 0.9) !important;
}
.client-avatar.big {
  height: 80px;
  width: 80px;
  font-size: 24px;
  line-height: 80px;
  text-transform: uppercase;
}
.client-avatar.mini {
  height: 30px;
  width: 30px;
  font-size: 12px;
  line-height: 30px;
  margin-right: 4px;
}
#client-page {
  max-width: 1000px;
  margin: 20px auto;
  padding: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  background: #fff;
}
#client-page .client-avatar {
  position: absolute;
  top: 20px;
  left: 20px;
}
#client-page .action {
  position: absolute;
  right: 0;
  top: 0;
}
#client-page li {
  list-style: none;
  padding: 20px 20px 20px 100px;
  font-size: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
#client-page li:hover {
  background: rgba(0, 0, 255, 0.02);
}
#client-page li .name,
#client-page li .email {
  display: inline-block;
  width: 50%;
  font-size: 16px;
  line-height: 60px;
}
#client-page li .email {
  text-align: right;
}
.invoices-list {
  max-width: 1000px;
  margin: 20px auto 60px auto;
  position: relative;
  overflow: auto;
  z-index: 1;
  padding-right: 0 !important;
  padding-left: 0 !important;
  list-style: none;
}
.invoices-list:before,
.invoices-list:after {
  content: " ";
  display: table;
}
.invoices-list:after {
  clear: both;
}
.invoices-list .invoice-wrapper {
  width: 22%;
  margin: 25px 2%;
  float: left;
  position: relative;
}
.invoices-list .invoice-wrapper:before,
.invoices-list .invoice-wrapper:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.invoices-list .invoice-wrapper:after {
  clear: both;
}
.invoices-list .invoice-wrapper:first-child {
  margin-left: 0;
}
.invoices-list .invoice-wrapper:last-child {
  margin-right: 0;
}
.invoices-list li > div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.invoice-mini {
  position: relative;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
  border-radius: 1px;
  z-index: 2;
  cursor: pointer;
  transition: all 0.2s ease;
  font-smoothing: antialiased !important;
}
.invoice-mini:hover {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 6px 6px 0 rgba(0, 0, 0, 0.025);
  border: 1px solid #eee;
  transform: translateY(-3px);
}
.invoice-mini:after {
  display: block;
  content: '';
  padding-top: 130%;
}
.invoice-mini .invoice-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 30px;
}
.invoice-mini .meta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.invoice-mini .meta-inner {
  width: 100%;
  padding: 20px 30px;
  color: #333;
  font-size: 1.2rem;
  line-height: 1.8;
}
.invoice-mini .status {
  width: 100%;
  padding: 10px 30px;
  background: #EDFAF0;
  color: #46CA6C;
}
.invoice-mini .client {
  color: #90A4AF;
}
.invoice-mini .meta-icons {
  position: absolute;
  right: 20px;
  bottom: 20px;
  opacity: 0.3;
}
.invoice-mini .meta-icons > div {
  height: 20px;
  width: 20px;
  background-size: 14px;
  margin-top: 8px;
}
.invoice-mini .total-text {
  margin-top: 29px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #90A4AF;
  text-transform: uppercase;
  text-align: center;
}
.invoice-mini .amount {
  color: #243137;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}
.invoice-mini .amount span {
  font-size: 16px;
  line-height: 16px;
  padding-left: 4px;
  padding-right: 4px;
}
.invoice-mini .number,
.invoice-mini .due,
.invoice-mini .client {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media all and (max-width: 1100px) {
  .invoice-mini {
    font-size: 12px;
  }
  .invoice-mini .client-avatar {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 10px;
  }
  .invoice-mini .invoice-inner {
    padding: 20px 20px;
  }
  .invoice-mini .meta-inner {
    padding: 20px;
    font-size: 12px;
  }
  .invoice-mini .total-text {
    margin-top: 20px;
  }
  .invoice-mini .amount {
    font-size: 15px;
    line-height: 15px;
  }
}
@media all and (max-width: 900px) {
  .meta-inner {
    display: none;
  }
  .total,
  .amount {
    font-size: 10px;
  }
  .client {
    display: none;
  }
}
.small-title {
  font-size: 2rem;
  color: #000;
  font-weight: 400;
  text-align: center;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}
.small-title span {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 2px;
}
.invoices-page {
  margin-top: 0 !important;
}
.dropdown-button .main-button {
  padding: 0 20px;
}
.sign-up-page .halves,
#create-account .halves {
  font-size: 0;
}
.sign-up-page .error,
#create-account .error {
  font-size: 12px;
  color: #ff0000;
}
.sign-up-page .login-window,
#create-account .login-window,
.sign-up-page .form-content,
#create-account .form-content {
  max-width: 400px;
  width: 400px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.sign-up-page .columns:first-child,
#create-account .columns:first-child {
  padding-left: 0;
}
.sign-up-page .columns:last-child,
#create-account .columns:last-child {
  padding-right: 0;
}
.sign-up-page .company-field,
#create-account .company-field,
.sign-up-page .name-field,
#create-account .name-field {
  display: none;
}
.sign-up-page .company-field.showing,
#create-account .company-field.showing,
.sign-up-page .name-field.showing,
#create-account .name-field.showing {
  display: block;
}
.sign-up-page .sign-up-type,
#create-account .sign-up-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.sign-up-page .sign-up-type > label,
#create-account .sign-up-type > label {
  padding-bottom: 12px;
}
.sign-up-page .sign-up-type label,
#create-account .sign-up-type label {
  display: inline-block;
  padding-right: 20px;
}
.sign-up-page .sign-up-type input,
#create-account .sign-up-type input {
  display: inline;
  margin-bottom: 15px;
}
.sign-up-page .half,
#create-account .half {
  display: inline-block;
  width: 50%;
}
.sign-up-page .half label,
#create-account .half label,
.sign-up-page .half input,
#create-account .half input {
  font-size: 14px;
}
.sign-up-page .half:first-child,
#create-account .half:first-child {
  padding-right: 10px;
}
.sign-up-page .half:last-child,
#create-account .half:last-child {
  padding-left: 10px;
  box-shadow: none;
}
.no-invoices {
  max-width: 1000px;
  text-align: center;
  margin: 40px auto;
  padding-top: 65px;
  /* background: transparent url(../images/money.svg) no-repeat center top; */
  background-size: 60px;
}
.modal .login-window {
  padding-bottom: 45px;
}
.modal .login-window form {
  float: none;
}
.modal .login-window .forgot-password {
  display: none;
}
.modal .login-window input[type=text],
.modal .login-window input[type=password] {
  margin-bottom: 10px;
}
.modal .login-window input[type=text]:focus,
.modal .login-window input[type=password]:focus {
  box-shadow: inset 0px 0px 0px 1px rgba(43, 50, 57, 0.21), inset 0 5px 3px -2px rgba(43, 50, 57, 0.04), 0 0 4px 1px rgba(69, 140, 207, 0) !important;
}
body.public #sidebar {
  display: none;
}
body.public #mobile-menu-button {
  display: none;
}
.width-100 {
  width: auto !important;
}
#add-client,
#company-details-form {
  width: 700px !important;
  max-width: 80vw;
}
.login-window .error-message {
  white-space: normal;
}
.corner {
  display: none;
}
.invoice.editable .inline-edit {
  border: none;
  height: auto;
  line-height: 1;
  display: inline-block;
  width: auto;
  padding: 0;
  z-index: 1;
  position: relative;
  width: 100px;
  background: transparent;
}
.invoice.editable .inline-edit:focus {
  outline: none;
}
.editable-area {
  position: relative;
  display: inline-block;
}
.editable-area:hover svg {
  fill: #257eff;
}
.editable-area:hover .editable-area-marker {
  background: transparent;
  color: #257eff;
  border: 2px solid #257eff;
}
.editable-area:hover .editable-area-marker:hover {
  box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.05);
}
.editable-not-hover-styles {
  background: rgba(0, 0, 0, 0.015);
  border: 2px dashed rgba(0, 0, 0, 0.05);
}
.editable-area-marker {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform: scaleX(1.1) scaleY(1.2);
  border-radius: 3px;
  left: 0;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 0;
  background: rgba(0, 0, 0, 0.015);
  border: 2px dashed rgba(0, 0, 0, 0.05);
}
#needs-a-sender,
#needs-a-client {
  padding-left: 45px;
  background: transparent no-repeat 0 0;
  background-size: 30px;
  padding-bottom: 2rem;
}
#needs-a-sender {
  /* background-image: url('../images/from.svg'); */
}
#needs-a-client {
  /* background-image: url('../images/client.svg'); */
}
.sender-and-recipient {
  margin-bottom: 20px;
}
.sender-and-recipient:before,
.sender-and-recipient:after {
  content: " ";
  display: table;
}
.sender-and-recipient:after {
  clear: both;
}
.sender-and-recipient .invoice-contact-info {
  float: left;
}
.sender-and-recipient .invoice-client {
  float: right;
}
.invoice-client,
.invoice-contact-info {
  width: 46% !important;
  line-height: 1.2;
}
.invoice-client .invoice-sender,
.invoice-contact-info .invoice-sender {
  width: 100%;
}
.details-registration{
  font-size: 10px;
  color: #969696;
}
.invoice-client .editable-area-content,
.invoice-contact-info .editable-area-content {
  opacity: 1;
}
.invoice-client.has-data .editable-area-marker,
.invoice-contact-info.has-data .editable-area-marker,
.invoice-client.has-data .editable-area-content,
.invoice-contact-info.has-data .editable-area-content {
  opacity: 0;
}
.invoice-meta > div .editable-area {
  padding: 2px 0;
  line-height: 1;
}
.invoice.editable:hover .editable-area-marker,
.invoice.editable:hover .editable-area-content {
  opacity: 1 !important;
}
.invoice.editable .error .editable-area-marker {
  border-color: transparent;
  border: 2px dashed rgba(255, 0, 0, 0.6);
  opacity: 1 !important;
  background: rgba(255, 0, 0, 0.015);
}
.invoice.editable .error #add-invoice-item.button {
  color: #f30000 !important;
  border: 1px solid rgba(255, 0, 0, 0.5) !important;
}
.invoice.editable .error #needs-a-sender,
.invoice.editable .error #needs-a-client {
  opacity: 1 !important;
}
#admin-settings .active {
  position: relative;
}
#saving-notification {
  width: 120px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -60px;
  padding: 20px;
  background: rgba(255, 254, 242, 0.91);
  border-radius: 0 0 6px 6px;
  display: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
}
#saving-notification > span {
  display: none;
}
#saving-notification.saving .saving-text {
  display: block;
}
#saving-notification.saved .saved-text {
  display: block;
}
.waiting-spinner,
.waiting-spinner:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: none;
}
.waiting-spinner {
  vertical-align: middle;
  right: -12px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.button.waiting .waiting-spinner {
  display: inline-block;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.currency-selector > .select2-container {
  width: 100%;
}
.inline-terms {
  display: block;
  margin: 30px 0 20px 0;
  padding: 5px 0;
}
.inline-terms .textbox {
  width: 100%;
  display: block;
  z-index: 1;
  position: relative;
}
.inline-terms .textbox:focus {
  outline: none;
}
.inline-terms .textbox:empty:before {
  content: attr(placeholder);
  display: block;
  /* For Firefox */
  font-style: italic;
}
.inline-terms .editable-area-marker {
  transform: scaleX(1.05) scaleY(1.2);
}
.invoice-link {
  display: inline-block;
  word-break: break-word;
  word-break: break-all;
  hyphens: auto;
  width: 100%;
  max-width: 300px;
}
form#create-account {
  width: 450px;
}
.agreement,
.allow_advertising_check {
  font-style: italic;
  color: #888;
  max-width: 500px;
  display: inline-block;
  padding-bottom: 20px;
}
.agreement a,
.allow_advertising_check a {
  color: #888;
  text-decoration: underline;
}
.agreement input[type=checkbox],
.allow_advertising_check input[type=checkbox] {
  display: inline-block;
  margin-bottom: 0;
}
.login-header .logo {
  float: left;
}
.login-header .logo img {
  width: 40px;
}
.inline-currency-selector-outer {
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 4px;
}
.currency-selector-popup {
  max-height: 50vh;
  overflow: auto;
}
.currency-selector-popup ul {
  list-style: none;
}
.currency-selector-popup li {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.currency-selector-popup li:hover {
  font-weight: bold;
}
[data-locale=india] .individual-tax {
  display: none;
}
[data-locale=india] .tax-percentage {
  display: inline-block;
}
[data-locale=india] .total-taxes {
  display: block;
}
[ert=form-project-file] .modal {
  background: none;
  border: none;
  box-shadow: none;
  min-width: 40vw;
  padding-top: 50px;
}
[ert=form-project-file] .modal-info {
  background: none;
  color: #fff;
  text-align: Center;
  font-weight: bold;
  text-shadow: -1px 0 0 rgba(0, 0, 0, 0.2);
  border: none;
}
[ert=form-project-file] .modal-info h3 {
  font-size: 20px;
}
[ert=form-project-file] #cancel-all {
  display: block;
  margin: 0 auto;
  float: none;
  width: 110px;
  text-align: center;
}
.app-message {
  padding: 20px;
  background: #42CB79;
}
.app-message p {
  max-width: 1024px;
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
  background-size: 22px;
  text-align: center;
}
.verify-email-banner {
  padding: 20px;
  background: #4b9df6;
}
.verify-email-banner p {
  max-width: 1024px;
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
  padding-left: 34px;
  /* background: transparent url('../images/alert.svg') no-repeat 0 center; */
  background-size: 22px;
}
.verify-email-banner span {
  text-decoration: underline;
  cursor: pointer;
}
.login-header {
  display: none;
}
header {
  border-bottom: 1px solid #e2e2e2;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  transform: translateY(0);
  transform: translate3d(0, 0, 0);
  transition: 0.25s transform;
  backface-visibility: hidden;
  z-index: 100;
  font-family: Roboto, sans-serif;
}
header .logo img {
  max-height: 40px;
  width: 40px;
}
header nav .menu .button {
  background: #1e79f2;
  color: #1e79f2;
  padding: 4px 25px;
  border-radius: 4px;
  margin-right: -30px !important;
  border: none;
}
header nav .menu .button a {
  color: #fff;
  display: block;
}
header nav .menu .button a:hover {
  color: #fff;
}
header .row {
  max-width: 100%;
}
header .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  header .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  header .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  header .container {
    width: 1170px;
  }
}
header .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
header .row {
  margin-right: -15px;
  margin-left: -15px;
}
header .col-lg-1,
header .col-lg-10,
header .col-lg-11,
header .col-lg-12,
header .col-lg-2,
header .col-lg-3,
header .col-lg-4,
header .col-lg-5,
header .col-lg-6,
header .col-lg-7,
header .col-lg-8,
header .col-lg-9,
header .col-md-1,
header .col-md-10,
header .col-md-11,
header .col-md-12,
header .col-md-2,
header .col-md-3,
header .col-md-4,
header .col-md-5,
header .col-md-6,
header .col-md-7,
header .col-md-8,
header .col-md-9,
header .col-sm-1,
header .col-sm-10,
header .col-sm-11,
header .col-sm-12,
header .col-sm-2,
header .col-sm-3,
header .col-sm-4,
header .col-sm-5,
header .col-sm-6,
header .col-sm-7,
header .col-sm-8,
header .col-sm-9,
header .col-xs-1,
header .col-xs-10,
header .col-xs-11,
header .col-xs-12,
header .col-xs-2,
header .col-xs-3,
header .col-xs-4,
header .col-xs-5,
header .col-xs-6,
header .col-xs-7,
header .col-xs-8,
header .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
header .col-xs-1,
header .col-xs-10,
header .col-xs-11,
header .col-xs-12,
header .col-xs-2,
header .col-xs-3,
header .col-xs-4,
header .col-xs-5,
header .col-xs-6,
header .col-xs-7,
header .col-xs-8,
header .col-xs-9 {
  float: left;
}
@media (min-width: 768px) {
  header .col-sm-1,
  header .col-sm-10,
  header .col-sm-11,
  header .col-sm-12,
  header .col-sm-2,
  header .col-sm-3,
  header .col-sm-4,
  header .col-sm-5,
  header .col-sm-6,
  header .col-sm-7,
  header .col-sm-8,
  header .col-sm-9 {
    float: left;
  }
  header .col-sm-12 {
    width: 100%;
  }
  header .col-sm-11 {
    width: 91.66666667%;
  }
  header .col-sm-10 {
    width: 83.33333333%;
  }
  header .col-sm-9 {
    width: 75%;
  }
  header .col-sm-8 {
    width: 66.66666667%;
  }
  header .col-sm-7 {
    width: 58.33333333%;
  }
  header .col-sm-6 {
    width: 50%;
  }
  header .col-sm-5 {
    width: 41.66666667%;
  }
  header .col-sm-4 {
    width: 33.33333333%;
  }
  header .col-sm-3 {
    width: 25%;
  }
  header .col-sm-2 {
    width: 16.66666667%;
  }
  header .col-sm-1 {
    width: 8.33333333%;
  }
  header .col-sm-pull-12 {
    right: 100%;
  }
  header .col-sm-pull-11 {
    right: 91.66666667%;
  }
  header .col-sm-pull-10 {
    right: 83.33333333%;
  }
  header .col-sm-pull-9 {
    right: 75%;
  }
  header .col-sm-pull-8 {
    right: 66.66666667%;
  }
  header .col-sm-pull-7 {
    right: 58.33333333%;
  }
  header .col-sm-pull-6 {
    right: 50%;
  }
  header .col-sm-pull-5 {
    right: 41.66666667%;
  }
  header .col-sm-pull-4 {
    right: 33.33333333%;
  }
  header .col-sm-pull-3 {
    right: 25%;
  }
  header .col-sm-pull-2 {
    right: 16.66666667%;
  }
  header .col-sm-pull-1 {
    right: 8.33333333%;
  }
  header .col-sm-pull-0 {
    right: auto;
  }
  header .col-sm-push-12 {
    left: 100%;
  }
  header .col-sm-push-11 {
    left: 91.66666667%;
  }
  header .col-sm-push-10 {
    left: 83.33333333%;
  }
  header .col-sm-push-9 {
    left: 75%;
  }
  header .col-sm-push-8 {
    left: 66.66666667%;
  }
  header .col-sm-push-7 {
    left: 58.33333333%;
  }
  header .col-sm-push-6 {
    left: 50%;
  }
  header .col-sm-push-5 {
    left: 41.66666667%;
  }
  header .col-sm-push-4 {
    left: 33.33333333%;
  }
  header .col-sm-push-3 {
    left: 25%;
  }
  header .col-sm-push-2 {
    left: 16.66666667%;
  }
  header .col-sm-push-1 {
    left: 8.33333333%;
  }
  header .col-sm-push-0 {
    left: auto;
  }
  header .col-sm-offset-12 {
    margin-left: 100%;
  }
  header .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  header .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  header .col-sm-offset-9 {
    margin-left: 75%;
  }
  header .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  header .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  header .col-sm-offset-6 {
    margin-left: 50%;
  }
  header .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  header .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  header .col-sm-offset-3 {
    margin-left: 25%;
  }
  header .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  header .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  header .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  header .col-md-1,
  header .col-md-10,
  header .col-md-11,
  header .col-md-12,
  header .col-md-2,
  header .col-md-3,
  header .col-md-4,
  header .col-md-5,
  header .col-md-6,
  header .col-md-7,
  header .col-md-8,
  header .col-md-9 {
    float: left;
  }
  header .col-md-12 {
    width: 100%;
  }
  header .col-md-11 {
    width: 91.66666667%;
  }
  header .col-md-10 {
    width: 83.33333333%;
  }
  header .col-md-9 {
    width: 75%;
  }
  header .col-md-8 {
    width: 66.66666667%;
  }
  header .col-md-7 {
    width: 58.33333333%;
  }
  header .col-md-6 {
    width: 50%;
  }
  header .col-md-5 {
    width: 41.66666667%;
  }
  header .col-md-4 {
    width: 33.33333333%;
  }
  header .col-md-3 {
    width: 25%;
  }
  header .col-md-2 {
    width: 16.66666667%;
  }
  header .col-md-1 {
    width: 8.33333333%;
  }
  header .col-md-pull-12 {
    right: 100%;
  }
  header .col-md-pull-11 {
    right: 91.66666667%;
  }
  header .col-md-pull-10 {
    right: 83.33333333%;
  }
  header .col-md-pull-9 {
    right: 75%;
  }
  header .col-md-pull-8 {
    right: 66.66666667%;
  }
  header .col-md-pull-7 {
    right: 58.33333333%;
  }
  header .col-md-pull-6 {
    right: 50%;
  }
  header .col-md-pull-5 {
    right: 41.66666667%;
  }
  header .col-md-pull-4 {
    right: 33.33333333%;
  }
  header .col-md-pull-3 {
    right: 25%;
  }
  header .col-md-pull-2 {
    right: 16.66666667%;
  }
  header .col-md-pull-1 {
    right: 8.33333333%;
  }
  header .col-md-pull-0 {
    right: auto;
  }
  header .col-md-push-12 {
    left: 100%;
  }
  header .col-md-push-11 {
    left: 91.66666667%;
  }
  header .col-md-push-10 {
    left: 83.33333333%;
  }
  header .col-md-push-9 {
    left: 75%;
  }
  header .col-md-push-8 {
    left: 66.66666667%;
  }
  header .col-md-push-7 {
    left: 58.33333333%;
  }
  header .col-md-push-6 {
    left: 50%;
  }
  header .col-md-push-5 {
    left: 41.66666667%;
  }
  header .col-md-push-4 {
    left: 33.33333333%;
  }
  header .col-md-push-3 {
    left: 25%;
  }
  header .col-md-push-2 {
    left: 16.66666667%;
  }
  header .col-md-push-1 {
    left: 8.33333333%;
  }
  header .col-md-push-0 {
    left: auto;
  }
  header .col-md-offset-12 {
    margin-left: 100%;
  }
  header .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  header .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  header .col-md-offset-9 {
    margin-left: 75%;
  }
  header .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  header .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  header .col-md-offset-6 {
    margin-left: 50%;
  }
  header .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  header .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  header .col-md-offset-3 {
    margin-left: 25%;
  }
  header .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  header .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  header .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  header .col-lg-1,
  header .col-lg-10,
  header .col-lg-11,
  header .col-lg-12,
  header .col-lg-2,
  header .col-lg-3,
  header .col-lg-4,
  header .col-lg-5,
  header .col-lg-6,
  header .col-lg-7,
  header .col-lg-8,
  header .col-lg-9 {
    float: left;
  }
  header .col-lg-12 {
    width: 100%;
  }
  header .col-lg-11 {
    width: 91.66666667%;
  }
  header .col-lg-10 {
    width: 83.33333333%;
  }
  header .col-lg-9 {
    width: 75%;
  }
  header .col-lg-8 {
    width: 66.66666667%;
  }
  header .col-lg-7 {
    width: 58.33333333%;
  }
  header .col-lg-6 {
    width: 50%;
  }
  header .col-lg-5 {
    width: 41.66666667%;
  }
  header .col-lg-4 {
    width: 33.33333333%;
  }
  header .col-lg-3 {
    width: 25%;
  }
  header .col-lg-2 {
    width: 16.66666667%;
  }
  header .col-lg-1 {
    width: 8.33333333%;
  }
  header .col-lg-pull-12 {
    right: 100%;
  }
  header .col-lg-pull-11 {
    right: 91.66666667%;
  }
  header .col-lg-pull-10 {
    right: 83.33333333%;
  }
  header .col-lg-pull-9 {
    right: 75%;
  }
  header .col-lg-pull-8 {
    right: 66.66666667%;
  }
  header .col-lg-pull-7 {
    right: 58.33333333%;
  }
  header .col-lg-pull-6 {
    right: 50%;
  }
  header .col-lg-pull-5 {
    right: 41.66666667%;
  }
  header .col-lg-pull-4 {
    right: 33.33333333%;
  }
  header .col-lg-pull-3 {
    right: 25%;
  }
  header .col-lg-pull-2 {
    right: 16.66666667%;
  }
  header .col-lg-pull-1 {
    right: 8.33333333%;
  }
  header .col-lg-pull-0 {
    right: auto;
  }
  header .col-lg-push-12 {
    left: 100%;
  }
  header .col-lg-push-11 {
    left: 91.66666667%;
  }
  header .col-lg-push-10 {
    left: 83.33333333%;
  }
  header .col-lg-push-9 {
    left: 75%;
  }
  header .col-lg-push-8 {
    left: 66.66666667%;
  }
  header .col-lg-push-7 {
    left: 58.33333333%;
  }
  header .col-lg-push-6 {
    left: 50%;
  }
  header .col-lg-push-5 {
    left: 41.66666667%;
  }
  header .col-lg-push-4 {
    left: 33.33333333%;
  }
  header .col-lg-push-3 {
    left: 25%;
  }
  header .col-lg-push-2 {
    left: 16.66666667%;
  }
  header .col-lg-push-1 {
    left: 8.33333333%;
  }
  header .col-lg-push-0 {
    left: auto;
  }
  header .col-lg-offset-12 {
    margin-left: 100%;
  }
  header .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  header .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  header .col-lg-offset-9 {
    margin-left: 75%;
  }
  header .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  header .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  header .col-lg-offset-6 {
    margin-left: 50%;
  }
  header .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  header .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  header .col-lg-offset-3 {
    margin-left: 25%;
  }
  header .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  header .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  header .col-lg-offset-0 {
    margin-left: 0;
  }
}
header .container {
  padding: 15px 15px;
}
@media only screen and (min-width: 0px) and (max-width: 991px) {
  header .container {
    padding: 15px;
  }
}
header .container .logo > span {
  font-size: 24px;
  line-height: 40px;
}
header.fixed_header.sticky {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: sticky_anim 0.4s;
}
header.fixed_header.sticky .container {
  padding: 15px;
}
@media only screen and (min-width: 992px) {
  header.fixed_header.sticky {
    position: fixed;
  }
}
@media only screen and (min-width: 0px) and (max-width: 991px) {
  header.fixed_header.sticky {
    padding: 20px 15px;
    background-color: #fff;
  }
}
header.fixed_header.sticky ul.menu > li > a:before {
  bottom: -16px;
}
@keyframes sticky_anim {
  0% {
    transform: translateY(-120px);
    transform: translate3d(0, -120px, 0);
  }
  100% {
    transform: translateY(0);
    transform: translate3d(0, 0, 0);
  }
}
header ul.menu {
  margin: 0;
  list-style: none;
  text-align: right;
}
header ul.menu > li {
  margin: 0 20px;
  display: inline-block;
}
header ul.menu > li > a {
  line-height: 30px;
  padding: 5px 0;
  color: #333;
  position: relative;
}
header ul.menu > li > a:before {
  content: '';
  width: 0;
  height: 3px;
  left: 50%;
  margin-left: -23px;
  position: absolute;
  bottom: -31px;
}
header ul.menu > li > a.sub-siblings:before {
  margin-left: -33px;
}
header ul.menu > li.current-menu-item a:before,
header ul.menu > li:hover a:before {
  width: 46px;
}
header ul.menu > li:last-child {
  margin-right: 0;
}
header ul.menu li a {
  font-weight: 500;
  font-size: 16px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
header ul.menu > li > a {
  font-weight: 500;
  font-size: 16px;
}
@media only screen and (min-width: 992px) {
  .header-area .sub-siblings:after,
  .header-area ul {
    transition: 0.4s;
  }
  .header-area ul li {
    position: relative;
  }
  .header-area ul li a {
    margin-left: 0;
  }
  .header-area ul li ul {
    left: 0;
    position: absolute;
    top: 300%;
    width: 230px;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
  }
  .header-area ul.menu > li:nth-last-child(1) > ul,
  .header-area ul.menu > li:nth-last-child(2) > ul,
  .header-area ul.menu > li:nth-last-child(3) > ul {
    right: 0;
    left: auto;
  }
  .header-area ul li ul li {
    display: block;
    background-color: transparent;
  }
  .header-area ul li ul li a {
    display: block;
    text-align: left;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12px 15px;
    line-height: 30px;
    background-color: transparent;
  }
  header.header-area ul li ul li a:before {
    content: '\f105';
    font-family: fontawesome;
    margin-right: 10px;
    margin-left: 5px;
    background-color: transparent;
  }
  .header-area ul li ul li.current-page-ancestor > a,
  .header-area ul li ul li.current_page_item > a,
  .header-area ul li ul li:hover > a {
    background-color: rgba(255, 255, 255, 0.15);
  }
  .header-area ul li ul li ul {
    left: 100%;
    top: 200%;
  }
  .header-area ul.menu > li:nth-last-child(1) ul > li > ul,
  .header-area ul.menu > li:nth-last-child(2) ul > li > ul,
  .header-area ul.menu > li:nth-last-child(3) ul > li > ul,
  .header-area ul.menu > li:nth-last-child(4) ul > li > ul,
  .header-area ul.menu > li:nth-last-child(5) ul > li > ul,
  .header-area ul.menu > li > ul > li > ul > li > ul {
    left: auto;
    right: 100%;
  }
  .header-area ul.menu > li:nth-last-child(1) > ul > li > ul > li > ul,
  .header-area ul.menu > li:nth-last-child(2) > ul > li > ul > li > ul,
  .header-area ul.menu > li:nth-last-child(3) > ul > li > ul > li > ul,
  .header-area ul.menu > li:nth-last-child(4) > ul > li > ul > li > ul,
  .header-area ul.menu > li:nth-last-child(5) > ul > li > ul > li > ul {
    right: auto;
    left: 100%;
  }
  .header-area .sub-siblings:after {
    content: "\f105";
    font-family: fontawesome;
    margin-left: 7px;
    position: absolute;
    top: 50%;
    right: 5px;
    line-height: 20px;
    margin-top: -10px;
  }
  .header-area .sub-menu .sub-siblings:after {
    right: 15px;
  }
  .header-area ul.menu > li > ul {
    transition-delay: 0.3s !important;
  }
  .header-area ul.menu > li:hover > ul {
    top: 69px;
    opacity: 1;
    visibility: visible;
    transition-delay: 0s !important;
  }
  .header-area.sticky ul.menu > li:hover > ul {
    top: 53px;
  }
  .header-area ul.menu > li:hover > ul > li:hover > ul,
  .header-area ul.menu > li:hover > ul > li:hover > ul > li:hover > ul {
    top: -1px;
    opacity: 1;
    visibility: visible;
  }
  .header-area ul > li:hover > a.sub-siblings:after {
    transform: rotate(90deg);
  }
  .header-area ul.menu > li > a.sub-siblings {
    padding-right: 20px;
  }
}
.alternative {
  margin-top: 40px;
  padding-top: 40px;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.alternative p {
  font-size: 18px;
}
.dropdown-selector {
  margin: 0;
  display: inline-block;
  height: 100%;
  position: relative;
}
.dropdown-selector .button {
  background: none;
  padding: 0;
}
.dropdown-selector ul {
  display: block;
  margin: 0;
  list-style: none;
  vertical-align: top;
}
.dropdown-selector li {
  color: #536172;
}
.dropdown-selector li:hover {
  color: #5AC6F8;
  background: #fafafa;
}
.dropdown-selector__content {
  visibility: hidden;
  opacity: 0;
  position: Absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.15);
  transition: opacity 0.1s linear;
  text-align: left;
  width: 260px;
  margin: 0;
  padding: 0;
  max-height: 60vh;
  overflow: auto;
}
.dropdown-selector__content > * {
  vertical-align: top;
}
.dropdown-selector__content a {
  padding: 0.75rem 1.25rem;
  line-height: 1.625;
  display: block;
  color: #333;
}
.dropdown-selector__content a:hover {
  text-decoration: none;
}
.dropdown-selector__text {
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.dropdown-selector__text:after {
  content: '';
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  /* background: transparent url(../images/down-arrow.svg) no-repeat center center / 1.25rem; */
  opacity: 0.4;
  margin-left: 1.25rem;
  position: relative;
  top: 1px;
}
.dropdown-selector--attached .text {
  background: #fff;
  border: 1px solid transparent;
  transition: opacity 0.1s linear;
  line-height: 1;
  font-size: 0.9rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.dropdown-selector--attached:hover .text {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 999;
  padding-bottom: 1.4em;
}
.dropdown-selector--attached .menu-content {
  margin-top: -1px;
  z-index: 998;
  right: 0;
}
.dropdown-selector--attached .menu-content:after,
.dropdown-selector--attached .menu-content:before {
  display: none;
}
.dropdown-selector--attached ul {
  padding: 0;
  width: 100%;
}
.dropdown-selector--open .dropdown-selector__content {
  display: block;
  visibility: visible;
  opacity: 1;
}
.signup-step-overlay {
  position: fixed;
  top: 0;
  left: 0;
  /* overflow: scroll; */
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.8);
  BACKDROP-FILTER: blur(5px);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.signup-step-content {
  position: fixed;
  width: 600px;
  top: 10%;
  left: calc(50% - 300px);
  z-index: 999;
  background: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 2rem;
  font-size: 1.6rem;
  margin-bottom: 4rem;
}
.signup-step-content label {
  display: block;
  margin-bottom: 0.5rem;
}
.signup-step-content label input {
  display: inline;
}
.form input.business-line__other {
  display: none;
}
.business-line--other .form input.business-line__other {
  display: block;
}
.invoice-template-picker .button:hover {
  color: #000 !important;
}
.invoice-template-picker .dropdown-selector__text:after {
  /* background-image: url(../images/chevron-down.svg) !important; */
}
.invoice-template-picker.dropdown-selector--open .button {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.34) !important;
  border-bottom: none !important;
  border-radius: 3px 3px 0 0;
  z-index: 999999999;
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #000 !important;
}
.invoice-template-picker.dropdown-selector--open .button span {
  color: #000 !important;
}
.invoice-template-picker.dropdown-selector--open ul.dropdown-selector__content {
  padding: 2rem;
  border-radius: 0 3px 3px 3px;
  margin-top: -1px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 20px 3rem rgba(0, 0, 0, 0.2);
  width: 460px;
}
.invoice-template-picker.dropdown-selector--open ul.dropdown-selector__content li:hover {
  background: #259af4;
  color: #fff;
}
.invoice-template-picker.dropdown-selector--open ul.dropdown-selector__content li:hover a {
  color: #fff !important;
}
.client-invoices-list .client-avatar.mini {
  display: none;
}
.button {
  padding: 0.75rem 2rem;
  border-radius: 3px;
}
/*# sourceMappingURL=style.css.map */